import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { FormikProvider, useFormik } from "formik";
import { Loader } from "shared/ui";
import { ProductionInputMaterial, ProductionOutputMaterial } from "shared/api";
import { noop } from "shared/helpers";
import * as Yup from "yup";
import { useMainProductionPageData } from "shared/hooks";
import { addProductionItemSchema, outputMaterialsSchema } from "shared/schemas";
import { DynamicProcessStepsForTheOrder } from "./DynamicProcessStepsForTheOrder";

interface DynamicPageProps {}

export interface AddProductionRequest {
  input_materials: ProductionInputMaterial[];
  output_materials: ProductionOutputMaterial[];
  variant?: string | null;
  variantValue?: string | null;
}

const ProcessStepProduction: FC<DynamicPageProps> = () => {
  const { orderCode, index } = useParams();

  const {
    selectedOrder,
    decrementProcessStepIndex,
    incrementProcessStepIndex,
    clearSelectedOrder,
    orderStepTrackerResponseItem,
    isLoadingOrderData,
    order,
    refetchOrderProcessStepTracker,
    orderId,
    isLoadingOrderCodes,
    navigateOrder,
    orderProcessStepInfoBox,
  } = useMainProductionPageData({ orderCode, index });

  const addOrUpdateDynamicStepsForTheOrderFormik =
    useFormik<AddProductionRequest>({
      initialValues: {
        variant: null,
        variantValue: null,
        input_materials:
          orderStepTrackerResponseItem?.process_step?.input_materials ?? [],
        output_materials:
          orderStepTrackerResponseItem?.process_step?.output_materials ?? [],
      },
      validationSchema: Yup.object().shape({
        input_materials: addProductionItemSchema,
        output_materials: outputMaterialsSchema(orderStepTrackerResponseItem),
      }),
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: noop,
      enableReinitialize: true,
    });

  if (!order) {
    return <Loader type="fullscreen" />;
  }

  return (
    <FormikProvider value={addOrUpdateDynamicStepsForTheOrderFormik}>
      <DynamicProcessStepsForTheOrder
        processStepId={selectedOrder?.id}
        orderId={orderId}
        orderProcessStepInfoBox={orderProcessStepInfoBox}
        clearSelectedOrder={clearSelectedOrder}
        incrementProcessStepIndex={incrementProcessStepIndex}
        decrementProcessStepIndex={decrementProcessStepIndex}
        orderStepTrackerResponseItem={orderStepTrackerResponseItem}
        isLoadingOrderData={isLoadingOrderData}
        refetchOrderProcessStepTracker={refetchOrderProcessStepTracker}
        isLoadingOrderCodes={isLoadingOrderCodes}
        navigateOrder={navigateOrder}
      />
    </FormikProvider>
  );
};

export { ProcessStepProduction };
