import Title from "antd/es/typography/Title";
import React, { FC, useEffect } from "react";
import { Button, notification } from "antd";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import classNames from "classnames";
import {
  FormSelect,
  FormInput,
  GlobalDatePicker,
  DatePickerValue,
} from "shared/ui";
import { handleRequestError, noop } from "shared/helpers";
import {
  OrderStepTrackerResponseItem,
  useGetOrderProcessStepTrackersForTheOrderCardQuery,
  useUpdateOrderProcessStepTrackersMutation,
} from "shared/api";
import { EditProcessStepForTheOrderSchema } from "shared/schemas";
import { AdaptedValues, AddDynamicOrderSteps } from "shared/types";
import { orderCardSelectValues } from "shared/constants";
import { useWindowDimensions } from "shared/hooks";

interface OrderCardProps {
  orderStepTrackerResponseItem: OrderStepTrackerResponseItem | undefined;
  adaptedMachines: AdaptedValues[];
  processStepId: number | undefined;
  openProducedItemInOrderModal: () => void;
  setIsOrderCardMachineSelected: React.Dispatch<React.SetStateAction<boolean>>;
  isOrderCardMachineSelected: boolean;
  isSubmitted: boolean;
}

const OrderCard: FC<OrderCardProps> = ({
  orderStepTrackerResponseItem,
  adaptedMachines,
  processStepId,
  openProducedItemInOrderModal,
  setIsOrderCardMachineSelected,
  isOrderCardMachineSelected,
  isSubmitted,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useWindowDimensions();

  const [api, contextHolder] = notification.useNotification();

  const addOrUpdateDynamicStepsForTheOrderFormik =
    useFormik<AddDynamicOrderSteps>({
      initialValues: {
        id: null,
        machine: null,
        start_datetime: null,
        end_datetime: null,
        variant: null,
        variantValue: null,
        input_materials: [],
        output_materials: [],
        stockMaterial: null,
        status: null,
        info: null,
      },
      validationSchema: EditProcessStepForTheOrderSchema,
      onSubmit: noop,
    });

  const { values, handleBlur, handleChange, setFieldValue, errors, setValues } =
    addOrUpdateDynamicStepsForTheOrderFormik;

  const handleStartDateTimeChange = (date: DatePickerValue) => {
    setFieldValue("start_datetime", date);
  };

  const handleEndDateTimeChange = (date: DatePickerValue) => {
    setFieldValue("end_datetime", date);
  };

  const [updateOrderProcessStepTrackers] =
    useUpdateOrderProcessStepTrackersMutation();

  const updateOrderProcessStepTrackersRequest = async () => {
    try {
      await updateOrderProcessStepTrackers({
        id: processStepId!,
        machine: values.machine!,
        start_datetime: values.start_datetime!,
        end_datetime: values.end_datetime!,
        status: values.status!,
        info: values.info,
      }).unwrap();
      api.success({
        message: t("orders.success-title"),
        description: t("orders.success-update-msg"),
      });
    } catch (error) {
      const description = handleRequestError(error, t("orders.error-title"));

      api.error({
        message: t("orders.error-title"),
        description,
      });
    }
  };

  const { data: orderCardData } =
    useGetOrderProcessStepTrackersForTheOrderCardQuery(
      {
        id: Number(processStepId),
      },
      {
        skip: !processStepId,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        refetchOnReconnect: true,
      }
    );

  useEffect(() => {
    if (orderCardData) {
      const isMachineValid = adaptedMachines.some(
        (machine) => machine.value === orderCardData.machine
      );

      setValues({
        id: orderCardData.id,
        machine: isMachineValid ? orderCardData.machine : null,
        start_datetime: orderCardData.start_datetime,
        end_datetime: orderCardData.end_datetime,
        variant: orderCardData.variant,
        variantValue: orderCardData.variantValue,
        status: orderCardData.status,
        info: orderCardData.info,
      });
    }
  }, [orderCardData, adaptedMachines]);

  useEffect(() => {
    if (adaptedMachines.length === 1 && !values.machine) {
      setFieldValue("machine", adaptedMachines[0].value);
    }
  }, [adaptedMachines, values.machine, setFieldValue]);

  useEffect(() => {
    const isMachineSelected = !!values.machine;
    setIsOrderCardMachineSelected(isMachineSelected);
  }, [values.machine, setIsOrderCardMachineSelected]);

  useEffect(() => {
    if (values.machine && values.status) {
      updateOrderProcessStepTrackersRequest();
    }
  }, [values.machine]);

  return (
    <>
      {contextHolder}

      <div
        className={classNames(
          "w-full h-auto shadow-dynamicFormCard rounded-xl",
          {
            "w-full": !isDesktop,
          }
        )}
      >
        <div className="flex justify-between gap-3 pb-3">
          <div>
            <div className="flex justify-normal gap-3 pb-3">
              <div className="flex flex-col">
                <Title level={5} className="mb-1.25">
                  {t("orders.buyer")}
                </Title>

                <FormInput
                  value={orderStepTrackerResponseItem?.order.buyer.name!}
                  fieldName="buyer"
                  placeholder={t("orders.buyer")}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  disabled
                />
              </div>
              <div className="flex flex-col">
                <Title level={5} className="mb-1.25">
                  {t("orders.status")}
                </Title>

                <FormSelect
                  value={values.status}
                  placeholder={t("orders.status")}
                  fieldName="status"
                  options={orderCardSelectValues}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  error={errors.status?.toString()}
                  isError={!!errors.status}
                />
              </div>
              <div className="flex flex-col align-bottom">
                <Title level={5} className="mb-1.25">
                  {t("orders.machines")}
                </Title>

                <FormSelect
                  value={values.machine}
                  placeholder={t("orders.machines")}
                  fieldName="machine"
                  options={adaptedMachines}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  error={errors.machine?.toString()}
                  isError={!!errors.machine}
                  className={classNames("", {
                    "border-2 border-red-500":
                      !isOrderCardMachineSelected && isSubmitted,
                  })}
                />
              </div>
              <div className="flex flex-col">
                <Title level={5} className="mb-1.25">
                  {t("orders.start-datetime")}
                </Title>

                <GlobalDatePicker
                  value={
                    values?.start_datetime
                      ? dayjs(values?.start_datetime)
                      : null
                  }
                  handlePickerChange={handleStartDateTimeChange}
                  showTime
                  disabled
                />
              </div>
              <div className="flex flex-col">
                <Title level={5} className="mb-1.25">
                  {t("orders.end-datetime")}
                </Title>

                <GlobalDatePicker
                  value={
                    values?.end_datetime ? dayjs(values?.end_datetime) : null
                  }
                  handlePickerChange={handleEndDateTimeChange}
                  showTime
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="w-[10%] flex  h-auto items-center justify-between gap-y-2">
            <Button
              type="primary"
              className="
                w-full flex items-center  bg-purple-100
                justify-center text-white hover:!text-purple-100
                shadow-dynamicFormCard hover:!bg-white !text-[12px]
              "
              onClick={openProducedItemInOrderModal}
            >
              {t("orders.view-all-products")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export { OrderCard };
