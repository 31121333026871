import { DatePicker } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import "dayjs/locale/zh-cn";
import { Dayjs } from "dayjs";

import locale from "antd/es/date-picker/locale/en_GB";
import "dayjs/locale/en-gb";

export type DatePickerValue = Dayjs | null;
interface GlobalDatePickerProps {
  value?: DatePickerValue;
  error?: string;
  showTime?: boolean;
  disabled?: boolean;
  isError?: boolean;
  handlePickerChange: (value: DatePickerValue, dateString: string) => void;
  minDate?: DatePickerValue;
  size?: "small" | "large";
}

const GlobalDatePicker: FC<GlobalDatePickerProps> = ({
  value,
  error,
  showTime,
  isError,
  handlePickerChange,
  disabled,
  minDate,
  size = "large",
}) => {
  const { t } = useTranslation();

  const disabledDates = (current: Dayjs) => {
    return current && minDate
      ? current.isBefore(minDate.startOf("day"))
      : false;
  };

  return (
    <>
      <DatePicker
        value={value}
        showTime={showTime}
        className="w-full"
        onChange={handlePickerChange}
        popupClassName="!text-[15px]"
        size={size}
        disabled={disabled}
        disabledDate={disabledDates}
        locale={locale}
      />
      {!!isError && <div className="text-red-100 mt-1px">{t(`${error}`)}</div>}
    </>
  );
};

export { GlobalDatePicker };
