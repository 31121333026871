import { Navigate } from "react-router-dom";
import { useGetLoggedUserQuery } from "shared/api";
import { ClipLoader } from "react-spinners";
import { Routes } from "shared/routers";

interface ProtectedAccessRouteProps {
  requiredAccess: string;
  children: (user: any) => React.ReactNode;
}

export const ProtectedAccessRoute: React.FC<ProtectedAccessRouteProps> = ({
  requiredAccess,
  children,
}) => {
  const { data: user, isLoading } = useGetLoggedUserQuery();

  if (isLoading)
    return (
      <div className="flex items-center justify-center h-screen">
        <ClipLoader size={50} color="#2563EB" />
      </div>
    );

  if (!user?.access_points?.includes(requiredAccess)) {
    return <Navigate to={Routes.protectedAccessKeys.url} replace />;
  }

  return <>{children(user)}</>;
};
