import React, { FC, useMemo } from "react";
import { FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Page } from "shared/ui";
import { useModal } from "shared/hooks";
import { noop } from "shared/helpers";
import { AccessPoints } from "shared/routers";
import { adaptCustomers, adaptProcessSteps } from "shared/adapters";
import { ProcessStepItem } from "shared/api/productCard/models";
import {
  useGetAllCompaniesQuery,
  useGetProcessStepsWithNoParamsQuery,
  UserItem,
} from "shared/api";
import { productCardsInitialValues } from "shared/constants";
import { AddOrEditProductCardSchema } from "shared/schemas";
import {
  ProductCardTable,
  AddProductCardModal,
  EditProductCardModal,
} from "./ui";

interface ProductCardsProps {
  user?: UserItem;
}

export interface AddProductCardValues {
  id: number | null;
  name: string | null;
  company?: number[];
  process_steps: number[] | ProcessStepItem[];
}

const ProductCards: FC<ProductCardsProps> = ({ user }) => {
  const { t } = useTranslation();

  const hasAddPermission = user?.access_points?.includes(
    AccessPoints.PRODUCT_CARDS_ADD
  );

  const hasEditPermission = user?.access_points?.includes(
    AccessPoints.PRODUCT_CARDS_EDIT
  );

  const hasDeletePermission = user?.access_points?.includes(
    AccessPoints.PRODUCT_CARDS_DELETE
  );

  const [
    isOpenAddProductCardModal,
    showAddProductCardModal,
    hideAddProductCardModal,
  ] = useModal();

  const [
    isOpenEditProductCardModal,
    showEditProductCardModal,
    hideEditProductCardModal,
  ] = useModal();

  const {
    data: processSteps,
    isLoading: isLoadingProcessSteps,
    isFetching: isFetchingProcessSteps,
  } = useGetProcessStepsWithNoParamsQuery();

  const { data: companies, isLoading: isLoadingCompanies } =
    useGetAllCompaniesQuery({});

  const isLoadingProcessStepsOrFetching =
    isLoadingProcessSteps || isFetchingProcessSteps || isLoadingCompanies;

  const adaptedSteps = useMemo(() => {
    return adaptProcessSteps(processSteps || []);
  }, [processSteps]);

  const adaptedCustomers = useMemo(() => {
    return adaptCustomers(companies || []);
  }, [companies]);

  const addOrUpdateProductCardFormik = useFormik({
    initialValues: productCardsInitialValues,
    validationSchema: AddOrEditProductCardSchema,
    onSubmit: noop,
  });

  return (
    <FormikProvider value={addOrUpdateProductCardFormik}>
      <Page
        title={t("product-card.page-title")}
        buttonTitle={
          hasAddPermission ? t("product-card.add-product-card")! : undefined
        }
        onClick={hasAddPermission ? showAddProductCardModal : undefined}
      >
        <ProductCardTable
          showEditProductCardModal={showEditProductCardModal}
          isLoadingProcessStepsOrFetching={isLoadingProcessStepsOrFetching}
          hasEditPermission={hasEditPermission}
          hasDeletePermission={hasDeletePermission}
        />

        <AddProductCardModal
          isOpenModal={isOpenAddProductCardModal}
          hideAddProductCardModal={hideAddProductCardModal}
          adaptedSteps={adaptedSteps}
          processSteps={processSteps}
          customers={companies}
          adaptedCustomers={adaptedCustomers}
          isLoadingProcessSteps={isLoadingProcessSteps}
          isLoadingCustomers={isLoadingCompanies}
        />

        <EditProductCardModal
          isOpenModal={isOpenEditProductCardModal}
          hideEditProductCardModal={hideEditProductCardModal}
          adaptedSteps={adaptedSteps}
          processSteps={processSteps}
          isLoadingProcessSteps={
            isLoadingProcessSteps ||
            isFetchingProcessSteps ||
            isLoadingCompanies
          }
          adaptedCustomers={adaptedCustomers}
          customers={companies}
        />
      </Page>
    </FormikProvider>
  );
};

export { ProductCards };
