import React, { FC, useEffect, useState } from "react";
import { Modal, notification } from "antd";
import Title from "antd/es/typography/Title";
import { FormInput } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import { useUpdateRecipientsListMutation } from "shared/api";
import { ModalSizeValue } from "shared/constants";

interface UpdateEmailModalProps {
  isOpen: boolean;
  closeModal: () => void;
  refetch: () => void;
  existingEmails: { value: string }[];
  emailToEdit: string; // строка
}

const UpdateEmailModal: FC<UpdateEmailModalProps> = ({
  isOpen,
  closeModal,
  refetch,
  existingEmails,
  emailToEdit,
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [updateRecipientsList] = useUpdateRecipientsListMutation();
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (emailToEdit) {
      setEmail(emailToEdit);
      setError("");
    }
  }, [emailToEdit, isOpen]);

  const handleSave = async () => {
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setError("Please enter a valid email address");
      return;
    }

    if (
      existingEmails.some((e) => e.value === email && e.value !== emailToEdit)
    ) {
      setError("This email is already in the list.");
      return;
    }

    const updatedEmails = existingEmails.map((e) =>
      e.value === emailToEdit ? email : e.value
    );

    try {
      await updateRecipientsList({
        key: "t1_recipient_list",
        value: JSON.stringify(updatedEmails),
      }).unwrap();

      api.success({
        message: "Success",
        description: "Recipient has been updated successfully.",
      });

      setEmail("");
      setError("");
      closeModal();
      refetch();
    } catch (error) {
      const description = handleRequestError(
        error,
        "Error while updating recipients list"
      );

      api.error({
        message: "Error",
        description,
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Edit Recipient"
        open={isOpen}
        onCancel={() => {
          setEmail("");
          setError("");
          closeModal();
        }}
        onOk={handleSave}
        okButtonProps={{
          disabled: !email,
          style: { background: Colors.blue.primary },
        }}
        width={ModalSizeValue.PRIMARY}
      >
        <Title level={5} className="text-[14px]">
          Email
        </Title>
        <FormInput
          value={email}
          fieldName="email"
          placeholder="Enter new email"
          handleChange={(e) => {
            setEmail(e.target.value);
            setError("");
          }}
          handleBlur={() => {}}
          error={error}
          isError={!!error}
        />
      </Modal>
    </>
  );
};

export { UpdateEmailModal };
