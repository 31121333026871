import { expandOrdersString, expandStepTracker } from "shared/constants";
import { useMemo } from "react";
import {
  useGetAllOrdersWithNoParamsQuery,
  useGetOrderByIdQuery,
  useGetOrderProcessStepInfoBoxQuery,
  useGetOrderProcessStepTrackersQuery,
} from "shared/api";
import { useNavigate } from "react-router-dom";
import { useProductionExtruder } from "./useProductionExtruder";

interface UseMainProductionPageDataProps {
  orderCode?: string;
  index?: string;
}

const useMainProductionPageData = ({
  orderCode,
  index,
}: UseMainProductionPageDataProps) => {
  const navigate = useNavigate();

  const { data: orderItemResponse } = useGetOrderByIdQuery(
    { order_code: String(orderCode), expand: expandOrdersString },
    { skip: !orderCode }
  );

  const order = useMemo(() => {
    return orderItemResponse?.[0];
  }, [orderItemResponse, orderCode]);

  const orderId = order?.id.toString();

  const {
    selectedOrder,
    decrementProcessStepIndex,
    incrementProcessStepIndex,
    clearSelectedOrder,
    resetProcessStepIndex,
  } = useProductionExtruder(order, index);

  const {
    data: orderStepTrackerResponseItem,
    isLoading: isLoadingOrderData,
    refetch: refetchOrderStepTrackerItem,
  } = useGetOrderProcessStepTrackersQuery(
    {
      id: selectedOrder?.id,
      expand: expandStepTracker,
    },
    {
      skip: !selectedOrder?.id,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  const { data: orderProcessStepInfoBox } = useGetOrderProcessStepInfoBoxQuery(
    { id: selectedOrder?.id },
    { skip: !selectedOrder?.id, refetchOnFocus: true, refetchOnReconnect: true }
  );

  const refetchOrderProcessStepTracker = () => {
    refetchOrderStepTrackerItem();
  };

  const { data: orders, isLoading: isLoadingOrderCodes } =
    useGetAllOrdersWithNoParamsQuery({});

  const orderCodes = useMemo(() => {
    return (orders?.map((order) => order.order_code) as string[]) ?? [];
  }, [orders]);

  const navigateOrder = (direction: "next" | "previous") => {
    const currentIndex = orderCodes.indexOf(String(orderCode));
    if (currentIndex === -1) return;

    const newIndex = direction === "next" ? currentIndex + 1 : currentIndex - 1;

    if (newIndex < 0 || newIndex >= orderCodes.length) {
      return;
    }

    const newOrderCode = orderCodes[newIndex];
    navigate(`/process-step-production/${newOrderCode}/index-${0}`);
    resetProcessStepIndex();
  };

  return {
    selectedOrder,
    orderProcessStepInfoBox,
    decrementProcessStepIndex,
    incrementProcessStepIndex,
    clearSelectedOrder,
    orderStepTrackerResponseItem,
    isLoadingOrderData,
    order,
    refetchOrderProcessStepTracker,
    orderId,
    orderCodes,
    isLoadingOrderCodes,
    navigateOrder,
  };
};

export { useMainProductionPageData };
