import { FilterFilled } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { TFunction } from "i18next";
import { productCardColumnsData, sortDirections } from "shared/constants";
import { ProductCard, ProductCardItem } from "shared/api";
import { TableButton, TableSearchFilter } from "shared/ui";
import { EditCartItem } from "shared/types";
import { ProcessStepsByProductCard } from "pages/ProductCards/ProductCards/ui/ProcessStepByCompany";
import { CustomerByProductCard } from "pages/ProductCards/ProductCards/ui/CustomerByProductCard";

interface UseGetProductCardsTableColumnsProps {
  t: TFunction;
  changeSort: (ascendOrdering: string, descendOrdering: string) => void;
  editProductCarHandler: (record: EditCartItem) => void;
  handleProductCardClone: (card: ProductCard) => Promise<void>;
  showDeleteProductCardModal: (record: ProductCardItem) => void;
  nameContainsValue: string;
  handleNameContainsValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearNameContainsValue: () => void;
  handleProductCardArchive: (record: ProductCard) => Promise<void>;
  processStepsData: { label: string; value: number }[];
  handleProcessStepValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  processStepValue: string;
  clearProcessStepContainsValue: () => void;
  customerNames: { label: string; value: number }[];
  debouncedCustomerContains: string;
  handleCustomerContainsValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  customerValue: string;
  clearCustomerContainsValue: () => void;
  hasEditPermission?: boolean;
  hasDeletePermission?: boolean;
}

const { name, actions, processStep, customer } = productCardColumnsData;

export const useGetProductCardsTableColumns = ({
  t,
  changeSort,
  editProductCarHandler,
  handleProductCardClone,
  showDeleteProductCardModal,
  nameContainsValue,
  handleNameContainsValue,
  clearNameContainsValue,
  handleProductCardArchive,
  handleProcessStepValue,
  processStepValue,
  clearProcessStepContainsValue,
  clearCustomerContainsValue,
  customerValue,
  handleCustomerContainsValue,
  hasDeletePermission,
  hasEditPermission,
}: UseGetProductCardsTableColumnsProps): ColumnsType<ProductCardItem> => [
  {
    title: t(name.title),
    render: (item: ProductCardItem) => (
      <TableButton
        type="title"
        title={item?.name}
        onClick={
          hasEditPermission
            ? () => editProductCarHandler(item as EditCartItem)
            : undefined
        }
      />
    ),
    width: name.width,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort("name", "-name");
      },
    }),
    sorter: true,
    sortDirections,
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600  scale-125": nameContainsValue.length > 0,
        })}
      />
    ),
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearNameContainsValue}
        confirm={confirm}
        value={nameContainsValue}
        handleFilterChange={handleNameContainsValue}
        visible={visible}
        placeholder={t("product-card.enter-card-name")!}
        title={t("product-card.filter-by-name")!}
      />
    ),
  },
  {
    title: t(processStep.title),
    key: processStep.key,
    width: processStep.width,
    render: (item: ProductCardItem) => (
      <ProcessStepsByProductCard item={item} />
    ),
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600  scale-125": processStepValue.length > 0,
        })}
      />
    ),
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearProcessStepContainsValue}
        confirm={confirm}
        value={processStepValue}
        handleFilterChange={handleProcessStepValue}
        visible={visible}
        placeholder={t("product-card.enter-process-name")!}
        title={t("product-card.filter-by-process-step")!}
      />
    ),
  },
  {
    title: t(customer.title),
    key: customer.key,
    width: customer.width,
    render: (item: ProductCardItem) => <CustomerByProductCard item={item} />,
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600 scale-125": customerValue.length > 0,
        })}
      />
    ),
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearCustomerContainsValue}
        confirm={confirm}
        value={customerValue}
        handleFilterChange={handleCustomerContainsValue}
        visible={visible}
        placeholder={t("product-card.enter-customer-name")!}
        title={t("product-card.filter-by-customer")!}
      />
    ),
  },

  {
    title: t(actions.title),
    key: actions.key,
    width: actions.width,
    render: (record: ProductCardItem | ProductCard) => {
      return (
        <div className="w-full flex items-center justify-around">
          <TableButton
            type="clone"
            popoverString={t("product-card.clone-card")!}
            onClick={() => handleProductCardClone(record as ProductCard)}
          />
          <TableButton
            type="archive"
            popoverString={t("product-card.archive-card")!}
            onClick={() => handleProductCardArchive(record as ProductCard)}
          />
          {hasDeletePermission && (
            <TableButton
              type="delete"
              onClick={() =>
                showDeleteProductCardModal(record as ProductCardItem)
              }
            />
          )}
        </div>
      );
    },
  },
];
