import React, { FC, useMemo } from "react";
import { FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Page } from "shared/ui";
import { noop } from "shared/helpers";
import { adaptDimensionUnitsData } from "shared/adapters";
import { useGetDimensionUnitsQuery, UserItem } from "shared/api";
import { useModal } from "shared/hooks";
import { AccessPoints } from "shared/routers";
import {
  expandDimensionUnits,
  initialMaterialCategoryValues,
} from "shared/constants";
import { AddOrEditMaterialSchema } from "shared/schemas";
import {
  AddMaterialCategoryModal,
  MaterialsCategoryTable,
  UpdateMaterialCategoryModal,
} from "./ui";

interface MaterialCategoryManagementProps {
  user?: UserItem;
}

const MaterialCategoryManagement: FC<MaterialCategoryManagementProps> = ({
  user,
}) => {
  const { t } = useTranslation();

  const hasAddPermission = user?.access_points?.includes(
    AccessPoints.CATEGORIES_ADD
  );

  const hasEditPermission = user?.access_points?.includes(
    AccessPoints.CATEGORIES_EDIT
  );

  const hasDeletePermission = user?.access_points?.includes(
    AccessPoints.CATEGORIES_DELETE
  );

  const [
    isVisibleAddMaterialCategoryModal,
    showAddMaterialCategoryModal,
    hideAddMaterialCategoryModal,
  ] = useModal();

  const [
    isVisibleEditMaterialCategoryModal,
    showEditMaterialCategoryModal,
    hideEditMaterialCategoryModal,
  ] = useModal();

  const { data: dimensionUnits } = useGetDimensionUnitsQuery({
    expand: expandDimensionUnits,
  });

  const unitsWithLabel = useMemo(() => {
    return adaptDimensionUnitsData(dimensionUnits || []);
  }, [dimensionUnits]);

  const addOrUpdateMaterialFormik = useFormik({
    initialValues: initialMaterialCategoryValues,
    validationSchema: AddOrEditMaterialSchema,
    onSubmit: noop,
  });

  return (
    <FormikProvider value={addOrUpdateMaterialFormik}>
      <Page
        title={t("material-category-management.title")}
        buttonTitle={
          hasAddPermission
            ? t("material-category-management.add-category-btn")!
            : undefined
        }
        onClick={hasAddPermission ? showAddMaterialCategoryModal : undefined}
      >
        <MaterialsCategoryTable
          hasEditPermission={hasEditPermission}
          hasDeletePermission={hasDeletePermission}
          showEditMaterialCategoryModal={showEditMaterialCategoryModal}
        />

        <AddMaterialCategoryModal
          isVisibleAddMaterialCategoryModal={isVisibleAddMaterialCategoryModal}
          hideAddMaterialCategoryModal={hideAddMaterialCategoryModal}
          adaptedDimensionUnits={unitsWithLabel}
        />

        <UpdateMaterialCategoryModal
          isVisibleEditMaterialCategoryModal={
            isVisibleEditMaterialCategoryModal
          }
          hideEditMaterialCategoryModal={hideEditMaterialCategoryModal}
          adaptedDimensionUnits={unitsWithLabel}
        />
      </Page>
    </FormikProvider>
  );
};

export { MaterialCategoryManagement };
