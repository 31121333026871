import Table, { ColumnsType } from "antd/es/table";
import Title from "antd/es/typography/Title";
import React from "react";
import { Link } from "react-router-dom";
import {
  MaterialConsumedItem,
  MaterialStockItem,
  ProductionInputMaterial,
  StockMaterialItem,
} from "shared/api";
import { useFormikContext, FormikErrors } from "formik";
import { FormInput, TableButton } from "shared/ui";
import { useTranslation } from "react-i18next";
import { OrderProductionSetParams } from "shared/types";
import { useModal } from "shared/hooks";
import { materialConsumedColumns } from "shared/constants";
import { Button } from "antd";
import { Routes } from "shared/routers";
import { AddNewMaterialItemModal } from "./AddNewMaterialItemModal";
import { MaterialVariant } from "./models/MaterialItemsEnum";

interface OrderMaterialConsumedProps {
  materialStockBatches?: StockMaterialItem[];
  inputMaterials: ProductionInputMaterial[];
  materialItems: MaterialStockItem[];
}

const OrderMaterialConsumed: React.FC<OrderMaterialConsumedProps> = ({
  materialStockBatches,
  inputMaterials,
  materialItems,
}) => {
  const { t } = useTranslation();

  const { material, materialItem, stockBatch, quantity, actions } =
    materialConsumedColumns;

  const { values, handleBlur, handleChange, errors, setFieldValue } =
    useFormikContext<OrderProductionSetParams>();

  const [isOpenMaterialModal, openMaterialModal, closeMaterialModal] =
    useModal();

  const handleAddMaterial = async (
    variant: MaterialVariant,
    itemId: number | null,
    itemValue: string | null,
    quantity: number | null
  ) => {
    const newMaterial = {
      id: null,
      consumed_datetime: new Date().toISOString(),
      quantity,
      order_process_step_tracker: null,
      material:
        variant === MaterialVariant.Material
          ? { id: itemId, name: itemValue || "" }
          : null,
      material_stock_batch:
        variant === MaterialVariant.StockBatch
          ? { id: itemId, batch_code: itemValue || "" }
          : null,
      material_item:
        variant === MaterialVariant.MaterialItem
          ? { id: itemId, serial_number: itemValue || "" }
          : null,
      reason: "",
      status: "US",
    };

    setFieldValue("materials_consumed", [
      ...(values.materials_consumed || []),
      newMaterial,
    ]);
  };

  const handleOpenMaterialModal = () => {
    openMaterialModal();
  };

  const handleRemoveItem = (tableItemIndex: number) => {
    const updatedMaterialsConsumed = values.materials_consumed.filter(
      (_, materialConsumedIndex) => materialConsumedIndex !== tableItemIndex
    );
    setFieldValue("materials_consumed", updatedMaterialsConsumed);
  };

  const columns: ColumnsType<MaterialConsumedItem> = [
    {
      title: t(material.title),
      width: material.width,
      render: (record: MaterialConsumedItem) => record?.material?.name,
    },
    {
      title: t(materialItem.title),
      width: materialItem.width,
      render: (record: MaterialConsumedItem) =>
        record?.material_item?.id ? (
          <Link
            className="text-blue-400"
            to={Routes.materialManagement.articleUrl.replace(
              ":id",
              String(record.material_item.id)
            )}
          >
            {record?.material_item?.serial_number}
          </Link>
        ) : (
          record?.material_item?.serial_number
        ),
    },
    {
      title: t(stockBatch.title),
      width: stockBatch.width,
      render: (record: MaterialConsumedItem) =>
        record?.material_stock_batch?.batch_code,
    },
    {
      title: t(quantity.title),
      width: quantity.width,
      render: (
        _: MaterialConsumedItem,
        record: MaterialConsumedItem,
        index: number
      ) => {
        const error = errors.materials_consumed?.[
          index
        ] as FormikErrors<MaterialConsumedItem>;
        const quantityError = error?.quantity;

        return (
          <FormInput
            value={values?.materials_consumed?.[index]?.quantity}
            fieldName={`materials_consumed.${index}.quantity`}
            placeholder={t("orders.quantity")}
            handleChange={handleChange}
            handleBlur={handleBlur}
            numberType
            error={quantityError}
            isError={!!quantityError}
          />
        );
      },
    },
    {
      title: t(actions.title),
      width: 100,
      render: (
        _: MaterialConsumedItem,
        __: MaterialConsumedItem,
        index: number
      ) => (
        <TableButton type="delete" onClick={() => handleRemoveItem(index)} />
      ),
    },
  ];

  return (
    <div className="w-full">
      <div className="flex items-center gap-2 justify-between mb-2 mt-1">
        <Title level={5}>{t("orders.materials-consumed")}</Title>
        <Button onClick={handleOpenMaterialModal}>Add new material</Button>
        <AddNewMaterialItemModal
          isOpenModal={isOpenMaterialModal}
          materialItems={materialItems}
          close={closeMaterialModal}
          onAddMaterial={handleAddMaterial}
          materialStockBatches={materialStockBatches}
          inputMaterials={inputMaterials}
        />
      </div>
      <Table columns={columns} dataSource={values?.materials_consumed} />
    </div>
  );
};

export { OrderMaterialConsumed };
