import { Alert, Button, Image, notification, Select } from "antd";
import Title from "antd/es/typography/Title";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  DynamicOutputMaterialData,
  InputMaterialFormik,
  OrderStepTrackerResponseItem,
  ProductionOutputMaterial,
  useUpdateOrderProcessStepTrackersProduceMaterialItemsMutation,
} from "shared/api";
import { adaptOutputMaterialsValues } from "shared/adapters";
import {
  handleRequestError,
  selectFiltering,
  adaptInputMaterialValues,
} from "shared/helpers";
import classNames from "classnames";
import { useOrderProcessStepTrackerGeneratePdfFiles } from "shared/hooks";
import { InitialInputMaterials, InitialOutputMaterials } from "shared/types";
import { AddProductionRequest } from "../../ProcessStepProduction";
import { StatusesSummary } from "../../../StatusesSummary";

interface CountOfItemsStepProps {
  handleCountOfItemsChange: (value: number, index: number) => void;
  outputMaterialsValues: {
    output_materials: ProductionOutputMaterial[];
  };
  handleSetOutputMaterialsValuesBasedOnCountOfCopies: (
    materials: DynamicOutputMaterialData[],
    countOfCopiedItems: number,
    index: number
  ) => void;
  inputIndex: number;
  validationArray: boolean[];
  setIsUnlocked: Dispatch<SetStateAction<Record<number, boolean>>>;
  outputMaterials: ProductionOutputMaterial[];
  isValidProductionForm: boolean;
  processStepId?: number;
  values: AddProductionRequest;
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  adaptedInitialInputMaterials: InitialInputMaterials[];
  adaptedInitialOutputMaterials: InitialOutputMaterials[];
  refetchOrderProcessStepTracker: () => void;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  isOrderCardMachineSelected: boolean;
}

const CountOfItemsStep: FC<CountOfItemsStepProps> = ({
  handleCountOfItemsChange,
  outputMaterialsValues,
  handleSetOutputMaterialsValuesBasedOnCountOfCopies,
  inputIndex,
  validationArray,
  adaptedInitialInputMaterials,
  adaptedInitialOutputMaterials,
  isValidProductionForm,
  outputMaterials,
  refetchOrderProcessStepTracker,
  setFieldValue,
  setIsUnlocked,
  values,
  orderStepTrackerResponseItem,
  processStepId,
  isOrderCardMachineSelected,
  setIsSubmitted,
}) => {
  const { t } = useTranslation("", { keyPrefix: "orders" });
  const [selectedCount, setSelectedCount] = useState<number>(1);

  const options = useMemo(
    () =>
      Array.from({ length: 101 }, (_, index) => ({
        value: index + 1,
        label: (index + 1).toString(),
      })),
    []
  );

  const dataToCopy = useMemo(() => {
    const originalItem =
      outputMaterialsValues?.output_materials?.[inputIndex]?.dynamicData?.[0];

    return originalItem
      ? Array.from({ length: selectedCount }, () =>
          structuredClone(originalItem)
        )
      : [];
  }, [outputMaterialsValues, inputIndex, selectedCount]);

  const isNotValidForm = validationArray?.[inputIndex] === false;

  const defaultCountOfItems =
    outputMaterialsValues?.output_materials?.[inputIndex]
      ?.numberOfMaterialItems;

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isSecondTooltipVisible, setIsSecondTooltipVisible] = useState(false);

  const [api, contextHolder] = notification.useNotification();

  const [updateOrderProcessStepTrackersProduceMaterialItems] =
    useUpdateOrderProcessStepTrackersProduceMaterialItemsMutation();

  const { generateLastSetLabelsPdf } =
    useOrderProcessStepTrackerGeneratePdfFiles({
      orderProcessStepTrackerId: orderStepTrackerResponseItem?.id as number,
    });

  const createOrderStepTrackerRequest = async () => {
    if (!isOrderCardMachineSelected) {
      window.scrollTo({ top: 0, behavior: "smooth" });

      api.error({
        message: t("error"),
        description: t("pleaseSelectMachine"),
      });

      setIsSubmitted(true);
      return;
    }

    try {
      await updateOrderProcessStepTrackersProduceMaterialItems({
        id: Number(processStepId),
        input_materials: adaptInputMaterialValues(
          values,
          orderStepTrackerResponseItem
        ) as InputMaterialFormik[],
        output_materials: adaptOutputMaterialsValues(
          values,
          orderStepTrackerResponseItem,
          dataToCopy
        ),
      }).unwrap();
      api.success({
        message: t("success"),
        description: t("add-msg"),
      });
      // Set initial values after successful request
      setFieldValue("input_materials", adaptedInitialInputMaterials);
      setFieldValue("output_materials", adaptedInitialOutputMaterials);
      // Clear count of items based on output materials length
      if (outputMaterials && outputMaterials.length > 0) {
        outputMaterials.forEach((_, index) => {
          handleCountOfItemsChange(0, index);
        });
      }
      // Manually refetch data for the process step tracker
      // Because, after request we set <calc_default_dynamic_tracker_fields> field in adaptedInitialOutputMaterials function as default values for this material
      // Using refetch function and not tag, because requests are in different api files and have different invalidate tags for refetch
      setIsUnlocked({});
      refetchOrderProcessStepTracker();
      setIsSubmitted(false);
    } catch (error) {
      api.error({
        message: t("error"),
        description: handleRequestError(error, t("error")),
      });
    }
  };

  const createAndPrint = async () => {
    if (!isOrderCardMachineSelected) {
      window.scrollTo({ top: 0, behavior: "smooth" });

      api.error({
        message: t("error"),
        description: t("pleaseSelectMachine"),
      });

      setIsSubmitted(true);
      return;
    }

    try {
      await createOrderStepTrackerRequest();
      await generateLastSetLabelsPdf();
      setIsUnlocked({});
      setIsSubmitted(false);
    } catch (error) {
      api.error({
        message: t("error"),
        description: handleRequestError(error, t("error")),
      });
    }
  };

  useEffect(() => {
    if (defaultCountOfItems) {
      setSelectedCount(defaultCountOfItems);
    }
  }, [defaultCountOfItems]);

  useEffect(() => {
    if (defaultCountOfItems && !isNotValidForm) {
      setSelectedCount(defaultCountOfItems);
      handleCountOfItemsChange(defaultCountOfItems, inputIndex);
      handleSetOutputMaterialsValuesBasedOnCountOfCopies(
        dataToCopy,
        defaultCountOfItems,
        inputIndex
      );
    }
  }, [isNotValidForm, defaultCountOfItems]);

  return (
    <>
      {contextHolder}
      <div
        className={classNames("flex gap-9 p-2 w-full", {
          "justify-between": defaultCountOfItems,
          "justify-end": !defaultCountOfItems,
        })}
      >
        {defaultCountOfItems && (
          <div
            className="relative"
            onMouseEnter={() => setIsTooltipVisible(true)}
            onMouseLeave={() => setIsTooltipVisible(false)}
          >
            <Image width={30} height={30} src="/info-icon.png" />
            {isTooltipVisible && (
              <div className="absolute z-50 w-[30vw] top-0 left-10">
                {defaultCountOfItems ? (
                  <div className="flex gap-x-5 items-center">
                    <Alert
                      type="warning"
                      showIcon
                      message={
                        <>
                          {t("defaultCountOfItems", { defaultCountOfItems })}
                          <br />
                          {t("confirmDefaultCountOfItems", {
                            defaultCountOfItems,
                          })}
                        </>
                      }
                    />
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}

        <div className="flex flex-col gap-4 items-center">
          <StatusesSummary outputMaterials={outputMaterials} />
          <Button
            type="default"
            onClick={createAndPrint}
            className={classNames("w-52", {
              "bg-blue-50": isValidProductionForm,
            })}
            disabled={!isValidProductionForm}
          >
            {t("createAndPrint")}
          </Button>
        </div>

        <div className="flex">
          <div className="flex gap-4 flex-col items-end gap-x-3">
            <div className="flex gap-3 relative">
              <div
                onMouseEnter={() => setIsSecondTooltipVisible(true)}
                onMouseLeave={() => setIsSecondTooltipVisible(false)}
              >
                <Image width={30} height={30} src="/info-icon.png" />
              </div>

              {isSecondTooltipVisible && (
                <div className="absolute top-9 left-0 p-3 bg-green-50 z-[90]">
                  {t("confirmPopoverMessage")}
                </div>
              )}

              <Title level={5} className="!m-0">
                {t("selectCountOfItems")}
              </Title>
              <Select
                disabled={isNotValidForm}
                className="w-32"
                value={selectedCount}
                onChange={(value) => {
                  setSelectedCount(value);
                  handleCountOfItemsChange(value, inputIndex);
                  handleSetOutputMaterialsValuesBasedOnCountOfCopies(
                    dataToCopy,
                    value,
                    inputIndex
                  );
                  api.success({
                    message: t("outputMaterialsCopySuccess", {
                      selectedCount: value,
                    }),
                  });
                }}
                options={options}
                showSearch
                filterOption={(input, option) => selectFiltering(input, option)}
              />
            </div>

            <Button
              type="default"
              onClick={createOrderStepTrackerRequest}
              className={classNames("w-32", {
                "bg-green-50": isValidProductionForm,
              })}
              disabled={!isValidProductionForm}
            >
              {t("create")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export { CountOfItemsStep };
