import React, { FC, useMemo, useState } from "react";
import { Collapse } from "antd";
import { transformObject, updateDynamicProcessStep } from "shared/adapters";
import {
  DynamicOrderObject,
  OrderProcessInfoBox,
  OrderStepTrackerResponseItem,
} from "shared/api";
import { FieldType } from "shared/types";
import { ConfigCardStepFields } from "../ConfigCardStepFields";
import { ProductionGuiNBC } from "./ProductionGuiNBC";

interface DynamicStepsContainerProps {
  orderStepTrackerResponseItem: OrderStepTrackerResponseItem | undefined;
  orderProcessStepInfoBox?: OrderProcessInfoBox;
  processStepId?: number;
}

const DynamicStepsContainer: FC<DynamicStepsContainerProps> = ({
  orderStepTrackerResponseItem,
  orderProcessStepInfoBox,
  processStepId,
}) => {
  const dynamicProcessStep =
    orderStepTrackerResponseItem?.process_step.process_template
      .config_dynamic_process_step_fields;

  const processStep =
    orderStepTrackerResponseItem?.process_step?.dynamic_process_step_fields;

  const transformedProcessStep = useMemo(() => {
    return transformObject(processStep as DynamicOrderObject);
  }, [processStep]);

  const updatedDynamicProcessStep = useMemo(
    () =>
      updateDynamicProcessStep(
        dynamicProcessStep,
        transformedProcessStep,
        (field: FieldType, value: string | number) => ({
          ...field,
          value,
        })
      ),
    [dynamicProcessStep, transformedProcessStep]
  );

  const sortedDynamicProcessSteps = useMemo(() => {
    const orderCards = updatedDynamicProcessStep.filter((card) =>
      card.title.includes("Order")
    );
    const otherCards = updatedDynamicProcessStep.filter(
      (card) => !card.title.includes("Order") && card.title !== "Misc"
    );

    return [...orderCards, ...otherCards];
  }, [updatedDynamicProcessStep]);

  const [activeKeys, setActiveKeys] = useState<string[]>(["production_gui"]);

  const handlePanelChange = (keys: string | string[]) => {
    if (typeof keys === "string") {
      keys = [keys];
    }
    setActiveKeys(keys);
  };

  return (
    <Collapse
      accordion={false}
      className="!white-arrow p-2 w-full"
      activeKey={activeKeys}
      onChange={handlePanelChange}
    >
      <Collapse.Panel
        header={
          <span className="!text-white">
            {orderProcessStepInfoBox?.title?.toUpperCase()}
          </span>
        }
        key="production_gui"
        className="w-full bg-blue-primary !text-white pl-3 py-2"
      >
        <ProductionGuiNBC
          processStepId={processStepId}
          orderProcessStepInfoBox={orderProcessStepInfoBox}
          orderStepTrackerResponseItem={orderStepTrackerResponseItem}
        />
      </Collapse.Panel>

      {sortedDynamicProcessSteps.map((card: any, cardIndex: number) => {
        const cardKey = `${card.title}_${cardIndex}`;

        return (
          <Collapse.Panel
            header={<span className="!text-white">{card.title}</span>}
            key={cardKey}
            className="w-full bg-blue-primary !text-white pl-3 py-2"
          >
            <ConfigCardStepFields card={card} key={card.title} />
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};

export { DynamicStepsContainer };
