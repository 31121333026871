import {
  ApiOutlined,
  CheckOutlined,
  ClusterOutlined,
  ControlOutlined,
  FileTextOutlined,
  FolderOutlined,
  InboxOutlined,
  MailOutlined,
  ReconciliationOutlined,
  SnippetsOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { MenuProps } from "antd";
import { Routes } from "shared/routers";

export const placement = "left";

const supplyChainKey = "sub1";
const productCardsKey = "sub2";
const ordersKey = "sub3";
const configKey = "sub4";
const reportsKey = "sub5";

export const supplyChainKeysArray = [supplyChainKey];
export const productCardsKeyArray = [productCardsKey];
export const ordersKeyArray = [ordersKey];
export const configKeyArray = [configKey];
export const reportsKeyArray = [reportsKey];

const parentIconStyle = "-mt-2.5 scale-125";

const parentItemStyles = {
  margin: "0px",
  width: "100%",
};

const childItemStyles = {
  width: "99%",
};

const companiesPage = {
  label: "sidebar.companies",
  key: Routes.companies.url,
  icon: <ClusterOutlined className={parentIconStyle} />,
  style: parentItemStyles,
};

const supplyChainPages = {
  label: "sidebar.supplyChain",
  key: supplyChainKey,
  icon: <ApiOutlined className={parentIconStyle} />,
  style: parentItemStyles,
  children: [
    {
      label: "sidebar.materialManagement",
      key: Routes.materialManagement.url,
      style: childItemStyles,
    },
    {
      label: "sidebar.categoryManagement",
      key: Routes.materialCategoryManagement.url,
      style: childItemStyles,
    },
    {
      label: "sidebar.itemView",
      key: Routes.itemView.url,
      style: childItemStyles,
    },
  ],
};

const productCardsPages = {
  label: "sidebar.productCards",
  key: productCardsKey,
  icon: <FileTextOutlined className={parentIconStyle} />,
  style: parentItemStyles,
  children: [
    {
      label: "sidebar.processTemplates",
      key: Routes.processTemplates.url,
      style: childItemStyles,
    },
    {
      label: "sidebar.machines",
      key: Routes.machines.url,
      style: childItemStyles,
    },
    {
      label: "sidebar.processSteps",
      key: Routes.processSteps.url,
      style: childItemStyles,
    },
    {
      label: "sidebar.productCards",
      key: Routes.productCards.url,
      style: childItemStyles,
    },
  ],
};

const ordersPages = {
  label: "sidebar.production",
  key: ordersKey,
  icon: <InboxOutlined className={parentIconStyle} />,
  style: parentItemStyles,
  children: [
    {
      label: "sidebar.ordersTable",
      key: Routes.orders.url,
      style: childItemStyles,
    },
    // Temporary hidden

    // {
    //   label: "sidebar.orderPlans",
    //   key: Routes.orderPlans.url,
    //   style: childItemStyles,
    // },
  ],
};

const reportPages = {
  label: "sidebar.reports",
  key: Routes.executeReport.url,
  icon: <SnippetsOutlined className={parentIconStyle} />,
  style: parentItemStyles,
};

const qualityPages = {
  label: "sidebar.quality",
  key: Routes.quality.url,
  icon: <CheckOutlined className={parentIconStyle} />,
  style: parentItemStyles,
};

const configPages = {
  label: "sidebar.config",
  key: Routes.config.url,
  icon: <ControlOutlined className={parentIconStyle} />,
  style: parentItemStyles,
  children: [
    {
      label: "sidebar.users",
      key: Routes.users.url,
      icon: <UserOutlined />,
      style: {
        margin: "0px",
        width: "100%",
      },
    },
    {
      label: "sidebar.recipientList",
      key: Routes.recipientList.url,
      icon: <MailOutlined />,
      style: {
        margin: "0px",
        width: "100%",
      },
    },
    {
      label: "sidebar.roles",
      key: Routes.roles.url,
      icon: <UserOutlined />,
      style: {
        margin: "0px",
        width: "100%",
      },
    },
    {
      label: "sidebar.templateConfig",
      key: Routes.templateConfig.url,
      icon: <ReconciliationOutlined />,
      style: {
        margin: "0px",
        width: "100%",
      },
    },
    {
      label: "sidebar.standAlone",
      key: Routes.standAlone.url,
      icon: <FolderOutlined className="!p-0" />,
    },
  ],
};

const dashboardsPages = {
  label: "sidebar.dashboards",
  key: Routes.dashboards.url,
  icon: <ClusterOutlined className={parentIconStyle} />,
  style: parentItemStyles,
  children: [],
};

export const sidebarItemsData: MenuProps["items"] = [
  companiesPage,
  supplyChainPages,
  productCardsPages,
  ordersPages,
  qualityPages,
  reportPages,
  dashboardsPages,
];

export const sidebarItemsUserData: MenuProps["items"] = [configPages];
