import { Table } from "antd";
import React from "react";

const OeeViewTable = () => {
  const columns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "User", dataIndex: "user", key: "user" },
    { title: "Product card", dataIndex: "productCard", key: "productCard" },
    { title: "Process", dataIndex: "process", key: "process" },
    { title: "Order", dataIndex: "order", key: "order" },
    {
      title: "Scheduled time",
      dataIndex: "scheduledTime",
      key: "scheduledTime",
    },
    { title: "Active time", dataIndex: "activeTime", key: "activeTime" },
    { title: "Setting time", dataIndex: "settingTime", key: "settingTime" },
    { title: "Deviation min", dataIndex: "deviationMin", key: "deviationMin" },
    {
      title: "Deviation in %",
      dataIndex: "deviationPercent",
      key: "deviationPercent",
    },
    { title: "Prod meter", dataIndex: "prodMeter", key: "prodMeter" },
    {
      title: "Investigation meters",
      dataIndex: "investigationMeters",
      key: "investigationMeters",
    },
    { title: "Waste meter", dataIndex: "wasteMeter", key: "wasteMeter" },
    { title: "OEE", dataIndex: "oee", key: "oee" },
  ];

  const data = Array.from({ length: 9 }, (_, i) => ({
    key: i,
    date: "2025-03-12",
    user: "Urban Winberg",
    productCard: "NTC GRADE Y",
    process: "REWINDER",
    order: 1233,
    activeTime: 10.0,
    settingTime: 0,
    deviation: "16.67%",
    prodMet: 15000,
    oee: i % 2 === 0 ? 80 : 90,
  }));

  return (
    <div className="col-span-2 bg-white p-4 rounded-lg shadow-md">
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 6 }}
        bordered
        rowClassName={(record, index) =>
          index % 2 === 0
            ? "bg-gray-50 hover:bg-gray-200 transition"
            : "bg-white hover:bg-gray-200 transition"
        }
      />
    </div>
  );
};

export { OeeViewTable };
