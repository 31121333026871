import { AdaptedValues } from "shared/types";
import { useMemo, useState } from "react";

interface UseCompanyKeysProps {
  selectedCompany: number[];
  adaptedCompanies: AdaptedValues[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  hideCustomerModal: () => void;
  nameValue: string;
  setSelectedCompanyIds?: (ids: number[]) => void;
}

export const useCustomerKeys = ({
  selectedCompany,
  adaptedCompanies,
  setFieldValue,
  hideCustomerModal,
  nameValue,
  setSelectedCompanyIds,
}: UseCompanyKeysProps) => {
  const fieldName = "company";
  const [selectedKeys, setSelectedKeys] = useState<number[]>(
    (selectedCompany as number[]) || []
  );

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedKeys(selectedRowKeys.map(Number));
    },
    getCheckboxProps: (record: AdaptedValues) => ({
      name: record.label,
    }),
  };

  const addCompany = () => {
    setFieldValue(fieldName, selectedKeys);
    setSelectedCompanyIds?.(selectedKeys);
    hideCustomerModal();
    setSelectedKeys([]);
  };

  const closeModal = () => {
    hideCustomerModal();
    setSelectedKeys([]);
  };

  const filteredCompanies = useMemo(() => {
    return adaptedCompanies
      .filter((company) =>
        company.label.toLowerCase().includes(nameValue.toLowerCase())
      )
      .filter((company) => !selectedCompany.includes(company.value));
  }, [adaptedCompanies, nameValue, selectedCompany]);

  return {
    rowSelection,
    addCompany,
    closeModal,
    filteredCompanies,
    selectedCompany: selectedKeys,
  };
};
