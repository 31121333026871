// components/SlidingSidebarFooter.tsx
import React from "react";
import classNames from "classnames";
import { User } from "shared/api";
import { useLocation } from "react-router-dom";
import { useSidebarData } from "shared/hooks";
import { Routes } from "shared/routers";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import { SidebarFooter } from "./SidebarFooter";
import { SidebarMenu } from "./SidebarMenu";

interface SlidingSidebarFooterProps {
  isOpen: boolean;
  onClose: () => void;
  getPersonalInfo: User | undefined;
}

const SlidingSidebarFooter: React.FC<SlidingSidebarFooterProps> = ({
  isOpen,
  onClose,
  getPersonalInfo,
}) => {
  const { pathname } = useLocation();

  const { selectMenuKeys, handleActiveLink, filteredSidebarItems } =
    useSidebarData({ pathname, getPersonalInfo, isUserMode: true });

  const neededKeys = [Routes.config.url];

  const filteredNeededItems = filteredSidebarItems.flatMap(
    (item: MenuItemType) => {
      if (neededKeys.includes(item.key as string)) {
        return [item];
      }
      if ("children" in item && Array.isArray(item.children)) {
        return item.children.filter((child) =>
          neededKeys.includes(child.key as string)
        );
      }
      return [];
    }
  );

  return (
    <>
      {/* Overlay */}
      <div
        aria-label="close-button"
        role="button"
        tabIndex={0}
        onClick={onClose}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            onClose();
          }
        }}
        className={classNames(
          "fixed inset-0 bg-black bg-opacity-30 z-40 transition-opacity",
          {
            "opacity-100 pointer-events-auto": isOpen,
            "opacity-0 pointer-events-none": !isOpen,
          }
        )}
      />

      {/* Sliding Footer */}
      <div
        className={classNames(
          "fixed bottom-0 right-0 w-full md:w-72 h-screen bg-blue-primary shadow-lg z-50 transition-transform duration-300 p-4 flex items-end flex-col",
          { "translate-x-0": isOpen, "translate-x-full": !isOpen }
        )}
      >
        <SidebarMenu
          handleActiveLink={handleActiveLink}
          pathname={pathname}
          translatedSidebarItems={filteredNeededItems}
          selectMenuKeys={selectMenuKeys}
        />
        <SidebarFooter />
      </div>
    </>
  );
};

export default SlidingSidebarFooter;
