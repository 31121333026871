import { notification } from "antd";
import { ExecuteReportValues } from "shared/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ReportItem,
  useExecuteReportMutation,
  useGetReportDataMutation,
} from "shared/api";
import { downloadJsonFile, handleRequestError } from "shared/helpers";

interface UseExecuteReportsRequestsProps {
  values: ExecuteReportValues;
  selectedReport?: ReportItem;
}

export const useExecuteReportsRequests = ({
  values,
  selectedReport,
}: UseExecuteReportsRequestsProps) => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });

  const [api, contextHolder] = notification.useNotification();

  const [executeReport, { isLoading: isLoadingReportExport }] =
    useExecuteReportMutation();

  const [getReportData, { isLoading: isLoadingReportData }] =
    useGetReportDataMutation();

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const clearPdfUrl = () => setPdfUrl(null);

  const handleExecuteReport = async () => {
    try {
      const response = await executeReport({
        id: values.id!,
        material_id: values.material_id,
        material_item_id: values.material_item_id,
        order_id: values.order_id,
        order_production_datetime_end: values.order_production_datetime_end,
        order_production_datetime_start: values.order_production_datetime_start,
        // order_wanted_delivery_datetime_end:
        //   values.order_wanted_delivery_datetime_end,
        // order_wanted_delivery_datetime_start:
        //   values.order_wanted_delivery_datetime_start,
        packaging_id: values.packaging_id,
        report_format: values.report_format,
        quality_result_id: values.quality_result_id,
        packaging_ids: values.packaging_ids,
      }).unwrap();

      if (response !== null && response !== undefined) {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        setPdfUrl(fileURL);
        // window.open(fileURL);
      }

      api.success({
        message: t("generatedReport"),
      });
    } catch (error) {
      api.error({
        message: t("error"),
        description: handleRequestError(error, t("error")),
      });
    }
  };

  const handleGetReportData = async () => {
    try {
      const response = await getReportData({
        id: values.id!,
        material_id: values.material_id,
        material_item_id: values.material_item_id,
        order_id: values.order_id,
        order_production_datetime_end: values.order_production_datetime_end,
        order_production_datetime_start: values.order_production_datetime_start,
        // order_wanted_delivery_datetime_end:
        //   values.order_wanted_delivery_datetime_end,
        // order_wanted_delivery_datetime_start:
        //   values.order_wanted_delivery_datetime_start,
        packaging_id: values.packaging_id,
        report_format: values.report_format,
        quality_result_id: values.quality_result_id,
        packaging_ids: values.packaging_ids,
      }).unwrap();

      downloadJsonFile(
        response,
        t("reportData", { reportName: selectedReport?.name })
      );

      api.success({
        message: t("generatedReport"),
      });
    } catch (error) {
      api.error({
        message: t("error"),
        description: handleRequestError(error, t("error")),
      });
    }
  };

  return {
    contextHolder,
    executeReports: {
      handleExecuteReport,
      isLoadingReportExport,
    },
    getReportData: {
      handleGetReportData,
      isLoadingReportData,
    },
    pdfUrl,
    clearPdfUrl,
  };
};
