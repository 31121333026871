import { ColumnsType } from "antd/es/table";
import { materialTableColumnsData } from "shared/constants";
import { MaterialCategoriesItem } from "shared/api";
import { TableButton, TableSearchFilter } from "shared/ui";
import { FilterFilled } from "@ant-design/icons";
import classNames from "classnames";
import { CategorySuppliers } from "pages/SupplyChain/MaterialCategoryManagement/ui";

interface UseGetMaterialCategoryColumnsProps {
  t: Function;
  editMaterialHandler: (record: MaterialCategoriesItem) => void;
  showDeleteCategoryModal: (record: MaterialCategoriesItem) => void;
  nameContainsValue: string;
  handleNameContainsValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearNameContainsValue: () => void;
  handleCategorySuppliersModal: (record: MaterialCategoriesItem) => void;
  hasEditPermission?: boolean;
  hasDeletePermission?: boolean;
}

export const useGetMaterialCategoryColumns = ({
  t,
  editMaterialHandler,
  showDeleteCategoryModal,
  nameContainsValue,
  handleNameContainsValue,
  clearNameContainsValue,
  handleCategorySuppliersModal,
  hasDeletePermission,
  hasEditPermission,
}: UseGetMaterialCategoryColumnsProps): ColumnsType<MaterialCategoriesItem> => {
  const columns: ColumnsType<MaterialCategoriesItem> = [
    {
      title: t(materialTableColumnsData.name.title),
      render: (item: MaterialCategoriesItem) => (
        <TableButton
          type="title"
          title={item?.name}
          onClick={
            hasEditPermission ? () => editMaterialHandler(item) : undefined
          }
        />
      ),
      filterIcon: (
        <FilterFilled
          className={classNames("", {
            "!text-green-600  scale-125": nameContainsValue.length > 0,
          })}
        />
      ),
      filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
        <TableSearchFilter
          setSelectedKeys={setSelectedKeys}
          clearFilters={clearNameContainsValue}
          confirm={confirm}
          value={nameContainsValue}
          handleFilterChange={handleNameContainsValue}
          visible={visible}
        />
      ),
    },

    {
      title: t(materialTableColumnsData.unit.title),
      key: materialTableColumnsData.unit.key,
      render: (item) => item?.unit?.name,
    },
    {
      title: t(materialTableColumnsData.uniqueCode.title),
      dataIndex: materialTableColumnsData.uniqueCode.dataIndex,
      key: materialTableColumnsData.uniqueCode.key,
    },
    {
      title: t(materialTableColumnsData.suppliers.title),
      render: (item: MaterialCategoriesItem) => (
        <CategorySuppliers
          materialCategory={item}
          handleCategorySuppliersModal={handleCategorySuppliersModal}
        />
      ),
    },
  ];

  if (hasDeletePermission) {
    columns.push({
      title: t(materialTableColumnsData.actions.title),
      key: materialTableColumnsData.actions.key,
      render: (record: MaterialCategoriesItem) => (
        <div className="w-full flex items-center justify-around">
          <TableButton
            type="delete"
            onClick={() => showDeleteCategoryModal(record)}
          />
        </div>
      ),
    });
  }

  return columns;
};
