import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  AddCompanyImageParams,
  AllCompaniesParams,
  CompaniesParams,
  CompaniesResponse,
  CompanyItem,
  CreateCompanyItem,
  CreateCompanyResponse,
  FilteredCompanyParams,
  SupplierCompanyItem,
} from "./models";
import { ConfigDynamicTrackerFieldsByDefault } from "../materials";

export const suppliersApi = createApi({
  baseQuery,
  reducerPath: "suppliersApi",
  tagTypes: ["Suppliers", "Companies"],
  endpoints: (builder) => ({
    getAllCompanies: builder.query<SupplierCompanyItem[], AllCompaniesParams>({
      query: (params) => ({
        url: "/api/v1/companies/",
        method: "GET",
        params,
      }),
      providesTags: ["Suppliers", "Companies"],
    }),

    getFilteredCompany: builder.query<
      SupplierCompanyItem[],
      FilteredCompanyParams
    >({
      query: (params) => ({
        url: "/api/v1/companies/",
        method: "GET",
        params,
      }),
      providesTags: ["Suppliers", "Companies"],
    }),

    deleteSupplier: builder.mutation<void, SupplierCompanyItem>({
      query: (body) => ({
        url: `/api/v1/materials/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Suppliers", "Companies"],
    }),

    // companies
    getAllCompaniesWithParams: builder.query<
      CompaniesResponse,
      CompaniesParams
    >({
      query: (params) => ({
        url: "/api/v1/companies/",
        method: "GET",
        params,
      }),
      providesTags: ["Companies", "Suppliers"],
    }),
    createCompany: builder.mutation<CreateCompanyResponse, CreateCompanyItem>({
      query: (body) => ({
        url: "/api/v1/companies/",
        method: "POST",
        body: { ...body },
      }),
      invalidatesTags: ["Companies", "Suppliers"],
    }),
    deleteCompany: builder.mutation<void, CompanyItem>({
      query: (body) => ({
        url: `/api/v1/companies/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Companies", "Suppliers"],
    }),
    updateCompany: builder.mutation<void, CompanyItem>({
      query: (body) => ({
        url: `/api/v1/companies/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Companies", "Suppliers"],
    }),
    addCompanyImage: builder.mutation<void, AddCompanyImageParams>({
      query: (body) => ({
        url: `/api/v1/companies/${body.id}/image/`,
        method: "POST",
        body: body.image,
        formData: true,
      }),

      invalidatesTags: ["Companies", "Suppliers"],
    }),
    getDefaultConfigDynamicFields: builder.query<
      ConfigDynamicTrackerFieldsByDefault[] | null,
      void
    >({
      query: () => ({
        url: "/api/v1/configurations/",
        method: "GET",
        params: { search: "default_config_dynamic_fields" },
      }),
      providesTags: ["Companies"],
    }),
  }),
});

export const {
  useGetDefaultConfigDynamicFieldsQuery,
  useGetAllCompaniesQuery,
  useDeleteSupplierMutation,
  useGetFilteredCompanyQuery,
  // companies
  useGetAllCompaniesWithParamsQuery,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useUpdateCompanyMutation,
  useAddCompanyImageMutation,
} = suppliersApi;
