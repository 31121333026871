import { ColumnsType } from "antd/es/table";
import { TFunction } from "i18next";
import {
  ProcessTemplatesSorting,
  processTemplatesColumnsData,
  sortDirections,
} from "shared/constants";
import { ProcessTemplateItem } from "shared/api";
import { TableButton, TableImage } from "shared/ui";

interface UseGetProcessTemplatesColumnsProps {
  t: TFunction;
  editProcessTypeHandler: (record: ProcessTemplateItem) => void;
  showDeleteProcessItemModal: (record: ProcessTemplateItem) => void;
  changeSort: (ascendOrdering: string, descendOrdering: string) => void;
  hasEditPermission?: boolean;
  hasDeletePermission?: boolean;
}

const { image, name, description, actions, abbreviation } =
  processTemplatesColumnsData;

export const useGetProcessTemplatesColumns = ({
  t,
  editProcessTypeHandler,
  showDeleteProcessItemModal,
  changeSort,
  hasDeletePermission,
  hasEditPermission,
}: UseGetProcessTemplatesColumnsProps): ColumnsType<ProcessTemplateItem> => {
  const columns: ColumnsType<ProcessTemplateItem> = [
    {
      title: t(image.title),
      render: (item: ProcessTemplateItem) => (
        <TableImage image={item?.image as string} />
      ),
    },
    {
      title: t(name.title),
      render: (item: ProcessTemplateItem) => (
        <TableButton
          type="title"
          title={item?.name}
          onClick={
            hasEditPermission ? () => editProcessTypeHandler(item) : undefined
          }
        />
      ),
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            ProcessTemplatesSorting.NAME_ASC,
            ProcessTemplatesSorting.NAME_DESC
          );
        },
      }),
      sorter: true,
      sortDirections,
    },
    {
      title: t(description.title),
      dataIndex: description.dataIndex,
      key: description.key,
    },
    {
      title: t(abbreviation.title),
      dataIndex: abbreviation.dataIndex,
      key: abbreviation.key,
    },
  ];

  if (hasDeletePermission) {
    columns.push({
      title: t(actions.title),
      key: actions.key,

      render: (record: ProcessTemplateItem) => {
        return (
          <div className="w-full flex items-center justify-around">
            <TableButton
              type="delete"
              onClick={() => showDeleteProcessItemModal(record)}
            />
          </div>
        );
      },
    });
  }

  return columns;
};
