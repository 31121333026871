import { FieldArray, useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormInput, FormSelect, TableButton } from "shared/ui";
import { AdaptedValues, AddProcessStepValues } from "shared/types";
import Title from "antd/es/typography/Title";
import { Button } from "antd";
import { InputMaterialConsumption } from "./InputMaterialConsumption";

interface InputMaterialsDynamicFormProps {
  adaptedMaterials: AdaptedValues[];
  isLoadingMaterials: boolean;
}
const InputMaterialsDynamicForm: FC<InputMaterialsDynamicFormProps> = ({
  adaptedMaterials,
  isLoadingMaterials,
}) => {
  const { t } = useTranslation();

  const { values, handleBlur, setFieldValue, handleChange } =
    useFormikContext<AddProcessStepValues>();

  return (
    <FieldArray
      name="input_materials"
      render={(arrayHelpers) => (
        <div>
          {values?.input_materials?.map((material, index) => {
            const materialValue =
              values?.input_materials[index]?.material || null;

            const materialFieldName = `input_materials.${index}.material`;
            const textInfoFieldName = `input_materials.${index}.info`;

            return (
              <div
                className="w-full relative flex items-center justify-between gap-x-3"
                key={index}
              >
                <div className="w-full">
                  <Title level={5}>
                    {t("process-step.input-materials-name")}
                  </Title>

                  <FormSelect
                    value={materialValue}
                    placeholder={t(
                      "process-step.input-material-type-placeholder"
                    )}
                    fieldName={materialFieldName}
                    options={adaptedMaterials}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    showSearch
                    isLoading={isLoadingMaterials}
                  />
                </div>

                <div className="w-full">
                  <Title level={5}>{t("process-step.info-field")}</Title>
                  <FormInput
                    value={material.info || ""}
                    fieldName={textInfoFieldName}
                    placeholder={t("process-step.info-placeholder")}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                </div>

                <InputMaterialConsumption
                  index={index}
                  adaptedMaterials={adaptedMaterials}
                />

                <TableButton
                  type="delete"
                  className="mt-2"
                  onClick={() => arrayHelpers.remove(index)}
                />
              </div>
            );
          })}
          <Button
            type="primary"
            className="btn-primary--dark mt-1"
            onClick={() => arrayHelpers.push("")}
          >
            {t("process-step.add-input-material")}
          </Button>
        </div>
      )}
    />
  );
};

export { InputMaterialsDynamicForm };
