import React from "react";
import { Page } from "shared/ui";
import { Sidebar } from "./Sidebar";
import { OeeViewTable } from "./OeeViewTable";
import { OEECharts } from "./OeeCharts";

const OeeView = () => {
  return (
    <Page title="">
      <div className="w-full ">
        <div className="flex justify-between">
          {/* Sidebar */}
          <div className="flex basis-[35%]">
            <Sidebar />
          </div>

          {/* Table */}
          <div className="flex basis-[65%]">
            <OeeViewTable />
          </div>
        </div>

        {/* Graphics OEE */}
        <OEECharts />
      </div>
    </Page>
  );
};

export { OeeView };
