import React from "react";
import { Progress } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsBoxSeam, BsGraphUp, BsPeopleFill } from "react-icons/bs";

const extrudeData = [
  { name: "PR232", value: 1534 },
  { name: "PR218", value: 1496 },
  { name: "PR149", value: 1346 },
  { name: "PR221", value: 1339 },
  { name: "PR211", value: 1325 },
  { name: "PR175", value: 1029 },
];

const rewindData = [
  { name: "PR232", value: 1534 },
  { name: "PR218", value: 1496 },
  { name: "PR149", value: 1346 },
  { name: "PR221", value: 1339 },
  { name: "PR211", value: 1325 },
  { name: "PR175", value: 1029 },
];
const Sidebar = () => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md w-full">
      {/* Основные показатели */}
      <div className="space-y-4">
        <div className="flex items-center space-x-3">
          <BsBoxSeam className="text-xl text-green-600" />
          <p className="text-xl font-bold">
            709,430 <span className="text-gray-500 text-sm">PRODUCED</span>
          </p>
        </div>

        <div className="flex items-center space-x-3">
          <BsGraphUp className="text-xl text-blue-600" />
          <p className="text-xl font-bold">
            757,472 <span className="text-gray-500 text-sm">PLANNED</span>
          </p>
        </div>

        <div className="flex items-center space-x-3">
          <BsPeopleFill className="text-xl text-red-600" />
          <p className="text-xl font-bold text-red-500">
            8,863 <span className="text-gray-500 text-sm">WASTE</span>
          </p>
        </div>
      </div>

      {/* Анализ продуктивности */}
      <div className="mt-6">
        <h3 className="text-md font-bold">PRODUCTIVITY ANALYSIS</h3>
        <div className="flex items-center space-x-3">
          <AiOutlineClockCircle className="text-xl text-gray-600" />
          <p className="text-lg font-semibold">
            39,109{" "}
            <span className="text-gray-500 text-sm">PRODUCTIVE HOURS</span>
          </p>
        </div>

        <div className="flex items-center space-x-3">
          <AiOutlineClockCircle className="text-xl text-gray-600" />
          <p className="text-lg font-semibold">
            2,937{" "}
            <span className="text-gray-500 text-sm">UNPRODUCTIVE HOURS</span>
          </p>
        </div>
      </div>

      {/* Индикаторы доступности и качества */}
      <div className="mt-6">
        <p className="text-md font-bold">AVAILABILITY</p>
        <div className="flex items-center space-x-2">
          <Progress
            percent={93.5}
            size="small"
            status="active"
            strokeColor="#FF5733"
          />
          <span className="font-semibold text-orange-600">93.5%</span>
        </div>

        <p className="text-md font-bold mt-2">QUALITY</p>
        <div className="flex items-center space-x-2">
          <Progress
            percent={98.6}
            size="small"
            status="active"
            strokeColor="#FF5733"
          />
          <span className="font-semibold text-orange-600">98.6%</span>
        </div>
      </div>

      {/* OEE RANK */}
      <div className="mt-6">
        <h3 className="text-md font-bold text-center">OEE RANK</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-sm font-semibold text-center">Extrude</p>
            <ResponsiveContainer width="100%" height={150}>
              <BarChart data={extrudeData} layout="vertical">
                <XAxis type="number" hide />
                <YAxis dataKey="name" type="category" width={50} />
                <Tooltip />
                <Bar dataKey="value" fill="#00A6FF" barSize={15} />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div>
            <p className="text-sm font-semibold text-center">Rewind</p>
            <ResponsiveContainer width="100%" height={150}>
              <BarChart data={rewindData} layout="vertical">
                <XAxis type="number" hide />
                <YAxis dataKey="name" type="category" width={50} />
                <Tooltip />
                <Bar dataKey="value" fill="#FF5733" barSize={15} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Sidebar };
