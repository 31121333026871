import React, { useState } from "react";
import Title from "antd/es/typography/Title";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useGetUserPersonalInfoQuery } from "shared/api";
import { Sidebar } from "../Sidebar";
import UserAvatar from "./UserAvatar";
import SlidingSidebarFooter from "../Sidebar/ui/SlidingSidebar";

interface PageProps {
  children: React.ReactNode;
  title: string;
  buttonTitle?: string;
  onClick?: () => void;
  hideTitle?: boolean;
}

const Page = ({
  children,
  title,
  buttonTitle,
  onClick,
  hideTitle = false,
}: PageProps) => {
  const { data: getPersonalInfo } = useGetUserPersonalInfoQuery();
  const [isFooterOpen, setIsFooterOpen] = useState(false);

  const handleUserSidebar = (value: boolean) => {
    setIsFooterOpen(value);
  };

  return (
    <div className="w-screen h-full flex">
      <Sidebar isOpenSidebar getPersonalInfo={getPersonalInfo} />

      <div className="w-full">
        <div className="w-full p-3.75">
          <div className="flex items-center justify-between">
            {hideTitle ? null : <Title className="capitalize">{title}</Title>}

            <UserAvatar handleUserSidebar={handleUserSidebar} />
          </div>

          {buttonTitle && (
            <div className="flex justify-between items-center">
              <Button
                type="primary"
                className="btn-primary--dark"
                onClick={onClick}
              >
                {buttonTitle}
                <PlusOutlined className="mt-1" />
              </Button>
            </div>
          )}

          {children}
        </div>
      </div>
      <SlidingSidebarFooter
        isOpen={isFooterOpen}
        onClose={() => setIsFooterOpen(false)}
        getPersonalInfo={getPersonalInfo}
      />
    </div>
  );
};

export { Page };
