import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { Page } from "shared/ui";
import { useModal, useProcessStepDynamicData } from "shared/hooks";
import { noop } from "shared/helpers";
import { AccessPoints } from "shared/routers";
import {
  adaptMaterials,
  adaptProcessTemplates,
  adaptDynamicFormValues,
} from "shared/adapters";
import {
  useGetAllMaterialsNoPaginationQuery,
  useGetAppProcessTemplatesWithNoParamsQuery,
  ConfigDynamicTrackerFields,
  UserItem,
} from "shared/api";
import { processStepsInitialValues } from "shared/constants";
import { DynamicValues } from "shared/types";
import { createAddOrUpdateProcessStepSchema } from "shared/schemas";
import {
  ProcessStepTable,
  AddProcessStepModal,
  EditProcessStepModal,
} from "./ui";

interface ProcessStepsProps {
  user?: UserItem;
}

const ProcessSteps: FC<ProcessStepsProps> = ({ user }) => {
  const [dynamicValues, setDynamicValues] = useState<
    DynamicValues[] | ConfigDynamicTrackerFields[]
  >([]);

  const hasAddPermission = user?.access_points?.includes(
    AccessPoints.PROCESS_STEPS_ADD
  );

  const hasEditPermission = user?.access_points?.includes(
    AccessPoints.PROCESS_STEPS_EDIT
  );

  const hasDeletePermission = user?.access_points?.includes(
    AccessPoints.PROCESS_STEPS_DELETE
  );

  const handleDynamicValues = (dynamicValues: DynamicValues[]) =>
    setDynamicValues(dynamicValues);

  const dynamicFieldsValidation = adaptDynamicFormValues(dynamicValues);

  const addOrUpdateProcessStepFormik = useFormik({
    initialValues: processStepsInitialValues,
    validationSchema: createAddOrUpdateProcessStepSchema(
      dynamicFieldsValidation
    ),
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: noop,
  });

  const { setFieldValue } = addOrUpdateProcessStepFormik;

  const { t } = useTranslation();

  const [
    isOpenAddProcessStepModal,
    showAddProcessStepModal,
    hideAddProcessStepModal,
  ] = useModal();

  const [
    isOpenEditProcessStepModal,
    showEditProcessStepModal,
    hideEditProcessStepModal,
  ] = useModal();

  const { data: materials, isLoading: isLoadingMaterials } =
    useGetAllMaterialsNoPaginationQuery();

  const adaptedMaterials = useMemo(() => {
    return adaptMaterials(materials || []);
  }, [materials]);

  const { data: processTemplates } =
    useGetAppProcessTemplatesWithNoParamsQuery();

  const adaptedProcessTemplates = useMemo(() => {
    return adaptProcessTemplates(processTemplates || []);
  }, [processTemplates]);

  const { dynamicProcessTemplateFields } = useProcessStepDynamicData({
    processTemplates,
    values: addOrUpdateProcessStepFormik.values,
    handleDynamicValues,
    setFieldValue,
  });

  return (
    <FormikProvider value={addOrUpdateProcessStepFormik}>
      <Page
        title={t("process-step.title")}
        buttonTitle={
          hasAddPermission ? t("process-step.add-process-step")! : undefined
        }
        onClick={hasAddPermission ? showAddProcessStepModal : undefined}
      >
        <ProcessStepTable
          hasEditPermission={hasEditPermission}
          hasDeletePermission={hasDeletePermission}
          showEditProcessStepModal={showEditProcessStepModal}
        />

        <AddProcessStepModal
          isOpenModal={isOpenAddProcessStepModal}
          hideAddProcessStepModal={hideAddProcessStepModal}
          adaptedProcessTemplates={adaptedProcessTemplates}
          adaptedMaterials={adaptedMaterials}
          dynamicProcessTemplateFields={dynamicProcessTemplateFields}
          isLoadingMaterials={isLoadingMaterials}
        />

        <EditProcessStepModal
          adaptedProcessTemplates={adaptedProcessTemplates}
          adaptedMaterials={adaptedMaterials}
          dynamicProcessTemplateFields={dynamicProcessTemplateFields}
          isOpenModal={isOpenEditProcessStepModal}
          hideEditProcessStepModal={hideEditProcessStepModal}
          isLoadingMaterials={isLoadingMaterials}
        />
      </Page>
    </FormikProvider>
  );
};

export { ProcessSteps };
