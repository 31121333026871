import React, { FC, useMemo, useState } from "react";
import { Table, notification } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import {
  useConfirmDeleteModal,
  useDateFilters,
  useDebouncedTableFilter,
  useGetOrderTableColumns,
  useModal,
  useGetOrderIdToUpdate,
  useOrdersRequests,
  usePagination,
  useRange,
  useRenderClearOrderFiltersButton,
  useTableSorting,
  useDownLoadPdfFile,
} from "shared/hooks";
import { findMaxQuantities, handleRequestError } from "shared/helpers";
import {
  useDeleteOrderMutation,
  useGetAllOrdersQuery,
  useGetAllOrdersWithNoParamsQuery,
  OrderItem,
  useGetReportsQuery,
  useExecuteReportMutation,
  ExecuteReport,
  ReportItem,
} from "shared/api";
import { AdaptedValues, AddOrUpdateOrderValues } from "shared/types";
import {
  FilterStatusDropdownValues,
  expandOrdersString,
} from "shared/constants";
import { GenerateMultiplePackagingReportModal } from "./GenerateMultiplePackagingReportModal";

interface OrdersTableProps {
  showEditOrderModal: () => void;
  hasEditPermission?: boolean;
  hasDeletePermission?: boolean;
  hasProductionPermission?: boolean;
  companiesAdapted?: AdaptedValues[];
}

const OrdersTable: FC<OrdersTableProps> = ({
  showEditOrderModal,
  hasDeletePermission,
  hasEditPermission,
  hasProductionPermission,
  companiesAdapted,
}) => {
  const { t } = useTranslation();

  const timezone = localStorage.getItem("timezone") ?? "UTC";

  const [deleteOrder] = useDeleteOrderMutation();
  const [executeReport] = useExecuteReportMutation();

  const [api, contextHolder] = notification.useNotification();

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const { setValues, values } = useFormikContext<AddOrUpdateOrderValues>();

  const { data: allOrdersToGetQuantity } = useGetAllOrdersWithNoParamsQuery({});

  const { orderId, selectOrderIdToUpdate, clearOrderId } =
    useGetOrderIdToUpdate();

  const maxQuantityValue = useMemo(() => {
    return findMaxQuantities(allOrdersToGetQuantity || []);
  }, [allOrdersToGetQuantity]);

  const { ordering, changeSort } = useTableSorting();

  const {
    debouncedValue: debouncedPoNumberContains,
    handleFilterChange: handlePoNumberContains,
    value: poNumberContainsValue,
    clearFilterValue: clearPoNumberContainsValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedOrderCodeContains,
    handleFilterChange: handleOrderCodeContains,
    value: orderCodeContainsValue,
    clearFilterValue: clearOrderCodeContainsValue,
  } = useDebouncedTableFilter("", 400);

  const {
    debouncedValue: debouncedProductCardContains,
    handleFilterChange: handleProductCardContains,
    value: productCardContainsValue,
    clearFilterValue: clearProductCardContainsValue,
  } = useDebouncedTableFilter("", 400);

  const {
    minValue: quantityGreaterThen,
    maxValue: quantityLowerThen,
    onChange: onChangeQuantity,
    onAfterChange: onAfterQuantityChange,
    reset: resetQuantityRange,
    isFilterActive: isQuantityFilterActive,
  } = useRange({ maxValueLimit: maxQuantityValue });

  const {
    dateGreater: wantedDeliveryDateGreater,
    dateLesser: wantedDeliveryDateLesser,
    handleDateGreater: handleWantedDeliveryGreater,
    handleDateLesser: handleWantedDeliverLesser,
    clearDates: clearWantedDeliveryDateFilter,
    isDataFilterActive: isWantedDateFilterActive,
  } = useDateFilters();

  const {
    dateGreater: productionDateGreater,
    dateLesser: productionDateLesser,
    handleDateGreater: handleProductionDateGreater,
    handleDateLesser: handleProductionDateLesser,
    clearDates: clearProductionDatesFilter,
    isDataFilterActive: isProductionDateFilterActive,
  } = useDateFilters();

  const [orderStatus, setOrderStatus] = useState<number | string>("");
  const [selectedCustomer, setSelectedCustomer] = useState<string | number>("");

  const handleOrderStatus = (value: number | string) => {
    setOrderStatus(value);
  };

  const handleCustomerChange = (value: string | number) => {
    setSelectedCustomer(value);
  };

  const clearAllFilters = () => {
    clearPoNumberContainsValue();
    clearWantedDeliveryDateFilter();
    clearProductionDatesFilter();
    resetQuantityRange(0, maxQuantityValue);
    setOrderStatus("");
    clearOrderCodeContainsValue();
    clearProductCardContainsValue();
    handleCustomerChange("");
  };

  const [
    isVisibleGenerateMultiplePackagingReportModal,
    showGenerateMultiplePackagingReportModal,
    hideGenerateMultiplePackagingReportModal,
  ] = useModal();

  const showGenerateMultiplePackagingReportModalHandler = (orderId: number) => {
    showGenerateMultiplePackagingReportModal();
    selectOrderIdToUpdate(orderId);
  };

  const {
    data: orders,
    isLoading: isLoadingOrders,
    isFetching: isFetchingOrders,
  } = useGetAllOrdersQuery({
    offset,
    limit,
    expand: expandOrdersString,
    ordering,
    po_number__icontains: debouncedPoNumberContains,
    quantity_expected__gte: quantityGreaterThen || "",
    quantity_expected__lte: quantityLowerThen || "",
    wanted_delivery_datetime__gte: wantedDeliveryDateGreater || "",
    wanted_delivery_datetime__lte: wantedDeliveryDateLesser || "",
    production_datetime__gte: productionDateGreater || "",
    production_datetime__lte: productionDateLesser || "",
    status: orderStatus as string,
    buyer: selectedCustomer || "",
    order_code: debouncedOrderCodeContains || "",
    product_card__name__icontains: debouncedProductCardContains || "",
  });

  const { data: reportsData } = useGetReportsQuery<{ data: ReportItem[] }>({
    report_type: "ORDER",
  });

  const handleDownloadReport = async (reportId: number) => {
    try {
      const reportParams: ExecuteReport = {
        id: reportId,
        material_item_id: null,
        packaging_id: null,
        order_id: reportId,
        material_id: null,
        // order_wanted_delivery_datetime_start: null,
        // order_wanted_delivery_datetime_end: null,
        order_production_datetime_start: null,
        order_production_datetime_end: null,
        report_format: "pdf",
        quality_result_id: null,
        packaging_ids: null,
      };

      const blob = await executeReport(reportParams).unwrap();

      const pdfUrl = window.URL.createObjectURL(blob);
      const { downloadPdfFile } = useDownLoadPdfFile({
        pdfUrl,
        nameToSetToPdf: `report_${reportId}`,
      });

      downloadPdfFile();
    } catch (error) {
      notification.error({
        message: t("error"),
        description: t("Failed to download report."),
      });
    }
  };

  const customers = useMemo(() => {
    return (companiesAdapted || []).map((company) => ({
      label: company.label,
      value: company.value,
    }));
  }, [companiesAdapted]);

  const deleteOrderHandler = async (record: OrderItem) => {
    try {
      await deleteOrder(record).unwrap();
      api.success({
        message: t("orders.success"),
        description: t("orders.delete-msg"),
      });
    } catch (error) {
      const description = handleRequestError(error, t("orders.error"));

      api.error({
        message: t("orders.error"),
        description,
      });
    }
  };

  const showDeleteOrderModal = useConfirmDeleteModal(
    t("orders.delete-alert"),
    deleteOrderHandler
  );

  const editOrderHandler = (record: OrderItem) => {
    showEditOrderModal();
    setValues({
      id: record.id,
      buyer: record.buyer.id,
      po_number: record.po_number,
      product_card: record.product_card.id,
      production_datetime: record.production_datetime,
      quantity_expected: record.quantity_expected,
      status: record.status,
      wanted_delivery_datetime: record.wanted_delivery_datetime,
      dynamic_order_fields: record.dynamic_order_fields,
      customer_order_number: record.customer_order_number,
    });
  };

  const {
    updateOrderWantedDeliveryTimeRequest,
    updateOrderWantedProductionTimeRequest,
    updateOrderStatusRequest,
  } = useOrdersRequests();

  const columns = useGetOrderTableColumns({
    t,
    editOrderHandler,
    changeSort,
    isProductionDateFilterActive,
    handleProductionDateGreater,
    handleProductionDateLesser,
    isWantedDateFilterActive,
    handleWantedDeliveryGreater,
    handleWantedDeliverLesser,
    isQuantityFilterActive,
    maxQuantityValue,
    onChangeQuantity,
    onAfterQuantityChange,
    selectOrderIdToUpdate,
    orderToChangeStatusId: orderId,
    clearOrderId,
    FilterStatusDropdownValues,
    showDeleteOrderModal,
    updateOrderWantedDeliveryTimeRequest,
    updateOrderWantedProductionTimeRequest,
    updateOrderStatusRequest,
    handleOrderCodeContains,
    orderCodeContainsValue,
    clearOrderCodeContainsValue,
    handlePoNumberContains,
    poNumberContainsValue,
    clearPoNumberContainsValue,
    showGenerateMultiplePackagingReportModalHandler,
    orderStatus,
    handleOrderStatus,
    timezone: timezone ?? "UTC",
    handleProductCardContains,
    productCardContainsValue,
    clearProductCardContainsValue,
    selectedCustomer,
    handleCustomerChange,
    customers,
    reportsData,
    handleDownloadReport,
    hasDeletePermission,
    hasEditPermission,
    hasProductionPermission,
  });

  const { renderButtonAndClearAllFilters } = useRenderClearOrderFiltersButton({
    poNumberContains: poNumberContainsValue,
    filterStatusValue: orderStatus as string,
    productionDateLesser,
    productionDateGreater,
    wantedDeliveryDateLesser,
    wantedDeliveryDateGreater,
    quantityLowerThen,
    quantityGreaterThen,
    orderCodeContains: orderCodeContainsValue,
    maxQuantityValue,
    values,
    clearAllFilters,
    productCardContainsValue,
  });

  return (
    <>
      {contextHolder}
      {renderButtonAndClearAllFilters()}

      <Table
        loading={isLoadingOrders || isFetchingOrders}
        columns={columns}
        dataSource={orders?.results}
        rowKey={(obj) => obj.id!}
        rowClassName="!p-0"
        className="overflow-x-auto"
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: orders?.count,
        }}
      />

      <GenerateMultiplePackagingReportModal
        isOpen={isVisibleGenerateMultiplePackagingReportModal}
        onClose={hideGenerateMultiplePackagingReportModal}
        orderIdToGenerateReport={orderId}
        clearOrderId={clearOrderId}
      />
    </>
  );
};

export { OrdersTable };
