import { FilterFilled } from "@ant-design/icons";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import classNames from "classnames";
import { ColumnsType } from "antd/es/table";
import { TFunction } from "i18next";
import {
  ConfigDynamicTrackerFields,
  ExpandedOrderProcessStepTracker,
  MaterialStockItem,
  OrderItem,
} from "shared/api";
import {
  columnsMaterialItemsData,
  materialItemSituations,
  materialItemStatuses,
  sortDirections,
} from "shared/constants";
import {
  openHyperlinkInTab,
  renderMaterialItemsSituations,
  renderMaterialItemsStatuses,
} from "shared/helpers";
import { Routes } from "shared/routers";
import {
  DatePickerValue,
  DateRange,
  DateWithTimeRow,
  MultipleOptionsTableFilter,
  PopConfirmButton,
  TableSearchFilter,
} from "shared/ui";

const enum ItemViewTableSorting {
  SERIAL_NUMBER_ASC = "serial_number",
  SERIAL_NUMBER_DESC = "-serial_number",
  TIMESTAMP_ASC = "timestamp",
  TIMESTAMP_DESC = "-timestamp",
  STATUS_ASC = "status",
  STATUS_DESC = "-status",
  CALC_TOTAL_REMAINING_QUANTITY_ASC = "calc_total_remaining_quantity",
  CALC_TOTAL_REMAINING_QUANTITY_DESC = "-calc_total_remaining_quantity",
}

interface ItemViewTableProps {
  t: TFunction;
  changeSort: (ascendOrdering: string, descendOrdering: string) => void;
  // Serial Number Filter
  handleSerialNumberValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  serialNumberValue: string;
  clearSerialNumberValue: () => void;
  // Timestamp filter
  handleDateGreater: (date: DatePickerValue, dateString: string) => void;
  handleDateLesser: (date: DatePickerValue, dateString: string) => void;
  isDataFilterActive: boolean;
  dynamicFields: ConfigDynamicTrackerFields[];
  // status filter
  handleStatusChange: (checkedValues: CheckboxValueType[]) => void;
  selectedStatus: CheckboxValueType[];
  handleMaterialNameValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  materialNameValue: string;
  clearMaterialNameValue: () => void;
  // situation filter
  handleSituationChange: (checkedValues: CheckboxValueType[]) => void;
  selectedSituation: CheckboxValueType[];
  hasEditPermission?: boolean;
}

const useGenerateItemViewColumns = ({
  t,
  changeSort,
  // Serial Number Filter
  handleSerialNumberValue,
  serialNumberValue,
  clearSerialNumberValue,
  // Timestamp filter
  handleDateGreater,
  handleDateLesser,
  isDataFilterActive,
  dynamicFields,
  handleStatusChange,
  selectedStatus,
  handleMaterialNameValue,
  materialNameValue,
  clearMaterialNameValue,
  handleSituationChange,
  selectedSituation,
  hasEditPermission,
}: ItemViewTableProps): ColumnsType<MaterialStockItem> => {
  const staticColumns: ColumnsType<MaterialStockItem> = [
    {
      title: t("itemView.serialNumber"),
      render: (item: MaterialStockItem) => {
        const materialLink =
          `${Routes.materialManagement.hyperlink}/${item?.material_stock_batch?.material?.id}`?.replace(
            ":id/",
            ""
          );

        return (
          <PopConfirmButton
            buttonContent={item?.serial_number}
            onClick={
              hasEditPermission
                ? () => openHyperlinkInTab(materialLink)
                : undefined
            }
          />
        );
      },
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            ItemViewTableSorting.SERIAL_NUMBER_ASC,
            ItemViewTableSorting.SERIAL_NUMBER_DESC
          );
        },
      }),
      sorter: true,
      sortDirections,
      filterIcon: (
        <FilterFilled
          className={
            serialNumberValue.length ? "!text-blue-600  scale-125" : undefined
          }
        />
      ),
      filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
        <TableSearchFilter
          setSelectedKeys={setSelectedKeys}
          clearFilters={clearSerialNumberValue}
          confirm={confirm}
          value={serialNumberValue!}
          handleFilterChange={handleSerialNumberValue!}
          visible={visible}
          placeholder={t("itemView.serialNumberPlaceholder")!}
          title={t("itemView.serialNumber")!}
        />
      ),
    },
    {
      title: t("itemView.timestamp"),
      render: (item: MaterialStockItem) => (
        <DateWithTimeRow text={item?.timestamp as string} />
      ),
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            ItemViewTableSorting.TIMESTAMP_ASC,
            ItemViewTableSorting.TIMESTAMP_DESC
          );
        },
      }),
      sorter: true,
      sortDirections,
      filterDropdown: () => {
        return (
          <DateRange
            title={t("itemView.timestamp")}
            leftPickerTitle={t("itemView.timestampDateLesser")}
            rightPickerTitle={t("itemView.timestampDateGreater")}
            handlePickerChangeGreater={handleDateGreater}
            handlePickerChangeLesser={handleDateLesser}
          />
        );
      },
      filterIcon: (
        <FilterFilled
          className={
            isDataFilterActive ? "!text-yellow-600  scale-125" : undefined
          }
        />
      ),
    },
    {
      title: t("itemView.material"),
      render: (item: MaterialStockItem) => {
        const materialLink =
          `${Routes.materialManagement.hyperlink}/${item?.material_stock_batch?.material?.id}`?.replace(
            ":id/",
            ""
          );

        return (
          <PopConfirmButton
            buttonContent={item?.material_stock_batch?.material?.name}
            onClick={
              hasEditPermission
                ? () => openHyperlinkInTab(materialLink)
                : undefined
            }
          />
        );
      },
      filterIcon: (
        <FilterFilled
          className={classNames("", {
            "!text-green-500 scale-125": materialNameValue.length > 0,
          })}
        />
      ),
      filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
        <TableSearchFilter
          setSelectedKeys={setSelectedKeys}
          clearFilters={clearMaterialNameValue}
          confirm={confirm}
          value={materialNameValue}
          handleFilterChange={handleMaterialNameValue}
          visible={visible}
          title={t("itemView.material") || ""}
          placeholder={t("itemView.materialPlaceholder") || ""}
        />
      ),
      sorter: true,
      sortDirections,
      onHeaderCell: () => ({
        onClick: () => {
          changeSort("material_name_asc", "material_name_desc");
        },
      }),
    },
    {
      title: t("itemView.status"),
      render: (item: MaterialStockItem) => renderMaterialItemsStatuses(item),
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            ItemViewTableSorting.STATUS_ASC,
            ItemViewTableSorting.STATUS_DESC
          );
        },
      }),
      sorter: true,
      sortDirections,
      filterDropdown: ({ clearFilters }) => (
        <MultipleOptionsTableFilter
          options={materialItemStatuses}
          selectedValues={selectedStatus}
          handleChange={handleStatusChange}
          clearFilters={clearFilters}
        />
      ),
      filteredValue: selectedStatus,
    },
    {
      title: t(columnsMaterialItemsData.situation.title),
      render: (item: MaterialStockItem) => renderMaterialItemsSituations(item),
      filterDropdown: ({ clearFilters }) => (
        <MultipleOptionsTableFilter
          options={materialItemSituations}
          selectedValues={selectedSituation}
          handleChange={handleSituationChange}
          clearFilters={clearFilters}
        />
      ),
      filteredValue: selectedSituation,
    },
    {
      title: t("itemView.calcTotalRemainingQuantity"),
      render: (item: MaterialStockItem) => item?.calc_total_remaining_quantity,
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(
            ItemViewTableSorting.CALC_TOTAL_REMAINING_QUANTITY_ASC,
            ItemViewTableSorting.CALC_TOTAL_REMAINING_QUANTITY_DESC
          );
        },
      }),
      sorter: true,
      sortDirections,
    },
    {
      title: t("itemView.order"),
      render: (item: MaterialStockItem) => {
        const orderProcessStepTracker = item?.order_production_set
          ?.order_process_step_tracker as ExpandedOrderProcessStepTracker;

        const order = orderProcessStepTracker?.order as OrderItem;

        const redirectLink = `process-step-production/${order?.order_code}/index-1`;

        return (
          <PopConfirmButton
            buttonContent={order?.order_code as string}
            onClick={() => openHyperlinkInTab(redirectLink)}
          />
        );
      },
    },
    {
      title: t("itemView.movedToOrder"),
      render: (item: MaterialStockItem) => {
        const movedToOrder = item?.moved_to_order;
        const redirectLink = `process-step-production/${movedToOrder?.order_code}/index-1`;

        return movedToOrder?.order_code ? (
          <PopConfirmButton
            buttonContent={movedToOrder.order_code}
            onClick={() => openHyperlinkInTab(redirectLink)}
          />
        ) : (
          []
        );
      },
    },
  ];
  const dynamicColumns: ColumnsType<MaterialStockItem> = dynamicFields
    ? dynamicFields.flatMap((fieldGroup) =>
        fieldGroup.fields.map((field) => ({
          title: `${fieldGroup.title} - ${field.name}`,

          render: (item: MaterialStockItem) =>
            item?.dynamic_tracker_fields?.[fieldGroup.title]?.[field.name],
        }))
      )
    : [];

  return [...staticColumns, ...dynamicColumns];
};

export { useGenerateItemViewColumns };
