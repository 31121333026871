import { ReportType } from "shared/api";

export const templateConfigsColumnsData = {
  name: {
    title: "reportsTableColumns.name",
    width: "20%",
  },
  templateId: {
    title: "reportsTableColumns.templateId",
    width: "20%",
  },
  reportType: {
    title: "reportsTableColumns.reportType",
    width: "20%",
  },
  isDefault: {
    title: "reportsTableColumns.isDefault",
    width: "20%",
  },
  actions: {
    title: "reportsTableColumns.actions",
    width: "5%",
  },
};

export const initialTemplateConfigFormikValues = {
  id: null,
  name: null,
  templateId: null,
  reportType: null,
  isDefault: null,
};

export const isDefaultOptions = [
  { value: true, label: "Default" },
  { value: false, label: "Not Default" },
];

export const reportTypesOptions = [
  { value: ReportType.MATERIAL_ITEM, label: "Material Item" },
  { value: ReportType.PACKAGING, label: "Packaging" },
  { value: ReportType.REEL_LAB_ARCHIVE, label: "Reel Lab Archive" },
  { value: ReportType.ORDER, label: "Order" },
  { value: ReportType.MULTIPLE_ORDER, label: "Multiple Order" },
  { value: ReportType.MULTIPLE_PACKAGING, label: "Multiple Packaging" },
  { value: ReportType.MULTIPLE_MATERIAL, label: "Multiple Material" },
  { value: ReportType.MULTIPLE_QUALITY, label: "Multiple Quality" },
  { value: ReportType.MULTIPLE_MATERIAL_ITEM, label: "Multiple Material Item" },
  { value: ReportType.MANAGEMENT, label: "Management" },
];
