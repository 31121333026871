import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ProductCardItem } from "shared/api";
import { Routes } from "shared/routers";

interface CustomerByProductCardProps {
  item: ProductCardItem;
}

const CustomerByProductCard: FC<CustomerByProductCardProps> = ({ item }) => {
  if (!item.companytoproductcards || item.companytoproductcards.length === 0) {
    return <span className="text-sm text-red-100">N/A</span>;
  }

  const customers = item.companytoproductcards
    .map((company: any) => ({
      name: company.company.name || "Unknown",
      id: company.company.id,
    }))
    .filter(
      (customer, index, self) =>
        customer.name &&
        self.findIndex((c) => c.name === customer.name) === index
    );

  return (
    <div className="flex flex-col gap-y-2">
      {customers.map((customer) => (
        <Link
          key={customer.id}
          to={Routes.companies.hyperlink.replace(
            ":companyID",
            String(customer.id)
          )}
          className="text-blue-600 hover:underline"
        >
          {customer.name}
        </Link>
      ))}
    </div>
  );
};

export { CustomerByProductCard };
