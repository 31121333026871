import React, { FC, useState } from "react";
import { notification, Table } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useConfirmDeleteModal, useGetUsersColumns } from "shared/hooks";
import { handleRequestError } from "shared/helpers";
import {
  UserItem,
  UsersResponse,
  useDeleteUserMutation,
  useSetNewPasswordMutation,
} from "shared/api";
import { useChangePasswordModal } from "shared/hooks/useChangePasswordModal";

interface UsersTableProps {
  showEditUserModal: () => void;
  allUsers?: UsersResponse;
  isLoadingUsersFetching: boolean;
  isLoadingAllUsers: boolean;
  refetch: () => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  currentPage: number;
  changeSort: (ascendOrdering: string, descendOrdering: string) => void;
}

const UsersTable: FC<UsersTableProps> = ({
  showEditUserModal,
  allUsers,
  isLoadingUsersFetching,
  isLoadingAllUsers,
  refetch,
  pageSize,
  setCurrentPage,
  setPageSize,
  currentPage,
  changeSort,
}) => {
  const { setValues } = useFormikContext<UserItem>();
  const { t } = useTranslation();

  const editUserHandler = (record: UserItem) => {
    showEditUserModal();
    setValues({
      first_name: record.first_name,
      last_name: record.last_name,
      username: record.username,
      email: record.email,
      id: record.id,
      roles: record.roles,
      is_superuser: record.is_superuser,
    });
  };

  const [api, contextHolder] = notification.useNotification();

  const [deleteUser] = useDeleteUserMutation();
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [setNewPassword, { isLoading }] = useSetNewPasswordMutation();

  const deleteUserHandler = async (record: UserItem) => {
    try {
      await deleteUser(record).unwrap();
      api.success({
        message: t("users-page.success-title"),
        description: t("users-page.success-delete-desc"),
      });
      refetch();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("users-page.error-title")
      );
      api.error({
        message: t("users-page.error-title"),
        description,
      });
    }
  };

  const showDeleteUserModal = useConfirmDeleteModal(
    t("modal-placeholders.delete-warning-message"),
    deleteUserHandler
  );

  const changePasswordModal = useChangePasswordModal(
    t("Set a new password"),
    async (newPassword) => {
      if (!selectedUserId) return;
      await setNewPassword({ userId: selectedUserId, newPassword }).unwrap();
      api.success({
        message: t("The password was successfully set"),
      });
    },
    isLoading
  );

  const showChangePasswordModal = (userId: number) => {
    setSelectedUserId(userId);
    changePasswordModal();
  };

  const columns = useGetUsersColumns({
    t,
    editUserHandler,
    showDeleteUserModal,
    showChangePasswordModal,
    changeSort,
  });

  return (
    <>
      {contextHolder}
      <Table
        columns={columns}
        dataSource={allUsers?.results}
        rowKey={(obj) => obj.id!}
        loading={isLoadingUsersFetching || isLoadingAllUsers}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: allUsers?.count,
        }}
      />
    </>
  );
};

export { UsersTable };
