import React, { FC, useCallback, useState } from "react";
import { OrderProcessInfoBox, OrderStepTrackerResponseItem } from "shared/api";
import { useFormikContext } from "formik";
import {
  useInputMaterialsProductionPageData,
  useOutputMaterialDataForProductionPage,
  useOutputMaterialsProductionPageData,
  useProdPageContentData,
} from "shared/hooks";
import { InputMaterialsContainer } from "./InputMaterialsContainer";
import { TrackingList } from "./TrackingList";
import { AddProductionRequest } from "./ProcessStepProduction";
import { OutputMaterialsContainer } from "./OutputMaterialsTable";
import { OrderCard } from "./OrderCard";

interface ProdPageContentProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  processStepId?: number;
  orderId?: string;
  openProducedItemInOrderModal: () => void;
  refetchOrderProcessStepTracker: () => void;
  orderProcessStepInfoBox?: OrderProcessInfoBox;
}

const ProdPageContent: FC<ProdPageContentProps> = ({
  orderStepTrackerResponseItem,
  processStepId,
  orderId,
  openProducedItemInOrderModal,
  refetchOrderProcessStepTracker,
  orderProcessStepInfoBox,
}) => {
  const { setFieldValue, isValid, values } =
    useFormikContext<AddProductionRequest>();

  const { inputMaterials, adaptedInitialInputMaterials } =
    useInputMaterialsProductionPageData({
      orderStepTrackerResponseItem,
    });

  const { adaptedMachines } = useProdPageContentData({
    orderId,
    orderStepTrackerResponseItem,
  });

  const { outputMaterials, adaptedInitialOutputMaterials } =
    useOutputMaterialsProductionPageData({
      orderStepTrackerResponseItem,
      setFieldValue,
    });

  const {
    handleCountOfItemsChange,
    isEditOutputMaterialsMode,
    toggleEditMode,
  } = useOutputMaterialDataForProductionPage({
    mainValues: values,
    orderStepTrackerResponseItem,
  });

  const [isUnlocked, setIsUnlocked] = useState<Record<number, boolean>>({});
  const toggleUnlockField = useCallback((inputIndex: number) => {
    setIsUnlocked((prevState) => ({
      ...prevState,
      [inputIndex]: !prevState[inputIndex],
    }));
  }, []);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isOrderCardMachineSelected, setIsOrderCardMachineSelected] =
    useState(false);

  return (
    <div className="min-h-screen bg-gray-50 py-2 px-5 w-screen">
      <div className="flex flex-col gap-5 w-full mb-8 items-start justify-center">
        <OrderCard
          processStepId={processStepId}
          orderStepTrackerResponseItem={orderStepTrackerResponseItem}
          adaptedMachines={adaptedMachines}
          openProducedItemInOrderModal={openProducedItemInOrderModal}
          setIsOrderCardMachineSelected={setIsOrderCardMachineSelected}
          isOrderCardMachineSelected={isOrderCardMachineSelected}
          isSubmitted={isSubmitted}
        />
        {inputMaterials && (
          <InputMaterialsContainer
            isUnlocked={isUnlocked}
            toggleUnlockField={toggleUnlockField}
            orderStepTrackerResponseItem={orderStepTrackerResponseItem!}
            adaptedInitialInputMaterials={adaptedInitialInputMaterials}
          />
        )}

        <div className="flex justify-between w-full">
          {outputMaterials && (
            <div className="basis-[100%] p-2">
              <OutputMaterialsContainer
                setIsSubmitted={setIsSubmitted}
                isOrderCardMachineSelected={isOrderCardMachineSelected}
                processStepId={processStepId}
                orderProcessStepInfoBox={orderProcessStepInfoBox}
                orderStepTrackerResponseItem={orderStepTrackerResponseItem}
                handleCountOfItemsChange={handleCountOfItemsChange}
                isEditMode={isEditOutputMaterialsMode}
                toggleEditMode={toggleEditMode}
                setIsUnlocked={setIsUnlocked}
                outputMaterialsList={values?.output_materials}
                isValidProductionForm={isValid}
                values={values}
                setFieldValue={setFieldValue}
                adaptedInitialInputMaterials={adaptedInitialInputMaterials}
                adaptedInitialOutputMaterials={adaptedInitialOutputMaterials}
                refetchOrderProcessStepTracker={refetchOrderProcessStepTracker}
              />
            </div>
          )}
        </div>
      </div>

      <TrackingList
        adaptedInitialInputMaterials={adaptedInitialInputMaterials}
        inputMaterials={inputMaterials || []}
        orderStepTrackerResponseItem={orderStepTrackerResponseItem}
      />
    </div>
  );
};

export { ProdPageContent };
