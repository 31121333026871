import { DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { FC, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { Link } from "react-router-dom";
import { Routes } from "shared/routers";
import { SupplierCompanyItem } from "shared/api";
import { AddProductCardValues } from "../ProductCards";

interface CustomersTableProps {
  customers?: SupplierCompanyItem[];
}

const CustomersTable: FC<CustomersTableProps> = ({ customers = [] }) => {
  const { t } = useTranslation();
  const { setFieldValue, values } = useFormikContext<AddProductCardValues>();

  const selectedCustomers = customers.filter((customer) =>
    values.company?.includes(customer.id)
  );

  const [dataSource, setDataSource] = useState(selectedCustomers);

  useEffect(() => {
    setDataSource(selectedCustomers);
  }, [setDataSource]);

  const removeCustomer = (customerId: number) => {
    const updatedCustomers = dataSource.filter(
      (item) => item.id !== customerId
    );
    setDataSource(updatedCustomers);
    setFieldValue(
      "company",
      updatedCustomers.map((item) => item.id)
    );
  };

  const columns: ColumnsType<{
    id: number;
    name: string;
    address: string;
    information: string;
  }> = [
    {
      title: t("Name"),
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link
          to={Routes.companies.hyperlink.replace(
            ":companyID",
            String(record.id)
          )}
          className="text-blue-500"
        >
          {text}
        </Link>
      ),
    },
    {
      title: t("Address"),
      dataIndex: "address",
      key: "address",
    },
    {
      title: t("Information"),
      dataIndex: "information",
      key: "information",
    },
    {
      title: t("Actions"),
      key: "actions",
      width: "5%",
      render: (_, record) => (
        <DeleteOutlined
          className="btn-remove"
          onClick={() => removeCustomer(record.id)}
        />
      ),
    },
  ];

  return <Table rowKey="id" columns={columns} dataSource={dataSource} />;
};

export { CustomersTable };
