import React, { FC } from "react";
import { FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Page } from "shared/ui";
import { noop } from "shared/helpers";
import { useModal, usePagination, useTableSorting } from "shared/hooks";
import { userInitialValues } from "shared/constants";
import { AddOrUpdateUserSchema } from "shared/schemas";
import { useGetRolesForTableQuery, useGetUsersQuery } from "shared/api";
import { UpdateUserModal, AddUserModal, UsersTable } from "./ui";

interface UsersProps {}

const Users: FC<UsersProps> = () => {
  const { t } = useTranslation();

  const [isOpenEditUserModal, showEditUserModal, closeEditUserModal] =
    useModal();

  const [isOpenAddUserModal, showAddUserModal, closeAddUserModal] = useModal();

  const { ordering, changeSort } = useTableSorting();
  const pagination = usePagination();
  const { offset, limit, setCurrentPage, setPageSize, pageSize, currentPage } =
    pagination;

  const {
    data: allUsers,
    isFetching: isLoadingUsersFetching,
    isLoading: isLoadingAllUsers,
    refetch,
  } = useGetUsersQuery({
    ordering,
    offset,
    limit,
    expand: "roles",
  });

  const { data: roles } = useGetRolesForTableQuery();

  const userRolesList =
    roles?.map((role) => ({
      value: role.id as number,
      label: role.name,
    })) || [];

  const addOrUpdateUserFormik = useFormik({
    initialValues: {
      ...userInitialValues,
      roles: [] as { id: number; name: string }[],
    },
    validateOnMount: false,
    validateOnBlur: true,
    validationSchema: AddOrUpdateUserSchema,
    onSubmit: noop,
  });

  return (
    <FormikProvider value={addOrUpdateUserFormik}>
      <Page
        title={t("users-page.title")}
        buttonTitle={t("users-page.add-btn")!}
        onClick={showAddUserModal}
      >
        <UsersTable
          refetch={refetch}
          isLoadingUsersFetching={isLoadingUsersFetching}
          isLoadingAllUsers={isLoadingAllUsers}
          allUsers={allUsers}
          showEditUserModal={showEditUserModal}
          setCurrentPage={setCurrentPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          currentPage={currentPage}
          changeSort={changeSort}
        />

        <UpdateUserModal
          isOpenEditUserModal={isOpenEditUserModal}
          closeEditUserModal={closeEditUserModal}
          userRolesList={userRolesList}
        />

        <AddUserModal
          refetch={refetch}
          isOpenNewUserModal={isOpenAddUserModal}
          closeAddUserModal={closeAddUserModal}
          userRolesList={userRolesList}
        />
      </Page>
    </FormikProvider>
  );
};

export { Users };
