import React from "react";
import { useTranslation } from "react-i18next";
import { noop } from "shared/helpers";
import { useModal } from "shared/hooks";
import { Page } from "shared/ui";
import { FormikProvider, useFormik } from "formik";
import { userRolesValidationSchema } from "shared/schemas";
import {
  useGetPermissionBlocksQuery,
  useGetRolesForTableQuery,
} from "shared/api";
import { userRolesInitialValues } from "shared/constants";
import { AddRolesModal } from "./AddRolesModal";
import { PermissionsAndRolesTable } from "./PermissionsAndRolesTable";
import { UpdateRoleModal } from "./UpdateRoleModal";

const AddRoles = () => {
  const { t } = useTranslation("", { keyPrefix: "roles" });
  const { data: permissionBlocks } = useGetPermissionBlocksQuery();
  const { data: rolesList, refetch: refetchRoles } = useGetRolesForTableQuery();

  const permissionOptions = permissionBlocks
    ? permissionBlocks.map((block) => ({
        value: block.name,
      }))
    : [];

  const [isOpenAddRolesModal, showAddRolesModal, closeAddRolesModal] =
    useModal();

  const [isOpenEditRolesModal, showEditRolesModal, closeEditRolesModal] =
    useModal();

  const addOrUpdateUserFormik = useFormik({
    initialValues: userRolesInitialValues,
    validateOnMount: false,
    validateOnBlur: true,
    validationSchema: userRolesValidationSchema,
    onSubmit: noop,
  });

  return (
    <FormikProvider value={addOrUpdateUserFormik}>
      <Page
        title={t("title")}
        buttonTitle={t("add-roles-btn")!}
        onClick={showAddRolesModal}
      >
        <PermissionsAndRolesTable
          rolesList={rolesList}
          showEditRolesModal={showEditRolesModal}
        />

        <UpdateRoleModal
          permissionOptions={permissionOptions}
          isOpenEditRoleModal={isOpenEditRolesModal}
          closeEditRoleModal={closeEditRolesModal}
          refetchRoles={refetchRoles}
        />

        <AddRolesModal
          refetchRoles={refetchRoles}
          permissionOptions={permissionOptions}
          isOpenNewRoleModal={isOpenAddRolesModal}
          closeAddRoleModal={closeAddRolesModal}
        />
      </Page>
    </FormikProvider>
  );
};

export { AddRoles };
