import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Table, notification } from "antd";
import { useFormikContext } from "formik";
import {
  useConfirmDeleteModal,
  useGetProcessStepsTableColumns,
  usePagination,
  useProcessStepTableFilters,
  useTableSorting,
} from "shared/hooks";
import { handleRequestError } from "shared/helpers";
import {
  adaptProcessStepOutputMaterialToEdit,
  adaptProcessStepInputMaterialToEdit,
  adaptProcessTemplates,
} from "shared/adapters";
import {
  useDeleteProcessStepMutation,
  useGetAppProcessTemplatesWithNoParamsQuery,
  useGetProcessStepsQuery,
  ProcessStepItem,
} from "shared/api";
import { RenderClearFiltersButton } from "shared/ui";
import { AddProcessStepValues } from "shared/types";
import { expandItemsString } from "shared/constants";

interface ProcessStepTableProps {
  showEditProcessStepModal: () => void;
  hasEditPermission?: boolean;
  hasDeletePermission?: boolean;
}

const ProcessStepTable: FC<ProcessStepTableProps> = ({
  showEditProcessStepModal,
  hasDeletePermission,
  hasEditPermission,
}) => {
  const { t } = useTranslation();

  const { setValues } = useFormikContext<AddProcessStepValues>();

  const pagination = usePagination();
  const { offset, limit, pageSize, currentPage, setCurrentPage, setPageSize } =
    pagination;

  const { ordering, changeSort } = useTableSorting();

  const {
    debouncedNameContains,
    handleNameContainsValue,
    nameContainsValue,
    clearNameContainsValue,
    processTemplateFilter,
    clearAllFilters,
    isVisibleClearFiltersButton,
    handleProcessTemplateFilter,
  } = useProcessStepTableFilters();

  const {
    data: processSteps,
    isLoading: isLoadingProcessSteps,
    isFetching: isFetchingProcessSteps,
  } = useGetProcessStepsQuery({
    offset,
    limit,
    expand: expandItemsString,
    ordering,
    name__icontains: debouncedNameContains,
    process_template: processTemplateFilter,
  });

  const { data: processTemplates } =
    useGetAppProcessTemplatesWithNoParamsQuery();
  const adaptedProcessTemplates = adaptProcessTemplates(processTemplates || []);

  const [deleteProcessStep] = useDeleteProcessStepMutation();
  const [api, contextHolder] = notification.useNotification();

  const deleteProcessStepHandler = async (record: ProcessStepItem) => {
    try {
      await deleteProcessStep(record).unwrap();
      api.success({
        message: t("process-step.success-title"),
        description: t("process-step.success-delete-msg"),
      });
    } catch (error) {
      const description = handleRequestError(error, t("process-step.error"));

      api.error({
        message: t("process-step.error"),
        description,
      });
    }
  };

  const showDeleteProcessStepModal = useConfirmDeleteModal(
    t("process-step.delete-alert-msg"),
    deleteProcessStepHandler
  );

  const editProcessStepHandler = (record: ProcessStepItem) => {
    showEditProcessStepModal();

    setValues({
      id: record?.id,
      name: record?.name,
      process_template: record.process_template.id,
      dynamic_process_step_fields: record.dynamic_process_step_fields,
      input_materials: adaptProcessStepInputMaterialToEdit(record),
      output_materials: adaptProcessStepOutputMaterialToEdit(record),
    });
  };

  const columns = useGetProcessStepsTableColumns({
    t,
    editProcessStepHandler,
    changeSort,
    processTemplateFilter,
    adaptedProcessTemplates,
    showDeleteProcessStepModal,
    nameContainsValue,
    clearNameContainsValue,
    handleNameContainsValue,
    handleProcessTemplateFilter,
    hasDeletePermission,
    hasEditPermission,
  });

  return (
    <>
      {contextHolder}

      {isVisibleClearFiltersButton && (
        <RenderClearFiltersButton onClick={clearAllFilters} />
      )}

      <Table
        loading={isLoadingProcessSteps || isFetchingProcessSteps}
        columns={columns}
        dataSource={processSteps?.results}
        pagination={{
          pageSize,
          showSizeChanger: true,
          current: currentPage,
          onShowSizeChange(page, pageSize) {
            setPageSize(pageSize);
            setCurrentPage(page);
          },
          onChange(page) {
            setCurrentPage(page);
          },
          total: processSteps?.count,
        }}
      />
    </>
  );
};

export { ProcessStepTable };
