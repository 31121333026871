import { FilterFilled } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { TFunction } from "i18next";
import { processStepColumnsData, sortDirections } from "shared/constants";
import { ProcessStepItem, ProcessTemplateItem } from "shared/api";
import { openHyperlinkInTab } from "shared/helpers";
import { Routes } from "shared/routers";
import {
  PopConfirmButton,
  TableButton,
  TableRadioGroup,
  TableSearchFilter,
} from "shared/ui";

interface UseGetProcessStepsTableColumnsProps {
  t: TFunction;
  editProcessStepHandler: (record: ProcessStepItem) => void;
  changeSort: (ascendOrdering: string, descendOrdering: string) => void;
  processTemplateFilter: string | number;
  adaptedProcessTemplates: {
    label: string;
    value: number;
  }[];
  showDeleteProcessStepModal: (record: ProcessStepItem) => void;
  nameContainsValue: string;
  clearNameContainsValue: () => void;
  handleNameContainsValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleProcessTemplateFilter: (value: number | string) => void;
  hasEditPermission?: boolean;
  hasDeletePermission?: boolean;
}

const { name, processTemplate, inputMaterials, outputMaterials, actions } =
  processStepColumnsData;

export const useGetProcessStepsTableColumns = ({
  t,
  editProcessStepHandler,
  changeSort,
  processTemplateFilter,
  adaptedProcessTemplates,
  showDeleteProcessStepModal,
  nameContainsValue,
  clearNameContainsValue,
  handleNameContainsValue,
  handleProcessTemplateFilter,
  hasDeletePermission,
  hasEditPermission,
}: UseGetProcessStepsTableColumnsProps): ColumnsType<ProcessStepItem> => {
  const columns: ColumnsType<ProcessStepItem> = [
    {
      title: t(name.title),
      render: (record: ProcessStepItem) => (
        <TableButton
          type="title"
          onClick={
            hasEditPermission ? () => editProcessStepHandler(record) : undefined
          }
          title={record.name}
        />
      ),
      onHeaderCell: () => ({
        onClick: () => {
          changeSort("name", "-name");
        },
      }),
      sorter: true,
      sortDirections,
      filterIcon: (
        <FilterFilled
          className={classNames("", {
            "!text-green-600  scale-125": nameContainsValue.length > 0,
          })}
        />
      ),
      filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
        <TableSearchFilter
          setSelectedKeys={setSelectedKeys}
          clearFilters={clearNameContainsValue}
          confirm={confirm}
          value={nameContainsValue}
          handleFilterChange={handleNameContainsValue}
          visible={visible}
          placeholder={t("process-step.enter-name")!}
          title={t("process-step.filter-by-name")!}
        />
      ),
    },
    {
      title: t(processTemplate.title),
      render: (record: ProcessStepItem) => {
        const processTemplate = record?.process_template as ProcessTemplateItem;

        const processTemplateLink =
          `${Routes.processTemplates.hyperlink}/${processTemplate?.id}`?.replace(
            ":id/",
            ""
          );

        return (
          <PopConfirmButton
            buttonContent={record.process_template.name}
            onClick={() => openHyperlinkInTab(processTemplateLink)}
          />
        );
      },
      filterIcon: (
        <FilterFilled
          className={classNames("", {
            "!text-blue-600  scale-125": !!processTemplateFilter,
          })}
        />
      ),
      filterDropdown: () => (
        <TableRadioGroup
          options={adaptedProcessTemplates}
          selectedValue={processTemplateFilter}
          onChange={handleProcessTemplateFilter}
        />
      ),
    },
    {
      title: t(inputMaterials.title),
      render: (record: ProcessStepItem) => (
        <div className="w-hull h-full flex flex-wrap gap-x-2">
          {record.input_materials.map((inputMaterial) => (
            <PopConfirmButton
              key={inputMaterial.id}
              buttonContent={inputMaterial?.material?.name}
              onClick={() => {
                openHyperlinkInTab(
                  `${Routes.materialManagement.hyperlink}/${inputMaterial?.material?.id}`.replace(
                    ":id/",
                    ""
                  )
                );
              }}
            />
          ))}
        </div>
      ),
    },
    {
      title: t(outputMaterials.title),
      render: (record: ProcessStepItem) => (
        <div className="w-hull h-full flex flex-wrap gap-x-2">
          {record.output_materials.map((outputMaterial) => (
            <PopConfirmButton
              key={outputMaterial.id}
              buttonContent={outputMaterial?.material?.name}
              onClick={() => {
                openHyperlinkInTab(
                  `${Routes.materialManagement.hyperlink}/${outputMaterial?.material?.id}`.replace(
                    ":id/",
                    ""
                  )
                );
              }}
            />
          ))}
        </div>
      ),
    },
  ];

  if (hasDeletePermission) {
    columns.push({
      title: t(actions.title),
      key: actions.key,

      render: (record: ProcessStepItem) => {
        return (
          <div className="w-full flex items-center justify-around">
            <TableButton
              type="delete"
              onClick={() => showDeleteProcessStepModal(record)}
            />
          </div>
        );
      },
    });
  }

  return columns;
};
