import React, { FC } from "react";
import { Modal, notification, Select } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import { FormInput } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import { UpdateUseritem, UserItem, useUpdateUserMutation } from "shared/api";
import { ModalSizeValue } from "shared/constants";

interface UpdateUserModalProps {
  isOpenEditUserModal: boolean;
  closeEditUserModal: () => void;
  userRolesList: {
    value: number | undefined;
    label: string;
  }[];
}

const UpdateUserModal: FC<UpdateUserModalProps> = ({
  isOpenEditUserModal,
  closeEditUserModal,
  userRolesList,
}) => {
  const { t } = useTranslation();
  const {
    values,
    resetForm,
    setFieldValue,
    isValid,
    handleChange,
    errors,
    handleBlur,
  } = useFormikContext<UserItem>();

  const [updateUser] = useUpdateUserMutation();

  const closeModal = () => {
    closeEditUserModal();
    resetForm();
  };

  const [api, contextHolder] = notification.useNotification();

  const onSubmitEditUser = async (params: UserItem) => {
    try {
      const formattedData: UpdateUseritem = {
        ...params,
        roles: params.roles.map((role) => Number(role.id)),
      };
      await updateUser(formattedData as UpdateUseritem).unwrap();

      api.success({
        message: t("users-page.success-title"),
        description: t("users-page.success-update-desc"),
      });
      closeModal();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("users-page.error-title")
      );

      api.error({
        message: t("users-page.error-title"),
        description,
      });
    }
  };

  return (
    <>
      {contextHolder}

      <Modal
        title={t("add-user-modal.edit-user")}
        width={ModalSizeValue.PRIMARY}
        open={isOpenEditUserModal}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onCancel={closeModal}
        onOk={() => {
          onSubmitEditUser(values);
        }}
      >
        <Title level={5} className="text-[14px]">
          {t("add-user-modal.first-name")}
        </Title>

        <FormInput
          value={values.first_name}
          fieldName="first_name"
          placeholder="modal-placeholders.firstname-placeholder"
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.first_name}
          isError={!!errors.first_name}
        />

        <Title level={5} className="text-[14px]">
          {t("add-user-modal.last-name")}
        </Title>

        <FormInput
          value={values.last_name}
          fieldName="last_name"
          placeholder="modal-placeholders.lastname-placeholder"
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.last_name}
          isError={!!errors.last_name}
        />

        <Title level={5} className="text-[14px]">
          {t("add-user-modal.username")}
        </Title>

        <FormInput
          value={values.username}
          fieldName="username"
          placeholder="modal-placeholders.username-placeholder"
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.username}
          isError={!!errors.username}
        />

        <Title level={5} className="text-[14px]">
          {t("add-user-modal.email")}
        </Title>

        <FormInput
          value={values.email}
          fieldName="email"
          placeholder="modal-placeholders.email-placeholder"
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.email}
          isError={!!errors.email}
        />

        <Title level={5} className="text-[14px]">
          {t("Roles")}
        </Title>

        <Select
          mode="multiple"
          value={values.roles.map((role) => role.id)}
          onChange={(selectedIds) => {
            const selectedRoles = userRolesList
              .filter((role) => selectedIds.includes(role.value))
              .map((role) => ({
                id: Number(role.value) || 0,
                name: role.label,
              }));

            setFieldValue("roles", selectedRoles);
          }}
          options={userRolesList}
          placeholder="Select roles"
          style={{ width: "100%" }}
        />

        <Title level={5} className="text-[14px]">
          {t("Are you superuser?")}
        </Title>

        <Select
          value={values.is_superuser ? "Yes" : "No"}
          onChange={(value) => setFieldValue("is_superuser", value === "Yes")}
          options={[
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
          ]}
        />
      </Modal>
    </>
  );
};

export { UpdateUserModal };
