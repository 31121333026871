import React from "react";
import { ProtectedAccessRoute } from "pages/ProtectedRoutePage";
import { BrowserRouter, Routes as RoutesDom, Route } from "react-router-dom";
import { pagesConfig } from "pages";
import { ProcessStepsHyperlink } from "pages/ProductCards/ProcessSteps";
import { ProtectedRoute } from "./ProtectedRoute";
import { Routes } from "./Routes";
import { RouteItem } from "./models";

export enum AccessPoints {
  COMPANIES = "companies",
  COMPANIES_ADD = "companies.add_company",
  COMPANIES_EDIT = "companies.edit_company",
  COMPANIES_DELETE = "companies.delete_company",

  MATERIALS = "materials",
  MATERIALS_ADD = "materials.add_material",
  MATERIALS_EDIT = "materials.edit_material",
  MATERIALS_DELETE = "materials.delete_material",

  CATEGORIES = "categories",
  CATEGORIES_ADD = "categories.add_category",
  CATEGORIES_EDIT = "categories.edit_category",
  CATEGORIES_DELETE = "categories.delete_category",

  ITEMS = "items",
  ITEMS_ADD = "items.add_item",
  ITEMS_EDIT = "items.edit_item",
  ITEMS_DELETE = "items.delete_item",

  ORDERS = "orders",
  ORDERS_ADD = "orders.add_order",
  ORDERS_EDIT = "orders.edit_order",
  ORDERS_DELETE = "orders.delete_order",
  ORDERS_PRODUCTION = "orders.production",

  PROCESS_TEMPLATES = "process_templates",
  PROCESS_TEMPLATES_ADD = "process_templates.add_process_template",
  PROCESS_TEMPLATES_EDIT = "process_templates.edit_process_template",
  PROCESS_TEMPLATES_DELETE = "process_templates.delete_process_template",

  MACHINES = "machines",
  MACHINES_ADD = "machines.add_machine",
  MACHINES_EDIT = "machines.edit_machine",
  MACHINES_DELETE = "machines.delete_machine",

  PROCESS_STEPS = "process_steps",
  PROCESS_STEPS_ADD = "process_steps.add_process_step",
  PROCESS_STEPS_EDIT = "process_steps.edit_process_step",
  PROCESS_STEPS_DELETE = "process_steps.delete_process_step",

  PRODUCT_CARDS = "product_cards",
  PRODUCT_CARDS_ADD = "product_cards.add_product_card",
  PRODUCT_CARDS_EDIT = "product_cards.edit_product_card",
  PRODUCT_CARDS_DELETE = "product_cards.delete_product_card",

  REPORTS = "reports",

  QUALITY = "quality",
  QUALITY_ADD = "quality.add_quality",
  QUALITY_EDIT = "quality.edit_quality",
  QUALITY_DELETE = "quality.delete_quality",

  USERS = "users",
  TEMPLATE_CONFIG = "template_config",
  STANDALONE = "standalone",

  DASHBOARDS = "dashboards",
  DASHBOARDS_ADD = "dashboards.add_dashboard",

  ECIPIENT_LIST = "recipient-list",
}

export const Router: React.FC = () => {
  const {
    Quality,
    TemplatesConfig,
    Dashboards,
    ArticleHyperlink,
    MachinesHyperlink,
    OrderHyperlink,
    ProcessTemplateHyperlink,
    ProductCardHyperlink,
    MaterialHyperlink,
    MaterialCategoryHyperlink,
    SignIn,
    MaterialCategoryManagement,
    Users,
    ProcessTemplates,
    Machines,
    ProductCards,
    ProcessSteps,
    Companies,
    Orders,
    ProcessStepProduction,
    DynamicProcessStepsForTheOrderPage,
    OrderPlans,
    TimelinePage,
    OrderReports,
    ExecuteReport,
    CompaniesHyperlink,
    UpdatedMaterialManagement,
    ItemView,
    StandAlone,
    AddRoles,
    AccessDenied,
    OeeView,
    RecipientList,
  } = pagesConfig;

  const routesConfig: RouteItem[] = [
    { path: Routes.signIn.url, component: SignIn, isProtected: false },

    {
      path: Routes.recipientList.url,
      component: RecipientList,
      isProtected: true,
    },

    {
      path: Routes.oeeView.url,
      component: OeeView,
      isProtected: true,
    },

    {
      path: Routes.protectedAccessKeys.url,
      component: AccessDenied,
      isProtected: true,
    },

    {
      path: Routes.users.url,
      component: Users,
      isProtected: true,
      props: { isOpenSidebar: true },
      requiredAccess: AccessPoints.USERS,
    },

    {
      path: Routes.companies.url,
      component: Companies,
      isProtected: true,
      requiredAccess: AccessPoints.COMPANIES,
    },
    {
      path: Routes.companies.hyperlink,
      component: CompaniesHyperlink,
      isProtected: true,
    },

    {
      path: Routes.materialManagement.url,
      component: UpdatedMaterialManagement,
      isProtected: true,
      requiredAccess: AccessPoints.MATERIALS,
    },

    {
      path: Routes.materialManagement.hyperlink,
      component: MaterialHyperlink,
      isProtected: true,
    },

    {
      path: Routes.materialManagement.articleUrl,
      component: ArticleHyperlink,
      isProtected: true,
    },

    {
      path: Routes.materialCategoryManagement.url,
      component: MaterialCategoryManagement,
      isProtected: true,
      requiredAccess: AccessPoints.CATEGORIES,
    },
    {
      path: Routes.processTemplates.url,
      component: ProcessTemplates,
      isProtected: true,
      requiredAccess: AccessPoints.PROCESS_TEMPLATES,
    },

    {
      path: Routes.processTemplates.hyperlink,
      component: ProcessTemplateHyperlink,
      isProtected: true,
    },

    {
      path: Routes.machines.url,
      component: Machines,
      isProtected: true,
      requiredAccess: AccessPoints.MACHINES,
    },

    {
      path: Routes.machines.hyperlinkUrl,
      component: MachinesHyperlink,
      isProtected: true,
    },

    {
      path: Routes.productCards.url,
      component: ProductCards,
      isProtected: true,
      requiredAccess: AccessPoints.PRODUCT_CARDS,
    },

    {
      path: Routes.productCards.hyperlink,
      component: ProductCardHyperlink,
      isProtected: true,
    },

    {
      path: Routes.processSteps.url,
      component: ProcessSteps,
      isProtected: true,
      requiredAccess: AccessPoints.PROCESS_STEPS,
    },

    {
      path: Routes.orders.url,
      component: Orders,
      isProtected: true,
      requiredAccess: AccessPoints.ORDERS,
    },

    {
      path: Routes.orders.hyperlinkUrl,
      component: OrderHyperlink,
      isProtected: true,
    },

    {
      path: Routes.processStepProduction.url,
      component: ProcessStepProduction,
      isProtected: true,
    },
    {
      path: Routes.processStepProduction.nestedUrl,
      component: DynamicProcessStepsForTheOrderPage,
      isProtected: true,
    },

    {
      path: Routes.orderPlans.url,
      component: OrderPlans,
      isProtected: true,
    },
    {
      path: Routes.orderPlans.nestedUrl,
      component: TimelinePage,
      isProtected: true,
    },
    {
      path: Routes.quality.url,
      component: Quality,
      isProtected: true,
      requiredAccess: AccessPoints.QUALITY,
    },
    {
      path: Routes.templateConfig.url,
      component: TemplatesConfig,
      isProtected: true,
      requiredAccess: AccessPoints.TEMPLATE_CONFIG,
    },
    {
      path: Routes.dashboards.url,
      component: Dashboards,
      isProtected: true,
    },
    {
      path: Routes.materialCategoryManagement.hyperlink,
      component: MaterialCategoryHyperlink,
      isProtected: true,
    },
    {
      path: Routes.orders.reportsUrl,
      component: OrderReports,
      isProtected: true,
    },
    {
      path: Routes.executeReport.url,
      component: ExecuteReport,
      isProtected: true,
      requiredAccess: AccessPoints.REPORTS,
    },
    {
      path: Routes.processSteps.hyperlink,
      component: ProcessStepsHyperlink,
      isProtected: true,
    },
    {
      path: Routes.itemView.url,
      component: ItemView,
      isProtected: true,
      requiredAccess: AccessPoints.ITEMS,
    },
    {
      path: Routes.standAlone.url,
      component: StandAlone,
      isProtected: true,
      requiredAccess: AccessPoints.STANDALONE,
    },
    {
      path: Routes.roles.url,
      component: AddRoles,
      isProtected: true,
    },
  ];

  const renderRoute = (route: RouteItem) => {
    const Component = route.component;
    const routeProps = route.props || {};

    return route.isProtected ? (
      <ProtectedRoute>
        {route.requiredAccess ? (
          <ProtectedAccessRoute requiredAccess={route.requiredAccess}>
            {(user) => <Component {...routeProps} user={user} />}
          </ProtectedAccessRoute>
        ) : (
          <Component {...routeProps} />
        )}
      </ProtectedRoute>
    ) : (
      <Component {...routeProps} />
    );
  };

  return (
    <BrowserRouter>
      <RoutesDom>
        {routesConfig.map((route, index) => (
          <Route key={index} path={route.path} element={renderRoute(route)} />
        ))}
      </RoutesDom>
    </BrowserRouter>
  );
};
