import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  AddNewRole,
  AllUsersParams,
  OrderedUsers,
  PermissionBlock,
  SetNewPasswordRequest,
  SetNewPasswordResponse,
  UpdateRecipient,
  UpdateUseritem,
  User,
  UserItem,
  UsersResponse,
} from "./models";

export const usersApi = createApi({
  baseQuery,
  reducerPath: "usersApi",
  tagTypes: ["Users", "Roles"],
  endpoints: (builder) => ({
    getUsers: builder.query<UsersResponse, AllUsersParams>({
      query: ({ ordering, limit, offset, expand }) => ({
        url: "/api/v1/users/",
        method: "GET",
        params: { ordering, limit, offset, expand },
      }),
      providesTags: ["Users"],
    }),
    getFilteredUsers: builder.query<UserItem[], OrderedUsers>({
      query: ({ ordering }) => ({
        url: `/api/v1/users/`,
        method: "GET",
        params: {
          ordering,
        },
      }),
      providesTags: ["Users"],
    }),
    getIndividualUser: builder.query<UserItem, number>({
      query: (id) => ({
        url: `/api/v1/users/${id}`,
        method: "GET",
      }),
    }),
    getLoggedUser: builder.query<UserItem, void>({
      query: () => ({
        url: "/api/v1/users/me/",
        method: "GET",
      }),
    }),
    createNewUser: builder.mutation<UserItem, UserItem>({
      query: ({ first_name, last_name, username, email, roles }) => ({
        url: `/api/v1/users/`,
        method: "POST",
        body: {
          first_name,
          last_name,
          username,
          email,
          roles,
        },
      }),
      invalidatesTags: ["Users"],
    }),
    updateUser: builder.mutation<UpdateUseritem, UpdateUseritem>({
      query: (body) => ({
        url: `/api/v1/users/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation<void, UserItem>({
      query: (body) => ({
        url: `/api/v1/users/${body.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Users"],
    }),
    setNewPassword: builder.mutation<
      SetNewPasswordResponse,
      SetNewPasswordRequest
    >({
      query: ({ userId, newPassword }) => ({
        url: `/api/v1/users/${userId}/set_new_password/`,
        method: "POST",
        body: { new_password: newPassword },
      }),
    }),
    updateRoleById: builder.mutation<AddNewRole, AddNewRole>({
      query: (body) => ({
        url: `/api/v1/roles/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Roles"],
    }),
    getPermissionBlocks: builder.query<PermissionBlock[], void>({
      query: () => "/api/v1/roles/all_permission_blocks/",
    }),
    getRolesForTable: builder.query<AddNewRole[], void>({
      query: () => "/api/v1/roles/",
    }),
    getUserPersonalInfo: builder.query<User, void>({
      query: () => "/api/v1/users/me",
    }),
    addNewRole: builder.mutation<AddNewRole, AddNewRole>({
      query: ({ name, permission_blocks }) => ({
        url: `/api/v1/roles/`,
        method: "POST",
        body: {
          name,
          permission_blocks,
        },
      }),
      invalidatesTags: ["Roles"],
    }),
    getRecipientsList: builder.query<UpdateRecipient, void>({
      query: () => ({
        url: "/api/v1/configurations/t1_recipient_list/",
        method: "GET",
      }),
    }),

    updateRecipientsList: builder.mutation<any, UpdateRecipient>({
      query: (body) => ({
        url: "/api/v1/configurations/t1_recipient_list/",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useUpdateRoleByIdMutation,
  useGetUserPersonalInfoQuery,
  useGetUsersQuery,
  useGetIndividualUserQuery,
  useGetLoggedUserQuery,
  useCreateNewUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetFilteredUsersQuery,
  useSetNewPasswordMutation,
  useGetPermissionBlocksQuery,
  useGetRolesForTableQuery,
  useAddNewRoleMutation,
  useGetRecipientsListQuery,
  useUpdateRecipientsListMutation,
} = usersApi;
