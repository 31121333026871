import { Button, Modal, notification } from "antd";
import React, { FC, useState } from "react";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { FormInput, Loader } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import { useModal } from "shared/hooks";
import { AdaptedValues } from "shared/types";
import { ModalSizeValue } from "shared/constants";
import { SupplierCompanyItem } from "shared/api";
import { AddProductCardValues } from "../ProductCards";
import { useCreateProductCardMutation } from "../../../../shared/api/productCard/productCardApi";
import { ProcessStepsTable } from "./ProcessStepsTable";
import { ProcessStepItem } from "../../../../shared/api/productCard/models";
import { ProcessStepToTheCardActionsModal } from "./AddProcessStepToTheCardModal";
import { AddCustomerToTheCardModal } from "./AddCustomerToTheCardModal";
import { CustomersTable } from "./CustomersTable";

interface AddProductCardModalProps {
  isOpenModal: boolean;
  hideAddProductCardModal: () => void;
  adaptedSteps: AdaptedValues[];
  processSteps?: ProcessStepItem[];
  isLoadingProcessSteps: boolean;
  adaptedCustomers: AdaptedValues[];
  isLoadingCustomers: boolean;
  customers?: SupplierCompanyItem[];
}

const AddProductCardModal: FC<AddProductCardModalProps> = ({
  isOpenModal,
  hideAddProductCardModal,
  adaptedSteps,
  processSteps,
  isLoadingProcessSteps,
  adaptedCustomers,
  isLoadingCustomers,
  customers,
}) => {
  const { values, resetForm, handleBlur, handleChange, isValid, errors } =
    useFormikContext<AddProductCardValues>();

  const closeModal = () => {
    hideAddProductCardModal();
    resetForm();
  };

  const { t } = useTranslation();

  const [api, contextHolder] = notification.useNotification();

  const [createProductCard] = useCreateProductCardMutation();

  const [
    isOpenAddProcessStepModal,
    showProcessStepsModal,
    hideProcessStepsModal,
  ] = useModal();

  const [isOpenAddCompanyModal, showCompanyModal, hideCompanyModal] =
    useModal();

  const [reorderingIds, setReorderingIds] = useState<number[]>([]);

  const createProductCardRequest = async (ids: number[]) => {
    try {
      await createProductCard({
        name: values.name!,
        process_steps: ids,
        companytoproductcards:
          values.company && Array.isArray(values.company) ? values.company : [],
      }).unwrap();
      api.success({
        message: t("product-card.success-title"),
        description: t("product-card.success-add-msg"),
      });
      closeModal();
    } catch (error) {
      const description = handleRequestError(error, t("product-card.error"));

      api.error({
        message: t("product-card.error"),
        description,
      });
    }
  };

  return (
    <>
      {contextHolder}

      <Modal
        title={t("product-card.add-card-title")}
        open={isOpenModal}
        width={ModalSizeValue.XL_MEDIUM}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onCancel={closeModal}
        onOk={() => {
          createProductCardRequest(reorderingIds);
        }}
      >
        {isLoadingProcessSteps && isLoadingCustomers ? (
          <Loader />
        ) : (
          <>
            <Title level={5} className="mb-1.25">
              {t("product-card.card-name")}
            </Title>

            <FormInput
              value={values.name}
              fieldName="name"
              placeholder={t("product-card.name-placeholder")}
              handleChange={handleChange}
              handleBlur={handleBlur}
              error={errors.name}
              isError={!!errors.name}
            />

            <div className="flex gap-2">
              <Button
                type="primary"
                className="btn-primary--dark mt-2.5"
                onClick={showProcessStepsModal}
              >
                {t("product-card.add-step")}
              </Button>

              <Button
                type="primary"
                className="btn-primary--dark mt-2.5"
                onClick={showCompanyModal}
              >
                {t("Add Customer")}
              </Button>
            </div>

            {values.process_steps.length ? (
              <>
                <Title level={5}>{t("product-card.process-steps")}</Title>
                <ProcessStepsTable
                  processSteps={processSteps}
                  setReorderingIds={setReorderingIds}
                />
              </>
            ) : null}

            {values.company?.length ? (
              <>
                <Title level={5}>Connected customers</Title>
                <CustomersTable customers={customers} />
              </>
            ) : null}

            <ProcessStepToTheCardActionsModal
              isOpenModal={isOpenAddProcessStepModal}
              hideProcessStepsModal={hideProcessStepsModal}
              adaptedSteps={adaptedSteps}
            />

            <AddCustomerToTheCardModal
              isOpenModal={isOpenAddCompanyModal}
              hideCustomerModal={hideCompanyModal}
              adaptedCustomers={adaptedCustomers}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export { AddProductCardModal };
