import { notification } from "antd";
import dayjs from "dayjs";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  OrderProcessInfoBox,
  OrderStepTrackerResponseItem,
  useLazyGetOrderProcessStepInfoBoxPdfQuery,
} from "shared/api";

interface ProductionGuiNBCProps {
  orderProcessStepInfoBox?: OrderProcessInfoBox;
  orderStepTrackerResponseItem: OrderStepTrackerResponseItem | undefined;
  processStepId?: number;
}

const ProductionGuiNBC: FC<ProductionGuiNBCProps> = ({
  orderProcessStepInfoBox,
  processStepId,
  orderStepTrackerResponseItem,
}) => {
  const { t } = useTranslation();

  const descriptionFields = orderProcessStepInfoBox?.fields.map((field) => ({
    label: field.name,
    value:
      field.name.includes("date") && field.value
        ? dayjs(field.value).format("DD/MM/YYYY HH:mm")
        : field.value || "N/A",
  }));

  const [fetchPdf] = useLazyGetOrderProcessStepInfoBoxPdfQuery();

  const handleDownloadPdf = async () => {
    if (!processStepId) return;

    try {
      const pdfBlob = await fetchPdf({
        id: processStepId,
      }).unwrap();

      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");
    } catch (error) {
      notification.error({
        message: t("error"),
        description: t("Failed to download report."),
      });
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg border border-gray-200 p-4">
      <div className="text-2xl flex items-center gap-2 font-bold text-blue-800 mb-4">
        Order #{orderStepTrackerResponseItem?.order?.order_code} Information
        <button type="button" onClick={handleDownloadPdf}>
          <img width={35} height={35} src="/infoBox.png" alt="ingo-box" />
        </button>
      </div>
      <div className="w-full">
        <table className="table-fixed w-full border-collapse border border-gray-300">
          <tbody>
            {descriptionFields?.map((field, index) => (
              <tr key={index} className="border-b border-gray-300">
                <td className="font-bold bg-gray-100 p-2 text-gray-700 w-1/3">
                  {field.label}:
                </td>
                <td className="p-2 text-gray-900">
                  <p className="max-w-[500px]">{field.value}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { ProductionGuiNBC };
