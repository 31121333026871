import { Modal, Input, Form } from "antd";
import { Colors } from "shared/themes";

const useChangePasswordModal = (
  title: string,
  onConfirm: (password: string) => Promise<void>,
  isLoading?: boolean
) => {
  const [form] = Form.useForm();

  const showModal = () => {
    Modal.confirm({
      title,
      width: "450px",
      content: (
        <Form form={form} layout="vertical">
          <Form.Item
            label="New password"
            name="password"
            rules={[{ required: true, message: "Enter new password" }]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        try {
          await form.validateFields();
          const password = form.getFieldValue("password");
          await onConfirm(password);
          form.resetFields();
        } catch (error) {
          return Promise.reject();
        }
        return null;
      },
      okButtonProps: {
        loading: isLoading,
        style: { background: `${Colors.blue.primary}` },
      },
    });
  };

  return showModal;
};

export { useChangePasswordModal };
