import { FilterFilled } from "@ant-design/icons";
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { companiesColumnsData, sortDirections } from "shared/constants";
import { CompanyItem } from "shared/api";
import {
  MultipleOptionsTableFilter,
  TableButton,
  TableImage,
  TableRadioGroup,
  TableSearchFilter,
} from "shared/ui";
import { CategoriesSuppliedByCompany } from "pages/Companies/ui/CategoriesSuppliedByCompany";
import { ChildCompanies } from "pages/Companies";
import { CheckboxValueType } from "antd/es/checkbox/Group";

interface GetCompaniesColumnsProps {
  t: Function;
  changeSort: Function;
  editCompany: Function;
  renderCompanyRole: Function;
  showCompanyDeleteModal: Function;
  handleNameValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCompanyWithChildrenModal: (record: CompanyItem) => void;
  nameValue: string;
  clearNameValue: () => void;
  clearPaymentTermsValue: () => void;
  handlePaymentTermsValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  paymentTermsValue: string;
  evaluationAsSupplierGte: string;
  evaluationAsSupplierLte: string;
  handleEvaluationAsSupplierGte: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleEvaluationAsSupplierLte: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  clearEvaluationAsSupplierGte: () => void;
  clearEvaluationAsSupplierLte: () => void;
  handleIncoTermsValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  incoTermsValue: string;
  clearIncoTermsValue: () => void;
  handleCustomerSupplierChange: (selectedValues: CheckboxValueType[]) => void;
  selectedCustomerSupplier: string[];
  categoriesSuppliedData: { label: string; value: number }[];
  handleCategoriesSupplierChange: (value: string | number) => void;
  selectCategoriesSupplier: string | number;
  handleSearchOrdersClick: (companyId: number) => void;
  hasDeletePermission: boolean | undefined;
}

const {
  name,
  address,
  incoTerms,
  actions,
  customerOrSupplier,
  image,
  evaluationAsSupplier,
  paymentTerms,
  childCompanies,
  categoriesSupplied,
  contactName,
  information,
} = companiesColumnsData;

export const useGetCompaniesColumns = ({
  t,
  changeSort,
  editCompany,
  renderCompanyRole,
  showCompanyDeleteModal,
  handleCompanyWithChildrenModal,
  nameValue,
  handleNameValue,
  clearNameValue,
  clearPaymentTermsValue,
  handlePaymentTermsValue,
  paymentTermsValue,
  evaluationAsSupplierGte,
  evaluationAsSupplierLte,
  handleEvaluationAsSupplierGte,
  handleEvaluationAsSupplierLte,
  clearEvaluationAsSupplierGte,
  clearEvaluationAsSupplierLte,
  incoTermsValue,
  handleIncoTermsValue,
  clearIncoTermsValue,
  handleCustomerSupplierChange,
  selectedCustomerSupplier,
  categoriesSuppliedData,
  handleCategoriesSupplierChange,
  selectCategoriesSupplier,
  handleSearchOrdersClick,
  hasDeletePermission,
}: GetCompaniesColumnsProps): ColumnsType<CompanyItem> => [
  {
    title: t(image.title),
    render: (company: CompanyItem) => (
      <TableImage image={company?.image as string} />
    ),
  },
  {
    title: t(name.title),
    render: (company: CompanyItem) => (
      <Button
        className="border-none hover:border-none bg-transparent shadow-none"
        onClick={() => editCompany(company)}
      >
        <span className="underline">{company?.name}</span>
      </Button>
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort("name", "-name");
      },
    }),
    sorter: true,
    sortDirections,
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600  scale-125": nameValue.length,
        })}
      />
    ),
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearNameValue}
        confirm={confirm}
        value={nameValue!}
        handleFilterChange={handleNameValue!}
        visible={visible}
        placeholder={t("companies.filter-placeholder")!}
        title={t("companies.filter-title")}
      />
    ),
  },
  {
    title: t(address.title),
    dataIndex: address.dataIndex,
    key: address.key,
  },
  {
    title: t(evaluationAsSupplier.title),
    dataIndex: evaluationAsSupplier.dataIndex,
    key: evaluationAsSupplier.key,
    sorter: true,
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <div>
        <TableSearchFilter
          setSelectedKeys={(selectedKeys: React.Key[]) => {
            setSelectedKeys([selectedKeys[0], evaluationAsSupplierLte]);
          }}
          clearFilters={() => {
            clearEvaluationAsSupplierGte();
            clearEvaluationAsSupplierLte();
          }}
          confirm={confirm}
          value={evaluationAsSupplierGte}
          handleFilterChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleEvaluationAsSupplierGte(e);
          }}
          visible={visible}
          placeholder={t("Greater or Equal")}
          title={t("Filter by Evaluation")}
        />
        <TableSearchFilter
          setSelectedKeys={(selectedKeys: React.Key[]) => {
            setSelectedKeys([evaluationAsSupplierGte, selectedKeys[0]]);
          }}
          clearFilters={() => {
            clearEvaluationAsSupplierGte();
            clearEvaluationAsSupplierLte();
          }}
          confirm={confirm}
          value={evaluationAsSupplierLte}
          handleFilterChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleEvaluationAsSupplierLte(e);
          }}
          visible={visible}
          placeholder={t("Lesser or Equal")}
          title={t("Filter by Evaluation")}
        />
      </div>
    ),
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600 scale-125":
            evaluationAsSupplierGte.length || evaluationAsSupplierLte.length,
        })}
      />
    ),
  },
  {
    title: t(paymentTerms.title),
    dataIndex: paymentTerms.dataIndex,
    key: paymentTerms.key,
    sorter: true,
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600 scale-125": paymentTermsValue.length,
        })}
      />
    ),
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearPaymentTermsValue}
        confirm={confirm}
        value={paymentTermsValue}
        handleFilterChange={handlePaymentTermsValue}
        visible={visible}
        placeholder={t("Enter Payment Terms")}
        title={t("Filter by Payment Terms")}
      />
    ),
  },
  {
    title: t(incoTerms.title),
    dataIndex: incoTerms.dataIndex,
    key: incoTerms.key,
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearIncoTermsValue}
        confirm={confirm}
        value={incoTermsValue}
        handleFilterChange={handleIncoTermsValue}
        visible={visible}
        placeholder={t("Search INCO Terms")}
        title={t("Filter by INCO Terms")}
      />
    ),
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600 scale-125": paymentTermsValue.length,
        })}
      />
    ),
  },
  {
    title: t(customerOrSupplier.title),
    render: (item: CompanyItem) => renderCompanyRole(item),
    filterDropdown: ({ clearFilters }) => (
      <MultipleOptionsTableFilter
        options={[
          { label: t("companiesColumns.isSupplier"), value: "is_supplier" },
          { label: t("companiesColumns.isBuyer"), value: "is_buyer" },
        ]}
        selectedValues={selectedCustomerSupplier}
        handleChange={handleCustomerSupplierChange}
        clearFilters={clearFilters}
      />
    ),
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600 scale-125": selectedCustomerSupplier.length > 0,
        })}
      />
    ),
    filteredValue: selectedCustomerSupplier,
  },
  {
    title: t(categoriesSupplied.title),
    render: (item: CompanyItem) => <CategoriesSuppliedByCompany item={item} />,
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600 scale-125": !!selectCategoriesSupplier,
        })}
      />
    ),
    filterDropdown: () => (
      <TableRadioGroup
        options={categoriesSuppliedData}
        selectedValue={selectCategoriesSupplier}
        onChange={handleCategoriesSupplierChange}
      />
    ),
  },
  {
    title: t(childCompanies.title),
    render: (item: CompanyItem) => (
      <ChildCompanies
        company={item}
        handleCompanyWithChildrenModal={handleCompanyWithChildrenModal}
      />
    ),
  },
  {
    title: t(contactName.title),
    render: (item: CompanyItem) => item?.contact_name,
  },
  {
    title: t(information.title),
    render: (item: CompanyItem) => item?.information,
  },
  {
    title: t(actions.title),
    key: actions.key,

    render: (record: CompanyItem) => {
      return (
        <div className="w-full flex items-center justify-around">
          <TableButton
            type="search"
            onClick={() => handleSearchOrdersClick(record.id)}
          />
          {hasDeletePermission && (
            <TableButton
              type="delete"
              onClick={() => showCompanyDeleteModal(record)}
            />
          )}
        </div>
      );
    },
  },
];
