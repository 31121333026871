import { Alert, Input, Modal, Table } from "antd";
import React, { FC } from "react";
import Title from "antd/es/typography/Title";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { ModalSizeValue } from "shared/constants";
import { useDebouncedTableFilter } from "shared/hooks";
import { ColumnsType } from "antd/es/table";
import { FilterFilled } from "@ant-design/icons";
import { AdaptedValues } from "shared/types";
import { useCustomerKeys } from "shared/hooks/useCustomerKeys";
import { AddProductCardValues } from "../ProductCards";

interface AddCustomerToTheCardModalProps {
  isOpenModal: boolean;
  hideCustomerModal: () => void;
  adaptedCustomers: AdaptedValues[];
  setSelectedCompanyIds?: (ids: number[]) => void;
}

const AddCustomerToTheCardModal: FC<AddCustomerToTheCardModalProps> = ({
  isOpenModal,
  hideCustomerModal,
  adaptedCustomers,
  setSelectedCompanyIds,
}) => {
  const { values, setFieldValue } = useFormikContext<AddProductCardValues>();
  const { t } = useTranslation();

  const {
    handleFilterChange: handleNameValue,
    value: nameValue,
    debouncedValue: debouncedNameValue,
  } = useDebouncedTableFilter("", 400);

  const {
    rowSelection,
    filteredCompanies,
    addCompany,
    closeModal,
    selectedCompany,
  } = useCustomerKeys({
    selectedCompany: Array.isArray(values?.company) ? values?.company : [],
    adaptedCompanies: adaptedCustomers,
    setFieldValue,
    hideCustomerModal,
    setSelectedCompanyIds,
    nameValue: debouncedNameValue || "",
  });

  const columns: ColumnsType<AdaptedValues> = [
    {
      title: t("product-card.customer"),
      width: "100%",
      render: (record: AdaptedValues) => record?.label,
      filterIcon: (
        <FilterFilled
          className={nameValue.length > 0 ? "!text-green-100 scale-125" : ""}
        />
      ),
    },
  ];

  const selectedColumns: ColumnsType<AdaptedValues> = [
    {
      title: t("product-card.selected-customer"),
      width: "100%",
      render: (record: AdaptedValues) => record?.label,
    },
  ];

  return (
    <Modal
      width={ModalSizeValue.X_LARGE}
      open={isOpenModal}
      centered
      onCancel={closeModal}
      onOk={addCompany}
      okButtonProps={{
        style: {
          background: `${Colors.blue.primary}`,
          color: "white",
        },
      }}
    >
      <div className="w-full flex gap-x-4">
        <div className="w-1/2">
          <Title level={5}>{t("product-card.all-customers")}</Title>

          <Input.Search
            placeholder={t("companies.search-customer")!}
            value={nameValue}
            onChange={handleNameValue}
          />

          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredCompanies}
            pagination={false}
            className="max-h-[820px] overflow-auto mt-3"
          />
        </div>

        <div className="w-1/2">
          <Title level={5}>{t("product-card.selected-customers")}</Title>

          {selectedCompany !== null ? (
            <Table
              columns={selectedColumns}
              dataSource={adaptedCustomers.filter((c) =>
                selectedCompany.includes(c.value)
              )}
              pagination={false}
            />
          ) : (
            <Alert
              type="info"
              showIcon
              message={t("product-card.no-selected-customers")}
              className="w-full h-14"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export { AddCustomerToTheCardModal };
