import React from "react";
import { Avatar, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";

interface UserAvatarProps {
  first_name?: string;
  last_name?: string;
  handleUserSidebar: (value: boolean) => void;
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  first_name = "Urban",
  last_name = "Winberg",
  handleUserSidebar,
}) => {
  const hasName = first_name || last_name;

  const getInitials = () => {
    const first = first_name?.trim()[0] || "";
    const last = last_name?.trim()[0] || "";
    return (first + last).toUpperCase() || null;
  };

  const fullName = hasName ? `${first_name} ${last_name}`.trim() : "No Name";

  return (
    <Tooltip title={fullName}>
      <Avatar
        size={70}
        style={{
          backgroundColor: "#001F7F",
          color: "#fff",
          fontWeight: "bold",
          fontSize: "30px",
        }}
        onClick={() => handleUserSidebar(true)}
        icon={!hasName && <UserOutlined />}
        className="flex items-center justify-center hover:cursor-pointer"
      >
        {hasName ? getInitials() : null}
      </Avatar>
    </Tooltip>
  );
};

export default UserAvatar;
