import { FilterFilled } from "@ant-design/icons";
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { TFunction } from "i18next";
import {
  OrdersSorting,
  StatusDropdownValues,
  ordersColumnsData,
  sortDirections,
} from "shared/constants";
import { OrderProcessStepDetails } from "pages/Orders/Orders/UI/OrderProcessStepDetails";
import { AdaptedStatuses, AdaptedValues } from "shared/types";
import { OrderItem, ReportItem } from "shared/api";
import {
  addThousandsSeparator,
  dayjs,
  openHyperlinkInTab,
  statusColors,
} from "shared/helpers";
import { Routes } from "shared/routers";
import {
  ColumnName,
  DatePickerValue,
  DateRange,
  PopConfirmButton,
  RangeSlider,
  TableButton,
  TableOrdersDatepicker,
  TableModalWithSelect,
  TableSearchFilter,
  TableRadioGroup,
} from "shared/ui";
import ReportsDropdown from "pages/Orders/OrderPlans/UI/ReportsDropdown";
import { Link } from "react-router-dom";

const {
  orderCode,
  buyer,
  po_number,
  wantedDeliveryDatetime,
  productionDatetime,
  productCard,
  quantityExpected,
  status,
  actions,
  processSteps,
  customerOrderNumber,
} = ordersColumnsData;

interface UseGetOrderTableColumnsProps {
  t: TFunction;
  editOrderHandler: (record: OrderItem) => void;
  changeSort: (ascendOrdering: string, descendOrdering: string) => void;
  isProductionDateFilterActive: boolean;
  handleProductionDateGreater: (
    date: DatePickerValue,
    dateString: string
  ) => void;
  handleProductionDateLesser: (
    date: DatePickerValue,
    dateString: string
  ) => void;
  isWantedDateFilterActive: boolean;
  handleWantedDeliveryGreater: (
    date: DatePickerValue,
    dateString: string
  ) => void;
  handleWantedDeliverLesser: (
    date: DatePickerValue,
    dateString: string
  ) => void;
  isQuantityFilterActive: () => boolean;
  maxQuantityValue: number;
  onChangeQuantity: (value: [number, number]) => void;
  onAfterQuantityChange: (value: [number, number]) => void;
  selectOrderIdToUpdate: (orderId: number) => void;
  orderToChangeStatusId: number | null;
  clearOrderId: () => void;
  FilterStatusDropdownValues: AdaptedStatuses[];
  showDeleteOrderModal: (record: OrderItem) => void;
  updateOrderWantedDeliveryTimeRequest: (
    item: OrderItem,
    wantedDeliveryDatetime: string
  ) => Promise<void>;
  updateOrderWantedProductionTimeRequest: (
    item: OrderItem,
    productionTime: string
  ) => Promise<void>;
  updateOrderStatusRequest: (item: OrderItem, status: string) => Promise<void>;
  handleOrderCodeContains: (e: React.ChangeEvent<HTMLInputElement>) => void;
  orderCodeContainsValue: string;
  clearOrderCodeContainsValue: () => void;
  handlePoNumberContains: (e: React.ChangeEvent<HTMLInputElement>) => void;
  poNumberContainsValue: string;
  clearPoNumberContainsValue: () => void;
  showGenerateMultiplePackagingReportModalHandler: (orderId: number) => void;
  orderStatus: string | number;
  handleOrderStatus: (value: number | string) => void;
  timezone: string;
  handleProductCardContains: (e: React.ChangeEvent<HTMLInputElement>) => void;
  productCardContainsValue: string;
  clearProductCardContainsValue: () => void;
  selectedCustomer: number | string;
  handleCustomerChange: (value: string | number) => void;
  customers: { label: string; value: number }[];
  handleDownloadReport: (orderId: number) => void;
  reportsData: ReportItem[];
  hasEditPermission?: boolean;
  hasDeletePermission?: boolean;
  hasProductionPermission?: boolean;
}

export const useGetOrderTableColumns = ({
  t,
  editOrderHandler,
  changeSort,
  isProductionDateFilterActive,
  handleProductionDateGreater,
  handleProductionDateLesser,
  isWantedDateFilterActive,
  handleWantedDeliveryGreater,
  handleWantedDeliverLesser,
  isQuantityFilterActive,
  maxQuantityValue,
  onChangeQuantity,
  onAfterQuantityChange,
  FilterStatusDropdownValues,
  showDeleteOrderModal,
  updateOrderWantedDeliveryTimeRequest,
  updateOrderWantedProductionTimeRequest,
  updateOrderStatusRequest,
  handleOrderCodeContains,
  orderCodeContainsValue,
  clearOrderCodeContainsValue,
  handlePoNumberContains,
  poNumberContainsValue,
  clearPoNumberContainsValue,
  showGenerateMultiplePackagingReportModalHandler,
  orderStatus,
  handleOrderStatus,
  timezone,
  handleProductCardContains,
  productCardContainsValue,
  clearProductCardContainsValue,
  selectedCustomer,
  handleCustomerChange,
  customers,
  handleDownloadReport,
  reportsData,
  hasDeletePermission,
  hasEditPermission,
  hasProductionPermission,
}: UseGetOrderTableColumnsProps): ColumnsType<OrderItem> => [
  {
    title: () => (
      <ColumnName
        fullTitle={t(orderCode.title)}
        shortTitle={t(orderCode.title)}
      />
    ),
    render: (order: OrderItem) => {
      return (
        <Button
          className="border-none hover:border-none bg-transparent shadow-none !p-0"
          onClick={
            hasEditPermission ? () => editOrderHandler(order) : undefined
          }
        >
          <span className="underline !text-[12px]">{order.order_code}</span>
        </Button>
      );
    },
    sortDirections,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort("order_code", "-order_code");
      },
    }),
    sorter: true,
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600  scale-125": orderCodeContainsValue.length > 0,
        })}
      />
    ),
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearOrderCodeContainsValue}
        confirm={confirm}
        value={orderCodeContainsValue}
        handleFilterChange={handleOrderCodeContains}
        visible={visible}
        title={t("orders.order-code")!}
        placeholder={t("orders.order-code")!}
      />
    ),
  },
  {
    title: () => (
      <ColumnName fullTitle={t(buyer.title)} shortTitle={t(buyer.title)} />
    ),
    render: (text: string, item: OrderItem) => {
      const redirectLink =
        `${Routes.companies.hyperlink}/${item?.buyer.id}`?.replace(
          ":companyID/",
          ""
        );
      return (
        <Link to={redirectLink}>
          {item?.buyer?.name || t("orders.no-buyer")}
        </Link>
      );
    },
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600 scale-125": !!selectedCustomer,
        })}
      />
    ),
    filterDropdown: () => (
      <TableRadioGroup
        options={customers}
        selectedValue={selectedCustomer}
        onChange={handleCustomerChange}
      />
    ),
  },
  {
    title: () => (
      <ColumnName
        fullTitle={t(po_number.title)}
        shortTitle={t(po_number.title)}
      />
    ),
    render: (item: OrderItem) => (
      <span className="!text-[12px]">{item?.po_number}</span>
    ),
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-green-100 scale-125": poNumberContainsValue.length > 0,
        })}
      />
    ),
    key: po_number.key,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(OrdersSorting.PO_NUMBER_ASC, OrdersSorting.PO_NUMBER_DESC);
      },
    }),
    sorter: true,
    sortDirections,
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearPoNumberContainsValue}
        confirm={confirm}
        value={poNumberContainsValue}
        handleFilterChange={handlePoNumberContains}
        visible={visible}
        title={t("orders.find-by-po")!}
        placeholder={t("orders.po-placeholder")!}
      />
    ),
  },
  {
    title: () => (
      <ColumnName
        fullTitle={t(customerOrderNumber.title)}
        shortTitle={t(customerOrderNumber.title)}
      />
    ),
    render: (item: OrderItem) => (
      <span className="!text-[12px]">{item?.customer_order_number}</span>
    ),
    key: customerOrderNumber.key,
  },
  {
    title: () => (
      <ColumnName
        fullTitle={t(productionDatetime.title)}
        shortTitle={t(productionDatetime.title)}
      />
    ),
    key: productionDatetime.key,
    render: (item: OrderItem) => (
      <TableOrdersDatepicker
        modalTitle={t("orders.prodTineForOrder", {
          orderCode: item?.order_code,
        })}
        value={dayjs(item?.production_datetime)
          .tz(timezone)
          .format("YYYY-MM-DD HH:mm")}
        originalDate={item?.production_datetime}
        onOk={updateOrderWantedProductionTimeRequest}
        item={item}
      />
    ),

    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-purple-400 scale-125": isProductionDateFilterActive,
        })}
      />
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          OrdersSorting.PRODUCTION_DATE_ASC,
          OrdersSorting.PRODUCTION_DATE_DESC
        );
      },
    }),
    sorter: true,
    sortDirections,
    filterDropdown: () => {
      return (
        <DateRange
          title={t("orders.production-date-title")}
          leftPickerTitle={t("orders.production-date-lesser")}
          rightPickerTitle={t("orders.production-date-greater")}
          handlePickerChangeGreater={handleProductionDateGreater}
          handlePickerChangeLesser={handleProductionDateLesser}
        />
      );
    },
  },
  {
    title: () => (
      <ColumnName
        fullTitle={t(wantedDeliveryDatetime.title)}
        shortTitle={t(wantedDeliveryDatetime.title)}
      />
    ),
    render: (item: OrderItem) => (
      <TableOrdersDatepicker
        modalTitle={t("orders.wantedTineForOrder", {
          orderCode: item?.order_code,
        })}
        value={dayjs(item?.wanted_delivery_datetime)
          .tz(timezone)
          .format("YYYY-MM-DD HH:mm")}
        originalDate={item?.wanted_delivery_datetime}
        onOk={updateOrderWantedDeliveryTimeRequest}
        item={item}
      />
    ),

    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-red-100 scale-125": isWantedDateFilterActive,
        })}
      />
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          OrdersSorting.WANTED_DELIVERY_DATE_ASC,
          OrdersSorting.WANTED_DELIVERY_DATE_DESC
        );
      },
    }),
    sorter: true,
    sortDirections,
    filterDropdown: () => {
      return (
        <DateRange
          title={t("orders.wanted-date-title")}
          leftPickerTitle={t("orders.wanted-date-lesser")}
          rightPickerTitle={t("orders.wanted-date-greater")}
          handlePickerChangeGreater={handleWantedDeliveryGreater}
          handlePickerChangeLesser={handleWantedDeliverLesser}
        />
      );
    },
  },
  {
    title: () => (
      <ColumnName
        fullTitle={t(productCard.title)}
        shortTitle={t(productCard.title)}
      />
    ),
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-green-200  scale-125": productCardContainsValue.length > 0,
        })}
      />
    ),
    filterDropdown: ({ setSelectedKeys, confirm, visible }) => (
      <TableSearchFilter
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearProductCardContainsValue}
        confirm={confirm}
        value={productCardContainsValue}
        handleFilterChange={handleProductCardContains}
        visible={visible}
        title={t("orders.product-card")!}
        placeholder={t("orders.product-card")!}
      />
    ),
    render: (item: OrderItem) => {
      const redirectLink =
        `${Routes.productCards.hyperlink}/${item?.product_card?.id}`?.replace(
          ":id/",
          ""
        );

      return (
        <PopConfirmButton
          buttonContent={item?.product_card?.name}
          onClick={() => openHyperlinkInTab(redirectLink)}
        />
      );
    },
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          OrdersSorting.PRODUCT_CARD_ASC,
          OrdersSorting.PRODUCT_CARD_DESC
        );
      },
    }),
    sorter: true,
    sortDirections,
  },
  {
    title: () =>
      hasProductionPermission ? (
        <ColumnName
          fullTitle={t(processSteps.title)}
          shortTitle={t(processSteps.title)}
        />
      ) : null,
    render: (order: OrderItem) =>
      hasProductionPermission ? (
        <OrderProcessStepDetails order={order} />
      ) : null,
  },
  {
    title: () => (
      <ColumnName
        fullTitle={t(quantityExpected.title)}
        shortTitle={t(quantityExpected.title)}
      />
    ),
    render: (item: OrderItem) => (
      <span className="!text-[12px] !text-center">
        {addThousandsSeparator(item.quantity_expected)}
      </span>
    ),
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-yellow-700 scale-125": isQuantityFilterActive,
        })}
      />
    ),
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          OrdersSorting.QUANTITY_EXPECTED_ASC,
          OrdersSorting.QUANTITY_EXPECTED_DESC
        );
      },
    }),
    sorter: true,
    sortDirections,
    filterDropdown: () => {
      return (
        <RangeSlider
          maxValue={maxQuantityValue}
          onChange={onChangeQuantity}
          onAfterChange={onAfterQuantityChange}
          title={t("orders.quantity-range")}
          rangeItemName={t("orders.quantity")}
        />
      );
    },
  },
  {
    title: () => (
      <ColumnName fullTitle={t(status.title)} shortTitle={t(status.title)} />
    ),
    render: (item: OrderItem) => {
      return (
        <TableModalWithSelect
          modalTitle={t("orders.statusForOrder", {
            orderCode: item?.order_code,
          })}
          title={t("orders.selectStatus")}
          onOk={updateOrderStatusRequest}
          content={item?.status}
          options={StatusDropdownValues as unknown as AdaptedValues[]}
          item={item}
          statusColors={statusColors}
        />
      );
    },
    filterIcon: (
      <FilterFilled
        className={classNames("", {
          "!text-blue-600  scale-125": orderStatus,
        })}
      />
    ),
    filterDropdown: () => (
      <TableRadioGroup
        options={FilterStatusDropdownValues}
        selectedValue={orderStatus}
        onChange={handleOrderStatus}
      />
    ),
  },
  {
    title: () => (
      <ColumnName fullTitle={t(actions.title)} shortTitle={t(actions.title)} />
    ),
    key: actions.key,
    width: 130,
    render: (record: OrderItem) => {
      return (
        <div className="w-full flex items-center justify-around">
          <TableButton
            type="rIcon"
            onClick={() =>
              showGenerateMultiplePackagingReportModalHandler(record?.id)
            }
            popoverString={t("orders.generateMultiplePackagingReport")!}
          />
          {hasDeletePermission && (
            <TableButton
              type="delete"
              onClick={() => showDeleteOrderModal(record)}
            />
          )}

          <ReportsDropdown
            reports={reportsData}
            onDownload={(reportId) => handleDownloadReport(reportId)}
            noReportsMessage={t("orders.ordersReportsMessage")!}
            popoverString={t("orders.ordersReports")!}
          />
        </div>
      );
    },
  },
];
