import { UploadFile } from "antd";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccessPoints } from "shared/routers";
import { Page } from "shared/ui";
import { useModal } from "shared/hooks";
import { noop } from "shared/helpers";
import { FormikProvider, useFormik } from "formik";
import {
  MaterialStockBatchItemResponse,
  MaterialStockItem,
  useGetAllMaterialsNoPaginationQuery,
  useGetAllOrdersWithNoParamsQuery,
  UserItem,
} from "shared/api";
import { adaptMaterials, adaptOrders } from "shared/adapters";
import { initialUploadDocumentValues } from "shared/constants";
import { AddOrUpdateQualityResult } from "shared/schemas";
import {
  QualityResultsTable,
  UpdateQualityResultModal,
  UploadDocumentModal,
} from "./ui";

interface QualityProps {
  user?: UserItem;
}

const Quality: FC<QualityProps> = ({ user }) => {
  const { t } = useTranslation();

  const hasAddPermission = user?.access_points?.includes(
    AccessPoints.QUALITY_ADD
  );

  const hasEditPermission = user?.access_points?.includes(
    AccessPoints.QUALITY_EDIT
  );

  const hasDeletePermission = user?.access_points?.includes(
    AccessPoints.QUALITY_DELETE
  );

  const { data: materials, isLoading: isLoadingMaterials } =
    useGetAllMaterialsNoPaginationQuery();
  const { data: orders, isLoading: isLoadingOrders } =
    useGetAllOrdersWithNoParamsQuery({});

  const [isOpenUploadDocument, showUploadDocument, closeUploadDocument] =
    useModal();

  const [isOpenUpdateResult, showUpdateResult, closeUpdateResult] = useModal();

  const uploadDocumentFormik = useFormik({
    initialValues: initialUploadDocumentValues,
    validationSchema: AddOrUpdateQualityResult,
    onSubmit: noop,
  });

  const adaptedMaterials = useMemo(() => {
    return adaptMaterials(materials ?? []);
  }, [materials]);

  const adaptedOrders = useMemo(() => {
    return adaptOrders(orders ?? []);
  }, [orders]);

  const [documentList, setDocumentList] = useState<UploadFile<unknown>[]>([]);
  const handleDocumentList = (files: UploadFile[]) => setDocumentList(files);

  const [document, setDocument] = useState<File | null>(null);
  const handleDocument = (file: File | null) => setDocument(file);

  const [selectedStockBatches, setSelectedStockBatches] = useState<
    MaterialStockBatchItemResponse[]
  >([]);

  const handleStockBatchesRows = (
    stockBatches: MaterialStockBatchItemResponse[]
  ) => setSelectedStockBatches(stockBatches);

  const [selectedMaterialItems, setSelectedMaterialItems] = useState<
    MaterialStockItem[]
  >([]);

  const handleMaterialItemsRows = (materialItems: MaterialStockItem[]) =>
    setSelectedMaterialItems(materialItems);

  return (
    <FormikProvider value={uploadDocumentFormik}>
      <Page
        title={t("quality.title")}
        buttonTitle={hasAddPermission ? t("quality.upload-file")! : undefined}
        onClick={hasAddPermission ? showUploadDocument : undefined}
      >
        <QualityResultsTable
          hasEditPermission={hasEditPermission}
          hasDeletePermission={hasDeletePermission}
          showUpdateResult={showUpdateResult}
          handleStockBatchesRows={handleStockBatchesRows}
          handleMaterialItemsRows={handleMaterialItemsRows}
        />

        <UploadDocumentModal
          isOpenModal={isOpenUploadDocument}
          onClose={closeUploadDocument}
          adaptedMaterials={adaptedMaterials}
          document={document}
          handleDocument={handleDocument}
          selectedStockBatches={selectedStockBatches}
          handleStockBatchesRows={handleStockBatchesRows}
          selectedMaterialItems={selectedMaterialItems}
          handleMaterialItemsRows={handleMaterialItemsRows}
          handleDocumentList={handleDocumentList}
          documentList={documentList}
          adaptedOrders={adaptedOrders}
          isLoadingMaterials={isLoadingMaterials}
          isLoadingOrders={isLoadingOrders}
        />

        <UpdateQualityResultModal
          onClose={closeUpdateResult}
          isOpenModal={isOpenUpdateResult}
          adaptedMaterials={adaptedMaterials}
          document={document}
          handleDocument={handleDocument}
          selectedStockBatches={selectedStockBatches}
          handleStockBatchesRows={handleStockBatchesRows}
          selectedMaterialItems={selectedMaterialItems}
          handleMaterialItemsRows={handleMaterialItemsRows}
          handleDocumentList={handleDocumentList}
          documentList={documentList}
          adaptedOrders={adaptedOrders}
          isLoadingMaterials={isLoadingMaterials}
          isLoadingOrders={isLoadingOrders}
        />
      </Page>
    </FormikProvider>
  );
};

export { Quality };
