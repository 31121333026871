import React, { FC } from "react";
import { notification, Table } from "antd";
import { UpdateRecipient, useUpdateRecipientsListMutation } from "shared/api";
import { useGetEmailsColumns } from "shared/hooks/useGetRecipientListColumns";
import { handleRequestError } from "shared/helpers";
import { useConfirmDeleteModal } from "shared/hooks";
import { useTranslation } from "react-i18next";

interface RecipientTableProps {
  parsedRecipientValues?: UpdateRecipient[];
  showEditEmailModal: (email: string) => void;
  refetch: () => void;
}

const RecipientTable: FC<RecipientTableProps> = ({
  parsedRecipientValues = [],
  showEditEmailModal,
  refetch,
}) => {
  const { t } = useTranslation();
  const [updateRecipientsList] = useUpdateRecipientsListMutation();
  const [api, contextHolder] = notification.useNotification();

  const extractEmail = (obj: any): string | null => {
    if (!obj || typeof obj !== "object") return null;

    if (typeof obj.value === "string") {
      return obj.value;
    }

    return extractEmail(obj.value);
  };

  const stringEmails = parsedRecipientValues
    .map((item) => (typeof item === "string" ? item : extractEmail(item)))
    .filter(Boolean);

  const handleDelete = async (emailToDelete: string) => {
    const updatedEmails = stringEmails.filter(
      (email) => email !== emailToDelete
    );

    try {
      await updateRecipientsList({
        key: "t1_recipient_list",
        value: JSON.stringify(updatedEmails),
        file: null,
      }).unwrap();

      api.success({
        message: "Success",
        description: "Recipient has been deleted successfully.",
      });

      refetch();
    } catch (error) {
      const description = handleRequestError(
        error,
        "Error while updating recipients list"
      );

      api.error({
        message: "Error",
        description,
      });
    }
  };

  const showDeleteUserModal = useConfirmDeleteModal(
    t("modal-placeholders.delete-warning-message"),
    handleDelete
  );

  const columns = useGetEmailsColumns({
    showDeleteUserModal,
    showEditEmailModal,
  });

  return (
    <>
      {contextHolder}
      <Table
        columns={columns}
        dataSource={parsedRecipientValues}
        rowKey="id"
        pagination={false}
      />
    </>
  );
};

export { RecipientTable };
