import { Quality } from "./Quality";
import { TemplatesConfig } from "./TemplatesConfig";
import { Dashboards } from "./Dashboards";
import { ArticleHyperlink } from "./SupplyChain/MaterialManagement/ui/ArticleHyperlinkModal";
import { MachinesHyperlink } from "./ProductCards/Machines/MachinesHyperlink";
import { OrderHyperlink } from "./Orders/Orders/OrderHyperlink";
import { ProcessTemplateHyperlink } from "./ProductCards/ProcessTemplates/ProcessTemplateHyperlink";
import { ProductCardHyperlink } from "./ProductCards/ProductCards/ProductCardHyperlink";
import { MaterialHyperlink } from "./SupplyChain/MaterialManagement/MaterialHyperlink";
import { MaterialCategoryHyperlink } from "./SupplyChain/MaterialCategoryManagement/MaterialCategoryHyperlink";
import { SignIn } from "./Auth/SignIn/SignIn";
import { MaterialCategoryManagement } from "./SupplyChain/MaterialCategoryManagement/MaterialCategoryManagement";
import { Users } from "./Users/Users";
import { ProcessTemplates } from "./ProductCards/ProcessTemplates/ProcessTemplates";
import { Machines } from "./ProductCards/Machines/Machines";
import { ProductCards } from "./ProductCards/ProductCards/ProductCards";
import { ProcessSteps } from "./ProductCards/ProcessSteps/ProcessSteps";
import { Companies } from "./Companies/Companies";
import { Orders } from "./Orders/Orders/Orders";
import { ProcessStepProduction } from "./Orders/Orders/UI/ProcessStepProduction/ProcessStepProduction";
import { DynamicProcessStepsForTheOrderPage } from "./Orders/Orders/UI/DynamicProcessStepsForTheOrderPage";
import { OrderPlans } from "./Orders/OrderPlans/OrderPlans";
import { TimelinePage } from "./Orders";
import { OrderReports } from "./Orders/OrdersReports";
import { ExecuteReport } from "./Reports";
import { CompaniesHyperlink } from "./Companies/CompaniesHyperlink";
import { UpdatedMaterialManagement } from "./SupplyChain/UpdatedMaterialManagement";
import { ItemView } from "./SupplyChain/ItemView";
import { StandAlone } from "./StandAlone";
import { AddRoles } from "./Users/ui/AddRoles";
import { AccessDenied } from "./ProtectedRoutePage/AccessDeniedPage";
import { OeeView } from "./OeeView/OeeView";
import { RecipientList } from "./T1RecipientList";

export const pagesConfig = {
  Quality,
  TemplatesConfig,
  Dashboards,
  ArticleHyperlink,
  MachinesHyperlink,
  OrderHyperlink,
  ProcessTemplateHyperlink,
  ProductCardHyperlink,
  MaterialHyperlink,
  MaterialCategoryHyperlink,
  SignIn,
  MaterialCategoryManagement,
  Users,
  AddRoles,
  ProcessTemplates,
  Machines,
  ProductCards,
  ProcessSteps,
  Companies,
  Orders,
  ProcessStepProduction,
  DynamicProcessStepsForTheOrderPage,
  OrderPlans,
  TimelinePage,
  OrderReports,
  ExecuteReport,
  CompaniesHyperlink,
  UpdatedMaterialManagement,
  ItemView,
  StandAlone,
  AccessDenied,
  OeeView,
  RecipientList,
};
