import React from "react";
import { Page } from "shared/ui";
import { AccessDeniedComponent } from "./AccessDeniedComponent";

const AccessDenied: React.FC = () => {
  return (
    <Page title="">
      <AccessDeniedComponent />
    </Page>
  );
};

export { AccessDenied };
