import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  AllMaterialCategoriesParams,
  AllMaterialsParams,
  CreateMaterialCategoryParams,
  CreateMaterialParams,
  CreateStockMaterialItem,
  DimensionItem,
  DimensionsResponse,
  DimensionUnitItem,
  MaterialCategoriesItem,
  MaterialCategoriesResponse,
  MaterialStockItem,
  MaterialItemsParams,
  MaterialItemsResponse,
  MaterialsItem,
  MaterialsResponse,
  MaterialStockBatchesResponse,
  StockMaterialItem,
  StockMaterialsParams,
  UpdateStockMaterialItem,
  MaterialReservationParams,
  MaterialsReservationResponse,
  MaterialReservationItem,
  CreateMaterialReservationItem,
  DimensionUnitParams,
  UpdateMaterial,
  AddStockMaterialItem,
  EditStockMaterialItem,
  MaterialConsumedParams,
  MaterialsConsumedResponse,
  CreateMaterialConsumed,
  MaterialConsumedItem,
  UpdateMaterialConsumed,
  AllMaterialItemsParams,
  ProducedOrderItem,
  ProducedOrderItemsResponse,
  MaterialDetailsParams,
  MaterialDetailsResponse,
  MaterialDetailsItem,
  GenerateMaterialItemReport,
  MaterialCategoryTemplateDownloadTemplate,
  UploadExcelFileParams,
  MaterialItemsForProductionParams,
  MaterialItemReport,
  PackageReport,
  OrderProductionSetParams,
  OrderProductionSetResponse,
  AllMaterialConsumedParams,
  UpdateProductionOrderSetParams,
  MaterialItemsWidgetResponse,
  MaterialCategoryByIdParams,
} from "./models";
import {
  CorrectAllPackagingParams,
  CorrectAllPackagingResponse,
  MaterialItemsMassUpdate,
  MoveItemsParams,
  OrderStepTrackerParams,
  OrderStepTrackerResponseItem,
  UpdateOrderStepTrackerItemExpanded,
} from "../orders";

export const materialsApi = createApi({
  baseQuery,
  reducerPath: "materialsApi",
  tagTypes: [
    "MaterialCategories",
    "Materials",
    "StockMaterial",
    "MaterialItems",
    "DynamicMaterialCategories",
    "MaterialReservations",
    "MaterialConsumed",
    "OrderCard",
  ],
  endpoints: (builder) => ({
    // dimensions
    getAllDimensions: builder.query<DimensionsResponse, void>({
      query: () => ({
        url: "/api/v1/dimensions/",
        method: "GET",
      }),
    }),

    // dimension units
    getDimensionUnits: builder.query<DimensionUnitItem[], DimensionUnitParams>({
      query: (body) => ({
        url: "/api/v1/dimension_units/",
        method: "GET",
        params: body,
      }),
    }),
    getIndividualDimension: builder.query<DimensionItem, number>({
      query: (id) => ({
        url: `/api/v1/dimensions/${id}`,
        method: "GET",
      }),
    }),

    // materials
    getAllMaterials: builder.query<MaterialsResponse, AllMaterialsParams>({
      query: (body) => ({
        url: "/api/v1/materials/",
        method: "GET",
        params: body,
      }),
      providesTags: ["Materials"],
    }),
    getAllMaterialsNoPagination: builder.query<
      MaterialsItem[],
      AllMaterialsParams | void
    >({
      query: (params = { expand: "suppliers.supplier" }) => ({
        url: "/api/v1/materials/",
        method: "GET",
        params: params || undefined,
      }),
      providesTags: ["Materials"],
    }),

    getMaterialById: builder.query<MaterialsItem[], AllMaterialsParams>({
      query: (params) => ({
        url: "/api/v1/materials/",
        method: "GET",
        params,
      }),
      providesTags: ["Materials"],
    }),

    createMaterial: builder.mutation<void, CreateMaterialParams>({
      query: (body) => ({
        url: "/api/v1/materials/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Materials"],
    }),
    deleteMaterial: builder.mutation<void, MaterialsItem>({
      query: (body) => ({
        url: `/api/v1/materials/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Materials"],
    }),
    updateMaterial: builder.mutation<void, UpdateMaterial>({
      query: (body) => ({
        url: `/api/v1/materials/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Materials"],
    }),
    getMaterialDetails: builder.query<
      MaterialDetailsResponse,
      MaterialDetailsParams
    >({
      query: (body) => ({
        url: `/api/v1/materials/${body.id}/stock_history/`,
        method: "GET",
        params: body,
      }),
    }),
    getMaterialDetailsWithoutParams: builder.query<
      MaterialDetailsItem[],
      MaterialDetailsParams
    >({
      query: (body) => ({
        url: `/api/v1/materials/${body.id}/stock_history/`,
        method: "GET",
        params: body,
      }),
    }),
    getMaterial: builder.query<void, MaterialsItem>({
      query: (body) => ({
        url: `/api/v1/materials/${body.id}/`,
        method: "GET",
        body,
      }),
      providesTags: ["Materials"],
    }),
    // materials category
    getMaterialCategories: builder.query<
      MaterialCategoriesResponse,
      AllMaterialCategoriesParams
    >({
      query: (params) => ({
        url: "/api/v1/material_categories/",
        method: "GET",
        params,
      }),
      providesTags: ["MaterialCategories"],
    }),
    getMaterialCategoryById: builder.query<
      MaterialCategoriesItem,
      MaterialCategoryByIdParams
    >({
      query: (params) => ({
        url: `/api/v1/material_categories/${params.id}/`,
        method: "GET",
        params,
      }),
      providesTags: ["MaterialCategories"],
    }),

    getMaterialCategoriesWithNoParams: builder.query<
      MaterialCategoriesItem[],
      AllMaterialCategoriesParams | void
    >({
      query: (params) => ({
        url: "/api/v1/material_categories/",
        method: "GET",
        params: params || undefined,
      }),
      providesTags: ["MaterialCategories", "DynamicMaterialCategories"],
    }),
    createMaterialCategory: builder.mutation<
      void,
      CreateMaterialCategoryParams
    >({
      query: (body) => ({
        url: "/api/v1/material_categories/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["MaterialCategories"],
    }),
    deleteMaterialCategory: builder.mutation<void, MaterialCategoriesItem>({
      query: (body) => ({
        url: `/api/v1/material_categories/${body.id}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["MaterialCategories"],
    }),
    updateMaterialCategory: builder.mutation<void, MaterialCategoriesItem>({
      query: (body) => ({
        url: `/api/v1/material_categories/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["MaterialCategories", "DynamicMaterialCategories"],
    }),

    // material stock batches
    getMaterialStockBatches: builder.query<
      MaterialStockBatchesResponse,
      StockMaterialsParams
    >({
      query: (params) => ({
        url: "/api/v1/material_stock_batches/",
        method: "GET",
        params,
      }),
      providesTags: ["StockMaterial"],
    }),
    getMaterialStockBatchesWithoutPagination: builder.query<
      StockMaterialItem[],
      StockMaterialsParams
    >({
      query: (params) => ({
        url: "/api/v1/material_stock_batches/",
        method: "GET",
        params,
      }),
      providesTags: ["StockMaterial"],
    }),
    getMaterialStockBatchesProducedInOrder: builder.query<
      ProducedOrderItemsResponse,
      StockMaterialsParams
    >({
      query: (body) => ({
        url: "/api/v1/material_stock_batches/",
        method: "GET",
        params: body,
      }),
      providesTags: ["StockMaterial"],
    }),
    addMaterialStockBatch: builder.mutation<void, AddStockMaterialItem>({
      query: ({
        material,
        batch_code,
        is_quantity_in_tracker_table,
        quantity,
        delivery_date,
        status,
        supplier,
      }) => ({
        url: "/api/v1/material_stock_batches/",
        method: "POST",
        body: {
          material,
          batch_code,
          is_quantity_in_tracker_table,
          quantity,
          delivery_date,
          status,
          supplier,
        },
      }),
      invalidatesTags: ["StockMaterial", "Materials"],
    }),
    deleteMaterialStockBatch: builder.mutation<
      void,
      StockMaterialItem | ProducedOrderItem
    >({
      query: (body) => ({
        url: `/api/v1/material_stock_batches/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["StockMaterial", "Materials"],
    }),
    updateMaterialStockBatch: builder.mutation<void, EditStockMaterialItem>({
      query: (body) => ({
        url: `/api/v1/material_stock_batches/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["StockMaterial", "Materials"],
    }),
    // material items
    getMaterialItemsByID: builder.query<
      MaterialItemsResponse,
      MaterialItemsParams
    >({
      query: (params) => ({
        url: `/api/v1/material_items/`,
        method: "GET",
        params,
      }),
      providesTags: ["MaterialItems"],
    }),
    getMaterialItemsByIDNoParams: builder.query<
      MaterialStockItem[],
      MaterialItemsParams
    >({
      query: ({ material, material_stock_batch, expand, status__in }) => ({
        url: `/api/v1/material_items/`,
        method: "GET",
        params: {
          material,
          material_stock_batch,
          expand,
          status__in,
        },
      }),
      providesTags: ["MaterialItems"],
    }),

    getMaterialItems: builder.query<
      MaterialItemsWidgetResponse,
      MaterialItemsParams
    >({
      query: (params) => ({
        url: `/api/v1/material_items/`,
        method: "GET",
        params,
      }),
      providesTags: ["MaterialItems"],
    }),

    getMaterialItemByID: builder.query<
      MaterialStockItem[],
      MaterialItemsParams
    >({
      query: (params) => ({
        url: `/api/v1/material_items/`,
        method: "GET",
        params,
      }),
      providesTags: ["MaterialItems"],
    }),
    getAllMaterialItems: builder.query<
      MaterialStockItem[],
      AllMaterialItemsParams
    >({
      query: (body) => ({
        url: `/api/v1/material_items/`,
        method: "GET",
        params: body,
      }),
      providesTags: ["MaterialItems"],
    }),
    createMaterialStockBatchItem: builder.mutation<
      void,
      CreateStockMaterialItem
    >({
      query: (body) => ({
        url: "/api/v1/material_items/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["MaterialItems", "StockMaterial"],
    }),
    deleteStockMaterialItem: builder.mutation<void, MaterialStockItem>({
      query: (body) => ({
        url: `/api/v1/material_items/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["MaterialItems"],
    }),
    updateStockMaterialItem: builder.mutation<void, UpdateStockMaterialItem>({
      query: (body) => ({
        url: `/api/v1/material_items/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["MaterialItems", "StockMaterial"],
    }),

    generateMaterialItemReport: builder.mutation<
      void,
      GenerateMaterialItemReport
    >({
      query: (body) => ({
        url: `/api/v1/material_items/${body.id}/generate_report/`,
        method: "POST",
        body,
        responseHandler: (response) => response.blob(),
      }),
    }),

    updateOrderProcessStepTrackersProduceMaterialItems: builder.mutation<
      void,
      UpdateOrderStepTrackerItemExpanded
    >({
      query: ({ id, output_materials, input_materials }) => ({
        url: `/api/v1/order_process_step_trackers/${id}/produce_material_items/`,
        method: "POST",
        body: { output_materials, input_materials },
      }),
      invalidatesTags: ["MaterialItems", "OrderCard"],
    }),

    getOrderProcessStepTrackersForTheOrderCard: builder.query<
      OrderStepTrackerResponseItem,
      OrderStepTrackerParams
    >({
      query: (body) => ({
        url: `/api/v1/order_process_step_trackers/${body.id}/`,
        method: "GET",
        params: body,
      }),
      providesTags: ["MaterialItems", "OrderCard"],
    }),

    getMaterialItemsForProductionPage: builder.query<
      MaterialStockItem[],
      MaterialItemsForProductionParams
    >({
      query: (body) => ({
        url: `/api/v1/material_items/`,
        method: "GET",
        params: body,
      }),
      providesTags: ["MaterialItems"],
    }),

    // material reservation
    getMaterialReservations: builder.query<
      MaterialsReservationResponse,
      MaterialReservationParams
    >({
      query: (body) => ({
        url: "/api/v1/material_reservations/",
        method: "GET",
        params: body,
      }),
      providesTags: ["MaterialReservations"],
    }),
    deleteMaterialReservationItem: builder.mutation<
      void,
      MaterialReservationItem
    >({
      query: (body) => ({
        url: `/api/v1/material_reservations/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["MaterialReservations", "Materials"],
    }),
    createMaterialReservationItem: builder.mutation<
      void,
      CreateMaterialReservationItem
    >({
      query: (body) => ({
        url: "/api/v1/material_reservations/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["MaterialReservations", "Materials"],
    }),
    updateReservationMaterialItem: builder.mutation<
      void,
      MaterialReservationItem
    >({
      query: (body) => ({
        url: `/api/v1/material_reservations/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["MaterialReservations", "Materials"],
    }),

    // material consumed
    getMaterialConsumed: builder.query<
      MaterialsConsumedResponse,
      MaterialConsumedParams
    >({
      query: (body) => ({
        url: "/api/v1/material_consumed/",
        method: "GET",
        params: body,
      }),
      providesTags: ["MaterialConsumed"],
    }),

    getAllMaterialConsumed: builder.query<
      MaterialConsumedItem[],
      AllMaterialConsumedParams
    >({
      query: (params) => ({
        url: "/api/v1/material_consumed/",
        method: "GET",
        params,
      }),
      providesTags: ["MaterialConsumed"],
    }),

    createMaterialConsumed: builder.mutation<void, CreateMaterialConsumed>({
      query: (body) => ({
        url: "/api/v1/material_consumed/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["MaterialConsumed"],
    }),
    deleteMaterialConsumed: builder.mutation<void, MaterialConsumedItem>({
      query: (body) => ({
        url: `/api/v1/material_consumed/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["MaterialConsumed"],
    }),
    updateMaterialConsumed: builder.mutation<void, UpdateMaterialConsumed>({
      query: (body) => ({
        url: `/api/v1/material_consumed/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["MaterialConsumed"],
    }),

    // Excel files requests

    getMaterialCategoryTemplate: builder.mutation<
      void,
      MaterialCategoryTemplateDownloadTemplate
    >({
      query: (body) => ({
        url: `/api/v1/material_categories/${body.id}/template/`,
        method: "POST",
        responseHandler: (response) => response.blob(),
      }),
    }),

    uploadMaterialItemsExcelFile: builder.mutation<void, UploadExcelFileParams>(
      {
        query: (body) => ({
          url: `/api/v1/material_items/xls_import/`,
          method: "POST",
          body: body.formData,
          formData: true,
        }),
        invalidatesTags: ["MaterialItems", "StockMaterial"],
      }
    ),

    // remove after Andreu tests
    generateMaterialItemReportData: builder.mutation<void, MaterialItemReport>({
      query: (body) => ({
        url: `/api/v1/material_items/${body.id}/generate_report_data/`,
        method: "POST",
        body,
      }),
    }),

    generatePackageReportData: builder.mutation<void, PackageReport>({
      query: (body) => ({
        url: `/api/v1/packagings/${body.id}/generate_report_data/`,
        method: "POST",
        body,
      }),
    }),

    // Order production set
    getOrderProductionSet: builder.query<
      OrderProductionSetResponse,
      OrderProductionSetParams
    >({
      query: (params) => ({
        url: `/api/v1/order_production_set/${params.id}/`,
        method: "GET",
        params,
      }),
    }),

    updateOrderProductionSet: builder.mutation<
      void,
      UpdateProductionOrderSetParams
    >({
      query: (body) => ({
        url: `/api/v1/order_production_set/${body.id}/`,
        method: "PUT",
        body,
      }),

      invalidatesTags: ["MaterialItems", "MaterialConsumed"],
    }),

    moveMateriaProductionItems: builder.mutation<void, MoveItemsParams>({
      query: (body) => ({
        url: `/api/v1/orders/${body.id}/move_items/`,
        method: "POST",
        body,
      }),

      invalidatesTags: ["MaterialItems"],
    }),

    getCorrectAllPackaging: builder.mutation<
      CorrectAllPackagingResponse,
      CorrectAllPackagingParams
    >({
      query: (body) => ({
        url: `/api/v1/orders/${body.id}/correct_all_packaging/`,
        method: "POST",
        body,
      }),

      invalidatesTags: ["MaterialItems"],
    }),

    massMaterialItemsUpdated: builder.mutation<void, MaterialItemsMassUpdate>({
      query: (body) => ({
        url: `/api/v1/material_items/mass_update/`,
        method: "POST",
        body,
      }),

      invalidatesTags: ["MaterialItems"],
    }),

    xlsExportMaterialItems: builder.mutation<Blob, { ids: number[] }>({
      query: ({ ids }) => ({
        url: `/api/v1/material_items/xls_export/`,
        method: "POST",
        params: { id: ids.join(",") },
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  // dimensions
  useGetAllDimensionsQuery,
  useGetIndividualDimensionQuery,
  // materials
  useGetAllMaterialsQuery,
  useGetAllMaterialsNoPaginationQuery,
  useCreateMaterialMutation,
  useDeleteMaterialMutation,
  useUpdateMaterialMutation,
  useGetMaterialDetailsQuery,
  useGetMaterialDetailsWithoutParamsQuery,
  useGetMaterialQuery,
  useGetMaterialItemsForProductionPageQuery,
  useGetMaterialByIdQuery,
  useUpdateOrderProcessStepTrackersProduceMaterialItemsMutation,
  // material categories
  useGetMaterialCategoriesQuery,
  useGetMaterialCategoryByIdQuery,
  useCreateMaterialCategoryMutation,
  useDeleteMaterialCategoryMutation,
  useUpdateMaterialCategoryMutation,
  useGetMaterialCategoriesWithNoParamsQuery,
  useGetMaterialCategoryTemplateMutation,
  // dimension items
  useGetDimensionUnitsQuery,
  // material stock batches
  useGetMaterialStockBatchesQuery,
  useGetMaterialStockBatchesWithoutPaginationQuery,
  useAddMaterialStockBatchMutation,
  useDeleteMaterialStockBatchMutation,
  useUpdateMaterialStockBatchMutation,
  useGetMaterialStockBatchesProducedInOrderQuery,
  // material stock items
  useGetMaterialItemsQuery,
  useGetMaterialItemsByIDQuery,
  useGetMaterialItemsByIDNoParamsQuery,
  useGetAllMaterialItemsQuery,
  useCreateMaterialStockBatchItemMutation,
  useDeleteStockMaterialItemMutation,
  useUpdateStockMaterialItemMutation,
  useGenerateMaterialItemReportMutation,
  useGetMaterialItemByIDQuery,
  // material reservation
  useGetMaterialReservationsQuery,
  useDeleteMaterialReservationItemMutation,
  useCreateMaterialReservationItemMutation,
  useUpdateReservationMaterialItemMutation,
  // material consumed
  useGetMaterialConsumedQuery,
  useCreateMaterialConsumedMutation,
  useDeleteMaterialConsumedMutation,
  useUpdateMaterialConsumedMutation,
  useGetAllMaterialConsumedQuery,

  // Excel files request
  useUploadMaterialItemsExcelFileMutation,
  // production order card data
  useGetOrderProcessStepTrackersForTheOrderCardQuery,
  // Temporary requests for testing
  useGenerateMaterialItemReportDataMutation,
  useGeneratePackageReportDataMutation,
  // Order production set
  useGetOrderProductionSetQuery,
  useUpdateOrderProductionSetMutation,
  // Move items on Prod page
  // Added request here because we need to trigger material_items refetch on this update
  useMoveMateriaProductionItemsMutation,
  // Correct all packaging
  useGetCorrectAllPackagingMutation,
  // Mass update material items
  useMassMaterialItemsUpdatedMutation,
  // Generate XLS
  useXlsExportMaterialItemsMutation,
} = materialsApi;
