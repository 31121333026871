import React, { FC } from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { useGetPermissionsAndRolesColumns } from "shared/hooks/useGetPermissionsAndRolesColumns";
import { AddNewRole } from "shared/api";
import { useFormikContext } from "formik";

interface PermissionsAndRolesTableProps {
  showEditRolesModal: () => void;
  rolesList: AddNewRole[] | undefined;
}

const PermissionsAndRolesTable: FC<PermissionsAndRolesTableProps> = ({
  showEditRolesModal,
  rolesList,
}) => {
  const { t } = useTranslation();

  const { setValues } = useFormikContext<AddNewRole>();

  const editRolesHandler = (record: AddNewRole) => {
    showEditRolesModal();
    setValues({
      id: record.id,
      name: record.name,
      permission_blocks: record.permission_blocks,
    });
  };

  const columns = useGetPermissionsAndRolesColumns({ t, editRolesHandler });

  return (
    <Table
      columns={columns}
      dataSource={rolesList}
      rowKey={(record) => record.name}
      pagination={false}
    />
  );
};

export { PermissionsAndRolesTable };
