import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { AccessPoints } from "shared/routers";
import { Page } from "shared/ui";
import { useModal, useOrdersOptions } from "shared/hooks";
import { noop } from "shared/helpers";
import { adaptDynamicFormValues } from "shared/adapters";
import { ConfigDynamicTrackerFields, UserItem } from "shared/api";
import { initialOrderValues } from "shared/constants";
import { DynamicValues } from "shared/types";
import { createAddOrOrderSchema } from "shared/schemas";
import { OrdersTable, AddOrderModal, EditOrderModal } from "./UI";

interface ProcessStepsProps {
  user?: UserItem;
}

const Orders: FC<ProcessStepsProps> = ({ user }) => {
  const { t } = useTranslation();

  const hasAddPermission = user?.access_points?.includes(
    AccessPoints.ORDERS_ADD
  );

  const hasEditPermission = user?.access_points?.includes(
    AccessPoints.ORDERS_EDIT
  );

  const hasDeletePermission = user?.access_points?.includes(
    AccessPoints.ORDERS_DELETE
  );

  const hasProductionPermission = user?.access_points?.includes(
    AccessPoints.ORDERS_PRODUCTION
  );

  const [dynamicValues, setDynamicValues] = useState<
    DynamicValues[] | ConfigDynamicTrackerFields[]
  >([]);

  const dynamicFieldsValidation = adaptDynamicFormValues(dynamicValues);

  const addOrUpdateOrderFormik = useFormik({
    initialValues: initialOrderValues,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: createAddOrOrderSchema(dynamicFieldsValidation),
    onSubmit: noop,
  });

  const { values } = addOrUpdateOrderFormik;

  const [visibleAddOrderModal, showAddOrderModal, hideAddOrderModal] =
    useModal();

  const [visibleEditOrderModal, showEditOrderModal, hideEditOrderModal] =
    useModal();

  const {
    companiesAdapted,
    adaptedSubBuyers,
    adaptedProductCard,
    dynamicCompanyFields,
  } = useOrdersOptions({
    buyer: Number(values?.buyer),
  });

  useEffect(() => {
    const dynamicValues = dynamicCompanyFields?.config_dynamic_order_fields;
    setDynamicValues(dynamicValues as ConfigDynamicTrackerFields[]);
  }, [addOrUpdateOrderFormik.values.buyer]);

  useEffect(() => {
    if (visibleAddOrderModal) {
      addOrUpdateOrderFormik.setFieldValue("product_card", null);
    }
  }, [addOrUpdateOrderFormik.values.buyer]);

  return (
    <FormikProvider value={addOrUpdateOrderFormik}>
      <Page
        title={t("orders.orders")}
        buttonTitle={hasAddPermission ? t("orders.add-order")! : undefined}
        onClick={hasAddPermission ? showAddOrderModal : undefined}
      >
        <OrdersTable
          hasEditPermission={hasEditPermission}
          hasDeletePermission={hasDeletePermission}
          hasProductionPermission={hasProductionPermission}
          showEditOrderModal={showEditOrderModal}
          companiesAdapted={companiesAdapted}
        />

        <AddOrderModal
          isOpenModal={visibleAddOrderModal}
          hideAddOrderModal={hideAddOrderModal}
          companiesAdapted={companiesAdapted}
          adaptedProductCard={adaptedProductCard}
          dynamicCompanyFields={dynamicCompanyFields}
          adaptedSubBuyers={adaptedSubBuyers}
        />

        <EditOrderModal
          isOpenModal={visibleEditOrderModal}
          hideEditOrderModal={hideEditOrderModal}
          dynamicCompanyFields={dynamicCompanyFields}
        />
      </Page>
    </FormikProvider>
  );
};

export { Orders };
