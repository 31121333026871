import { ColumnsType } from "antd/es/table";
import { UpdateRecipient } from "shared/api";
import { TableButton } from "shared/ui";

interface useGetEmailsColumnsProps {
  showDeleteUserModal: (email: string) => void;
  showEditEmailModal: (email: string) => void;
}

export const useGetEmailsColumns = ({
  showDeleteUserModal,
  showEditEmailModal,
}: useGetEmailsColumnsProps): ColumnsType<UpdateRecipient> => [
  {
    title: "№",
    key: "index",
    width: "10%",
    render: (_: any, __: any, index: number) => index + 1,
  },
  {
    title: "Recipient",
    dataIndex: "value",
    width: "90%",
    key: "value",
    render: (user: string) => (
      <TableButton
        type="title"
        title={user}
        onClick={() => showEditEmailModal(user)}
      />
    ),
  },
  {
    title: "Action",
    key: "action",
    width: "20%",
    render: (_: any, record: UpdateRecipient) => (
      <TableButton
        type="delete"
        onClick={() => showDeleteUserModal(record.value)}
      />
    ),
  },
];
