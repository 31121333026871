import { ColumnsType } from "antd/es/table";
import { TFunction } from "i18next";
import {
  MachinesSorting,
  machinesColumnsData,
  sortDirections,
} from "shared/constants";
import { MachineItem } from "shared/api";
import { TableButton } from "shared/ui";

interface UseGetMachinesTableColumnsProps {
  t: TFunction;
  editMachinesHandler: (record: MachineItem) => void;
  showMachineDeleteModal: (record: MachineItem) => void;
  changeSort: (ascendOrdering: string, descendOrdering: string) => void;
  hasEditPermission?: boolean;
  hasDeletePermission?: boolean;
}

const { name, processTemplate, actions } = machinesColumnsData;

export const useGetMachinesTableColumns = ({
  t,
  editMachinesHandler,
  showMachineDeleteModal,
  changeSort,
  hasDeletePermission,
  hasEditPermission,
}: UseGetMachinesTableColumnsProps): ColumnsType<MachineItem> => {
  const columns: ColumnsType<MachineItem> = [
    {
      title: t(name.title),
      render: (machine: MachineItem) => (
        <TableButton
          type="title"
          title={machine?.name}
          onClick={
            hasEditPermission ? () => editMachinesHandler(machine) : undefined
          }
        />
      ),
      width: name.width,
      onHeaderCell: () => ({
        onClick: () => {
          changeSort(MachinesSorting.NAME_ASC, MachinesSorting.NAME_DESC);
        },
      }),
      sorter: true,
      sortDirections,
    },
    {
      title: t(processTemplate.title),
      key: processTemplate.key,
      width: processTemplate.width,
      render: (item: MachineItem) => item?.process_template?.name,
    },
  ];

  if (hasDeletePermission) {
    columns.push({
      title: t(actions.title),
      key: actions.key,
      width: actions.width,

      render: (record: MachineItem) => {
        return (
          <div className="w-full flex items-center justify-around">
            <TableButton
              type="delete"
              onClick={() => showMachineDeleteModal(record)}
            />
          </div>
        );
      },
    });
  }

  return columns;
};
