import { ColumnsType } from "antd/es/table";
import { AddNewRole } from "shared/api";
import { TableButton } from "shared/ui";

interface PermissionItem {
  name: string;
  permission_blocks: string[];
}

interface PermissionTableProps {
  t: Function;
  editRolesHandler: (record: AddNewRole) => void;
}

export const useGetPermissionsAndRolesColumns = ({
  t,
  editRolesHandler,
}: PermissionTableProps): ColumnsType<PermissionItem> => [
  {
    title: t("Name"),
    render: (record: AddNewRole) => (
      <TableButton
        type="title"
        title={record?.name}
        onClick={() => editRolesHandler(record)}
      />
    ),
  },
  {
    title: t("Permission Blocks"),
    key: "permission_blocks",
    dataIndex: "permission_blocks",
    render: (blocks: string[]) => blocks?.join(", "),
  },
];
