import { Button, Modal, Select } from "antd";
import Title from "antd/es/typography/Title";
import React, { FC, useState } from "react";
import { useModal } from "shared/hooks";
import { OrderItem } from "shared/api";
import { Colors } from "shared/themes";
import { renderStatuses, StatusColor } from "shared/helpers";
import { AdaptedValues } from "shared/types";

type AcceptedItems = OrderItem;

interface DefaultModalProps {
  modalTitle: string;
  title: string;
  item: AcceptedItems;
  content: string;
  onOk: (item: AcceptedItems, newDate: string) => Promise<void>;
}

interface SelectProps {
  options: AdaptedValues[];
  statusColors: StatusColor[];
}

type TableItemModalProps = DefaultModalProps & SelectProps;

const TableModalWithSelect: FC<TableItemModalProps> = ({
  modalTitle,
  title,
  onOk,
  statusColors,
  content,
  item,
  options,
}) => {
  const [isVisible, showModal, hideModal] = useModal();
  const [selectValue, setSelectValue] = useState<string>(content);

  const handleSelectChange = (value: string) => {
    setSelectValue(value);
  };

  const confirmFunction = () => {
    onOk?.(item, selectValue);
    hideModal();
  };

  const statusStyle = statusColors.find(
    (status) => status.label === renderStatuses(item)
  );

  return (
    <>
      <Button
        onClick={showModal}
        className="mb-1.25 !text-[12px] border-none bg-transparent shadow-none !w-full p-1"
        style={{
          background: statusStyle?.background || "transparent",
          color: statusStyle?.color || "inherit",
          fontWeight: statusStyle?.fontWeight || "normal",
        }}
      >
        {renderStatuses(item)}
      </Button>

      <Modal
        open={isVisible}
        onCancel={hideModal}
        title={modalTitle}
        onOk={confirmFunction}
        okButtonProps={{
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
      >
        <>
          <Title level={5}>{title}</Title>
          <Select
            value={selectValue}
            options={statusColors?.length ? undefined : options}
            onChange={handleSelectChange}
            className="w-full"
            size="large"
            showSearch
          >
            {statusColors?.length &&
              options?.map((option, index) => {
                const styles = statusColors[index];
                return (
                  <Select.Option
                    key={option.value.toString()}
                    value={option.value}
                    style={{
                      background: styles.background || "transparent",
                      color: styles.color || "inherit",
                      fontWeight: styles.fontWeight || "normal",
                      padding: "8px",
                      marginBottom: "2px",
                      cursor: "pointer",
                    }}
                  >
                    {option.label}
                  </Select.Option>
                );
              })}
          </Select>
        </>
      </Modal>
    </>
  );
};

export { TableModalWithSelect };
