import React, { FC, useEffect, useMemo, useState } from "react";
import { UploadFile } from "antd";
import { useTranslation } from "react-i18next";
import { FormikProvider, useFormik } from "formik";
import { Page } from "shared/ui";
import {
  useCompaniesOptions,
  useDebouncedTableFilter,
  useModal,
} from "shared/hooks";
import { noop } from "shared/helpers";
import {
  CompaniesTable,
  AddCompanyModal,
  EditCompanyModal,
} from "pages/Companies";
import { companiesInitialValues } from "shared/constants";
import { AddCompanySchema, EditCompanySchema } from "shared/schemas";
import { useGetDefaultConfigDynamicFieldsQuery, UserItem } from "shared/api";
import { AccessPoints } from "shared/routers";

interface CompaniesProps {
  user?: UserItem;
}

const Companies: FC<CompaniesProps> = ({ user }) => {
  const hasAddPermission = user?.access_points?.includes(
    AccessPoints.COMPANIES_ADD
  );

  const hasEditPermission = user?.access_points?.includes(
    AccessPoints.COMPANIES_EDIT
  );

  const hasDeletePermission = user?.access_points?.includes(
    AccessPoints.COMPANIES_DELETE
  );

  const { t } = useTranslation();

  const [visibleAddCompanyModal, showAddCompanyModal, hideAddCompanyModal] =
    useModal();

  const [visibleEditCompanyModal, showEditCompanyModal, hideEditCompanyModal] =
    useModal();

  const [documentList, setDocumentList] = useState<UploadFile<unknown>[]>([]);

  const handleDocumentList = (files: UploadFile[]) => setDocumentList(files);

  const {
    handleFilterChange: handleProductCardValue,
    value: productCardValue,
    clearFilterValue: clearProductCardValue,
  } = useDebouncedTableFilter("", 400);

  const {
    adaptedProductCard,
    adaptedMaterialCategories,
    parentCompanies,
    productCards,
    materialCategories,
    unitsWithLabel,
    isLoadingProductCards,
  } = useCompaniesOptions();

  const { data: dynamicFieldsByDefault } =
    useGetDefaultConfigDynamicFieldsQuery();

  const parsedDynamicFields = useMemo(() => {
    try {
      return dynamicFieldsByDefault?.[0]?.value
        ? JSON.parse(dynamicFieldsByDefault[0].value)
        : [];
    } catch (error) {
      return [];
    }
  }, [dynamicFieldsByDefault]);

  const validationSchema = useMemo(() => {
    return visibleAddCompanyModal ? AddCompanySchema : EditCompanySchema;
  }, [visibleAddCompanyModal]);

  const addOrUpdateCompanyFormik = useFormik({
    initialValues: {
      ...companiesInitialValues,
      sections: [],
    },
    validationSchema,
    onSubmit: noop,
  });

  useEffect(() => {
    if (visibleAddCompanyModal && parsedDynamicFields.length) {
      addOrUpdateCompanyFormik.setFieldValue("sections", parsedDynamicFields);
    }
  }, [visibleAddCompanyModal, parsedDynamicFields]);

  return (
    <FormikProvider value={addOrUpdateCompanyFormik}>
      <Page
        title={t("companies.title")}
        buttonTitle={
          hasAddPermission ? t("companies.add-company-btn")! : undefined
        }
        onClick={hasAddPermission ? showAddCompanyModal : undefined}
      >
        <CompaniesTable
          showEditCompanyModal={showEditCompanyModal}
          hasDeletePermission={hasDeletePermission}
        />

        {hasAddPermission && (
          <AddCompanyModal
            isOpenModal={hasAddPermission ? visibleAddCompanyModal : false}
            adaptedDimensionUnits={unitsWithLabel}
            adaptedProductCard={adaptedProductCard}
            productCards={productCards}
            hideAddCompanyModal={hideAddCompanyModal}
            adaptedMaterialCategories={adaptedMaterialCategories}
            documentList={documentList}
            handleDocumentList={handleDocumentList}
            parentCompanies={parentCompanies}
            materialCategories={materialCategories ?? []}
            isLoadingProductCards={isLoadingProductCards}
            handleProductCardValue={handleProductCardValue}
            productCardValue={productCardValue}
            clearProductCardValue={clearProductCardValue}
          />
        )}

        {hasEditPermission && (
          <EditCompanyModal
            isOpenModal={visibleEditCompanyModal}
            adaptedDimensionUnits={unitsWithLabel}
            adaptedProductCard={adaptedProductCard}
            productCards={productCards}
            hideEditCompanyModal={hideEditCompanyModal}
            adaptedMaterialCategories={adaptedMaterialCategories}
            materialCategories={materialCategories}
            documentList={documentList}
            handleDocumentList={handleDocumentList}
            parentCompanies={parentCompanies}
            isLoadingProductCards={isLoadingProductCards}
            handleProductCardValue={handleProductCardValue}
            productCardValue={productCardValue}
            clearProductCardValue={clearProductCardValue}
          />
        )}
      </Page>
    </FormikProvider>
  );
};

export { Companies };
