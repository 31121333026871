import classNames from "classnames";
import React, { FC } from "react";
import { useWindowDimensions } from "shared/hooks";
import { OrderProcessInfoBox, OrderStepTrackerResponseItem } from "shared/api";
import { DynamicStepsContainer } from "./DynamicStepsContainer";

interface ProdPageInfoBlockProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  orderProcessStepInfoBox?: OrderProcessInfoBox;
  processStepId?: number;
}

const ProdPageInfoBlock: FC<ProdPageInfoBlockProps> = ({
  orderStepTrackerResponseItem,
  orderProcessStepInfoBox,
  processStepId,
}) => {
  const { isDesktop } = useWindowDimensions();

  return (
    <div
      className={classNames(
        "w-full overflow-y-auto overflow-x-hidden auto h-[100%] flex gap-x-5 items-start justify-between",
        {
          "flex-col gap-y-3": !isDesktop,
        }
      )}
    >
      <DynamicStepsContainer
        processStepId={processStepId}
        orderProcessStepInfoBox={orderProcessStepInfoBox}
        orderStepTrackerResponseItem={orderStepTrackerResponseItem}
      />
    </div>
  );
};

export { ProdPageInfoBlock };
