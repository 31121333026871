import { ReportFormatEnum } from "shared/api";

export const initialExecuteReportValues = {
  id: null,
  material_item_id: null,
  packaging_id: null,
  order_id: null,
  material_id: null,
  // order_wanted_delivery_datetime_start: null,
  // order_wanted_delivery_datetime_end: null,
  order_production_datetime_start: null,
  order_production_datetime_end: null,
  report_format: ReportFormatEnum.PDF,
  quality_result_id: null,
  packaging_ids: [],
};

export const reportFileFormats = [
  {
    label: "Basic Formats",
    title: "Basic Formats",
    options: [
      {
        label: ReportFormatEnum.PDF.toUpperCase(),
        value: ReportFormatEnum.PDF,
      },
      {
        label: ReportFormatEnum.DOCX.toUpperCase(),
        value: ReportFormatEnum.DOCX,
      },
      {
        label: ReportFormatEnum.XLSX.toUpperCase(),
        value: ReportFormatEnum.XLSX,
      },
    ],
  },
  {
    label: "Other Formats",
    title: "Other Formats",
    options: [
      {
        label: ReportFormatEnum.ODS.toUpperCase(),
        value: ReportFormatEnum.ODS,
      },
      {
        label: ReportFormatEnum.XLS.toUpperCase(),
        value: ReportFormatEnum.XLS,
      },
      {
        label: ReportFormatEnum.CSV.toUpperCase(),
        value: ReportFormatEnum.CSV,
      },
      {
        label: ReportFormatEnum.TXT.toUpperCase(),
        value: ReportFormatEnum.TXT,
      },
      {
        label: ReportFormatEnum.ODP.toUpperCase(),
        value: ReportFormatEnum.ODP,
      },
      {
        label: ReportFormatEnum.PPT.toUpperCase(),
        value: ReportFormatEnum.PPT,
      },
      {
        label: ReportFormatEnum.PPTX.toUpperCase(),
        value: ReportFormatEnum.PPTX,
      },
      {
        label: ReportFormatEnum.JPG.toUpperCase(),
        value: ReportFormatEnum.JPG,
      },
      {
        label: ReportFormatEnum.PNG.toUpperCase(),
        value: ReportFormatEnum.PNG,
      },
      {
        label: ReportFormatEnum.ODT.toUpperCase(),
        value: ReportFormatEnum.ODT,
      },
      {
        label: ReportFormatEnum.DOC.toUpperCase(),
        value: ReportFormatEnum.DOC,
      },
      {
        label: ReportFormatEnum.EPUB.toUpperCase(),
        value: ReportFormatEnum.EPUB,
      },
      {
        label: ReportFormatEnum.HTML.toUpperCase(),
        value: ReportFormatEnum.HTML,
      },
      {
        label: ReportFormatEnum.XML.toUpperCase(),
        value: ReportFormatEnum.XML,
      },
      {
        label: ReportFormatEnum.IDML.toUpperCase(),
        value: ReportFormatEnum.IDML,
      },
    ],
  },
];

export const mimeTypeMap: { [key: string]: string } = {
  ods: "application/vnd.oasis.opendocument.spreadsheet",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xls: "application/vnd.ms-excel",
  csv: "text/csv",
  pdf: "application/pdf",
  txt: "text/plain",
  odp: "application/vnd.oasis.opendocument.presentation",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  jpg: "image/jpeg",
  png: "image/png",
  odt: "application/vnd.oasis.opendocument.text",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  epub: "application/epub+zip",
  html: "text/html",
  xml: "application/xml",
  idml: "application/vnd.adobe.indesign-idml-package",
};
