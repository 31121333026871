import React, { useState } from "react";
import { useModal } from "shared/hooks";
import { Page } from "shared/ui";
import { FormikProvider, useFormik } from "formik";
import { recipientListInitialValues } from "shared/constants";
import { noop } from "shared/helpers";
import { AddOrChangeRecipientSchema } from "shared/schemas";
import { useGetRecipientsListQuery } from "shared/api";
import { RecipientTable } from "./RecipientTable";
import { AddEmailModal } from "./AddEmailModal";
import { UpdateEmailModal } from "./UpdateEmailModal";

const RecipientList = () => {
  const [isOpenAddRecipientModal, showRecipientModal, closeRecipientModal] =
    useModal();

  const [isOpenEditEmailModal, showEditEmailModal, closeEditEmailModal] =
    useModal();

  const [emailToEdit, setEmailToEdit] = useState<string | null>(null);

  const handleShowEditEmailModal = (email: string) => {
    setEmailToEdit(email);
    showEditEmailModal();
  };

  const { data: recipientLists, refetch } = useGetRecipientsListQuery();

  const parsedRecipientValues =
    recipientLists && Array.isArray(JSON.parse(recipientLists.value))
      ? JSON.parse(recipientLists.value).map((value: string) => ({
          value,
        }))
      : [];

  const addOrChangeRecipientFormik = useFormik({
    initialValues: {
      ...recipientListInitialValues,
    },
    validateOnMount: false,
    validateOnBlur: true,
    validationSchema: AddOrChangeRecipientSchema,
    onSubmit: noop,
  });

  return (
    <FormikProvider value={addOrChangeRecipientFormik}>
      <Page
        title="T1 Recipient list"
        buttonTitle="Add recipient list"
        onClick={showRecipientModal}
      >
        <RecipientTable
          refetch={refetch}
          showEditEmailModal={handleShowEditEmailModal}
          parsedRecipientValues={parsedRecipientValues}
        />
        <UpdateEmailModal
          emailToEdit={emailToEdit || ""}
          isOpen={isOpenEditEmailModal}
          closeModal={closeEditEmailModal}
          existingEmails={parsedRecipientValues}
          refetch={refetch}
        />
        <AddEmailModal
          isOpen={isOpenAddRecipientModal}
          closeModal={closeRecipientModal}
          refetch={refetch}
          existingEmails={parsedRecipientValues}
        />
      </Page>
    </FormikProvider>
  );
};

export { RecipientList };
