import { Modal, notification, Select } from "antd";
import { useFormikContext } from "formik";
import { mimeTypeMap, reportFileFormats } from "shared/constants";
import { ExecuteReportValues } from "shared/types";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ReportFormat,
  ReportFormatEnum,
  ReportItem,
  useExecuteReportMutation,
} from "shared/api";
import { handleRequestError } from "shared/helpers";
import { Colors } from "shared/themes";
import { Loader } from "shared/ui";

interface DownloadReportProps {
  isOpen: boolean;
  onClose: () => void;
  selectedReport?: ReportItem;
}

const DownloadReport: FC<DownloadReportProps> = ({
  onClose,
  isOpen,
  selectedReport,
}) => {
  const { t } = useTranslation("", { keyPrefix: "executeReports" });
  const [api, contextHolder] = notification.useNotification();
  const { values } = useFormikContext<ExecuteReportValues>();

  const [executeReport, { isLoading: isLoadingReportDownload }] =
    useExecuteReportMutation();

  const [selectedReportFormat, setSelectedReportFormat] =
    useState<ReportFormat>(ReportFormatEnum.PDF);

  const onReportFormatChange = (format: ReportFormat) => {
    setSelectedReportFormat(format);
  };

  const closeDownloadModal = () => {
    setSelectedReportFormat(ReportFormatEnum.PDF);
    onClose();
  };

  const downloadReport = async (reportFormat: ReportFormat) => {
    try {
      const response = await executeReport({
        id: values.id!,
        material_id: values.material_id,
        material_item_id: values.material_item_id,
        order_id: values.order_id,
        order_production_datetime_end: values.order_production_datetime_end,
        order_production_datetime_start: values.order_production_datetime_start,
        // order_wanted_delivery_datetime_end:
        //   values.order_wanted_delivery_datetime_end,
        // order_wanted_delivery_datetime_start:
        //   values.order_wanted_delivery_datetime_start,
        packaging_id: values.packaging_id,
        report_format: reportFormat,
        quality_result_id: values.quality_result_id,
        packaging_ids: values.packaging_ids,
      }).unwrap();

      if (response !== null && response !== undefined) {
        const mimeType =
          mimeTypeMap[reportFormat] || "application/octet-stream";
        const file = new Blob([response], { type: mimeType });
        const fileURL = URL.createObjectURL(file);

        const link = document.createElement("a");
        link.href = fileURL;
        link.download = `${selectedReport?.name}.${reportFormat}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      api.success({
        message: t("success"),
        description: t("successDescription", {
          reportName: selectedReport?.name,
          format: reportFormat.toUpperCase(),
        }),
      });
    } catch (error) {
      api.error({
        message: t("error"),
        description: handleRequestError(error, t("error")),
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("downloadReport")}
        open={isOpen}
        onCancel={closeDownloadModal}
        onOk={() => downloadReport(selectedReportFormat)}
        okText={t("okDownloadText", {
          format: selectedReportFormat.toUpperCase(),
        })}
        okButtonProps={{
          style: { background: Colors.blue.primary },
        }}
      >
        {isLoadingReportDownload && <Loader type="fullscreen-backdrop" />}

        <Select
          className="w-full"
          options={reportFileFormats}
          value={selectedReportFormat}
          onChange={onReportFormatChange}
        />
      </Modal>
    </>
  );
};

export { DownloadReport };
