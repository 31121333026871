import React, { Dispatch, FC, SetStateAction } from "react";
import { useFormikContext } from "formik";
import {
  DynamicOutputMaterialData,
  OrderProcessInfoBox,
  OrderStepTrackerResponseItem,
  ProductionOutputMaterial,
} from "shared/api";
import { Divider } from "antd";
import { useOutputMaterialDataForProductionPage } from "shared/hooks";
import classNames from "classnames";
import { InitialInputMaterials, InitialOutputMaterials } from "shared/types";
import { AddProductionRequest } from "./ProcessStepProduction";
import { MaterialSection } from "./DynamicOutputMaterials/MaterialSection";
import { OutputMasterCard } from "./DynamicOutputMaterials";
import {
  CountOfItemsStep,
  UpdatedOutputMaterialHeader,
} from "./DynamicOutputMaterials/UpdatedOutputMaterials";
import { UpdateOutputMaterials } from "./DynamicOutputMaterials/UpdatedOutputMaterials/UpdateOutputMaterials";
import { OutputMaterialsWarning } from "./DynamicOutputMaterials/UpdatedOutputMaterials/OutputMaterialsWarning";
import { ProdPageInfoBlock } from "./ProdPageInfoBlock";

interface OutputMaterialsContainerProps {
  orderStepTrackerResponseItem?: OrderStepTrackerResponseItem;
  processStepId?: number;
  handleCountOfItemsChange: (value: number, index: number) => void;
  isEditMode: boolean[];
  toggleEditMode: (index: number) => void;
  orderProcessStepInfoBox?: OrderProcessInfoBox;
  outputMaterialsList: ProductionOutputMaterial[];
  isValidProductionForm: boolean;
  values: AddProductionRequest;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  adaptedInitialInputMaterials: InitialInputMaterials[];
  adaptedInitialOutputMaterials: InitialOutputMaterials[];
  refetchOrderProcessStepTracker: () => void;
  setIsUnlocked: Dispatch<SetStateAction<Record<number, boolean>>>;
  setIsSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
  isOrderCardMachineSelected: boolean;
}

const OutputMaterialsContainer: FC<OutputMaterialsContainerProps> = ({
  orderStepTrackerResponseItem,
  handleCountOfItemsChange,
  isEditMode,
  toggleEditMode,
  orderProcessStepInfoBox,
  processStepId,
  outputMaterialsList,
  adaptedInitialInputMaterials,
  adaptedInitialOutputMaterials,
  isValidProductionForm,
  refetchOrderProcessStepTracker,
  setFieldValue,
  setIsUnlocked,
  setIsSubmitted,
  isOrderCardMachineSelected,
  values,
}) => {
  const { values: mainValues, setFieldValue: setMainFieldValue } =
    useFormikContext<AddProductionRequest>();

  const outputMaterials = mainValues?.output_materials;

  const handleSetOutputMaterialsValuesBasedOnCountOfCopies = (
    materials: DynamicOutputMaterialData[],
    countOfCopiedItems: number,
    index: number
  ) => {
    handleCountOfItemsChange(countOfCopiedItems, index);

    const repeatedMaterials = Array.from(
      { length: countOfCopiedItems },
      (_, index) => materials[index % materials.length]
    );

    setMainFieldValue(
      `output_materials.${index}.dynamicData`,
      repeatedMaterials
    );
  };

  const {
    outputMaterialsValues,
    setOutputMaterialFieldValue,
    validationArray,
  } = useOutputMaterialDataForProductionPage({
    mainValues,
    orderStepTrackerResponseItem,
  });

  return (
    <>
      <MaterialSection>
        <div className="flex w-full">
          <div className="w-[35%] h-full mt-5 flex-col gap-3">
            <ProdPageInfoBlock
              processStepId={processStepId}
              orderProcessStepInfoBox={orderProcessStepInfoBox}
              orderStepTrackerResponseItem={orderStepTrackerResponseItem}
            />
          </div>
          <div className="w-[65%] flex flex-col gap-y-10 p-3">
            {outputMaterials?.map((_, index) => {
              const isActiveEditMode = isEditMode?.[index];

              return (
                <div key={index} className="h-full">
                  <UpdatedOutputMaterialHeader
                    outputMaterials={outputMaterials}
                    index={index}
                    isEditMode={isEditMode}
                    toggleEditMode={toggleEditMode}
                    orderStepTrackerResponseItem={orderStepTrackerResponseItem}
                  />

                  <div className="w-full h-[96%] flex justify-between flex-col">
                    <div
                      className={classNames(
                        "w-full border-[1px] border-black",
                        {
                          hidden: isActiveEditMode,
                        }
                      )}
                    >
                      <OutputMasterCard
                        inputIndex={index}
                        orderStepTrackerResponseItem={
                          orderStepTrackerResponseItem
                        }
                        outputMaterialsValues={outputMaterialsValues}
                        setOutputMaterialFieldValue={
                          setOutputMaterialFieldValue
                        }
                      />

                      <CountOfItemsStep
                        setIsSubmitted={setIsSubmitted}
                        isOrderCardMachineSelected={isOrderCardMachineSelected}
                        handleCountOfItemsChange={handleCountOfItemsChange}
                        outputMaterialsValues={outputMaterialsValues}
                        handleSetOutputMaterialsValuesBasedOnCountOfCopies={
                          handleSetOutputMaterialsValuesBasedOnCountOfCopies
                        }
                        inputIndex={index}
                        validationArray={validationArray}
                        setIsUnlocked={setIsUnlocked}
                        outputMaterials={outputMaterialsList}
                        isValidProductionForm={isValidProductionForm}
                        processStepId={processStepId}
                        values={values}
                        orderStepTrackerResponseItem={
                          orderStepTrackerResponseItem
                        }
                        setFieldValue={setFieldValue}
                        adaptedInitialInputMaterials={
                          adaptedInitialInputMaterials
                        }
                        adaptedInitialOutputMaterials={
                          adaptedInitialOutputMaterials
                        }
                        refetchOrderProcessStepTracker={
                          refetchOrderProcessStepTracker
                        }
                      />
                    </div>

                    {isActiveEditMode ? (
                      <>
                        <div className="flex items-stretch">
                          <Divider
                            type="vertical"
                            className="bg-black h-full"
                          />
                        </div>

                        <UpdateOutputMaterials
                          mainValues={mainValues}
                          index={index}
                          toggleEditMode={toggleEditMode}
                          orderStepTrackerResponseItem={
                            orderStepTrackerResponseItem
                          }
                        />
                      </>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </MaterialSection>

      <div className="flex flex-wrap gap-x-4 w-full items-center justify-center">
        {outputMaterials?.map((_, index) => (
          <OutputMaterialsWarning
            key={index}
            index={index}
            outputMaterials={outputMaterials}
            handleSetOutputMaterialsValuesBasedOnCountOfCopies={
              handleSetOutputMaterialsValuesBasedOnCountOfCopies
            }
            orderStepTrackerResponseItem={orderStepTrackerResponseItem}
          />
        ))}
      </div>
    </>
  );
};

const OutputMaterialsContainerMemo = React.memo(OutputMaterialsContainer);
export { OutputMaterialsContainerMemo as OutputMaterialsContainer };
