import React, { FC } from "react";
import { ReportItem } from "shared/api";
import { DailyDatesBlock } from "./DailyDatesBlock";

interface MultipleOrderDailyProps {
  selectedReport?: ReportItem;
}

const MultipleOrderDaily: FC<MultipleOrderDailyProps> = ({
  selectedReport,
}) => {
  return <DailyDatesBlock selectedReport={selectedReport} />;
};

export { MultipleOrderDaily };
