import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Area,
  Legend,
  Scatter,
} from "recharts";

const chartData = [
  { name: "2020 June", value: 91, avg: 86.5 },
  { name: "2020 July", value: 86.5, avg: 86.5 },
  { name: "2020 August", value: 88.5, avg: 86.5 },
  { name: "2020 September", value: 86.6, avg: 86.5 },
  { name: "2020 October", value: 88.4, avg: 86.5 },
  { name: "2020 November", value: 89.1, avg: 86.5 },
  { name: "2021 January", value: 87.5, avg: 86.5 },
  { name: "2021 February", value: 84.1, avg: 86.5 },
  { name: "2021 March", value: 86.9, avg: 86.5 },
  { name: "2021 April", value: 85.9, avg: 86.5 },
];

const OEECharts = () => {
  return (
    <div className="grid grid-cols-2 gap-4">
      {/* OEE TIME Chart */}
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h3 className="text-lg font-bold text-center">OEE TIME</h3>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[80, 100]} tickFormatter={(value) => `${value}%`} />
            <Tooltip />
            <Legend />
            {/* Filled area under the chart */}
            <Area
              type="monotone"
              dataKey="value"
              stroke="#00A6FF"
              fill="#00A6FF40"
            />
            {/* Red Average line */}
            <ReferenceLine
              y={86.5}
              stroke="red"
              strokeWidth={2}
              label="Average"
            />
            {/* Main OEE line */}
            <Line
              type="monotone"
              dataKey="value"
              stroke="#00A6FF"
              strokeWidth={2}
              dot={{ r: 4 }}
            />
            {/* Displaying data points */}
            <Scatter dataKey="value" fill="#00A6FF" />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* OEE Chart */}
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h3 className="text-lg font-bold text-center">OEE</h3>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[80, 100]} tickFormatter={(value) => `${value}%`} />
            <Tooltip />
            <Legend />
            {/* Filled area under the chart */}
            <Area
              type="monotone"
              dataKey="value"
              stroke="#00A6FF"
              fill="#00A6FF40"
            />
            {/* Red Average line */}
            <ReferenceLine
              y={86.5}
              stroke="red"
              strokeWidth={2}
              label="Average"
            />
            {/* Main OEE line */}
            <Line
              type="monotone"
              dataKey="value"
              stroke="#00A6FF"
              strokeWidth={2}
              dot={{ r: 4 }}
            />
            {/* Displaying data points */}
            <Scatter dataKey="value" fill="#00A6FF" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export { OEECharts };
