import * as Yup from "yup";

export const AddOrUpdateUserSchema = Yup.object().shape({
  username: Yup.string().required("update-user-validation.username"),
  first_name: Yup.string().required("update-user-validation.firstname"),
  last_name: Yup.string().required("update-user-validation.lastname"),
  email: Yup.string()
    .email("update-user-validation.valid-email")
    .required("update-user-validation.email"),
  roles: Yup.array()
    .of(Yup.number().required("update-user-validation.role-id"))
    .default([]),
});

export const userRolesValidationSchema = Yup.object().shape({
  id: Yup.number().nullable(),
  name: Yup.string()
    .trim()
    .min(3, "Name must be at least 3 characters long")
    .max(50, "Name must be at most 50 characters long")
    .required("Name is required"),
  permission_blocks: Yup.array()
    .of(Yup.string().trim())
    .required("Permissions are required"),
});
