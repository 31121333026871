import { DatePickerValue } from "shared/ui";

export interface AllReportsParams {
  offset?: number | null;
  limit?: number | null;
  expand?: string;
  ordering?: string;
  report_type?: string;
}

export const enum ReportType {
  MATERIAL_ITEM = "MATERIAL_ITEM",
  PACKAGING = "PACKAGING",
  REEL_LAB_ARCHIVE = "REEL_LAB_ARCHIVE",
  ORDER = "ORDER",
  MULTIPLE_ORDER = "MULTIPLE_ORDER",
  MULTIPLE_PACKAGING = "MULTIPLE_PACKAGING",
  MULTIPLE_MATERIAL = "MULTIPLE_MATERIAL",
  MULTIPLE_QUALITY = "MULTIPLE_QUALITY",
  MULTIPLE_MATERIAL_ITEM = "MULTIPLE_MATERIAL_ITEM",
  MANAGEMENT = "MANAGEMENT",
}

export interface ReportItem {
  id: number;
  name: string;
  template_id: string;
  report_type: ReportType;
  is_default: boolean;
}

export interface CreateReport {
  name: string;
  template_id: string;
  report_type: ReportType;
  is_default: boolean;
}

export interface ReportsResponse {
  count: number;
  next: string;
  previous: string;
  results: ReportItem[];
}

export type ReportFormat =
  | "ods"
  | "xlsx"
  | "xls"
  | "csv"
  | "pdf"
  | "txt"
  | "odp"
  | "ppt"
  | "pptx"
  | "jpg"
  | "png"
  | "odt"
  | "doc"
  | "docx"
  | "epub"
  | "html"
  | "xml"
  | "idml";

export const enum ReportFormatEnum {
  ODS = "ods",
  XLSX = "xlsx",
  XLS = "xls",
  CSV = "csv",
  PDF = "pdf",
  TXT = "txt",
  ODP = "odp",
  PPT = "ppt",
  PPTX = "pptx",
  JPG = "jpg",
  PNG = "png",
  ODT = "odt",
  DOC = "doc",
  DOCX = "docx",
  EPUB = "epub",
  HTML = "html",
  XML = "xml",
  IDML = "idml",
}

export interface ExecuteReport {
  id: number | null;
  material_item_id: number | null;
  packaging_id: number | null;
  order_id: number | null;
  material_id: number | null;
  // order_wanted_delivery_datetime_start: DatePickerValue;
  // order_wanted_delivery_datetime_end: DatePickerValue;
  order_production_datetime_start: DatePickerValue;
  order_production_datetime_end: DatePickerValue;
  report_format: ReportFormat;
  quality_result_id: number | null;
  packaging_ids: number[] | null;
}
