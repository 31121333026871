import React from "react";
import { FaTimesCircle } from "react-icons/fa";

const AccessDeniedComponent = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-gray-700">
      <FaTimesCircle className="text-red-600 text-7xl mb-4" />
      <h1 className="text-3xl font-semibold">Access Denied</h1>
      <p className="text-center text-lg mt-2">
        You do not have permission to view this page.
      </p>
      <p className="text-center text-lg">
        Please check your credentials and try again.
      </p>
    </div>
  );
};

export { AccessDeniedComponent };
