import { ColumnsType } from "antd/es/table";
import { UserItem } from "shared/api";
import {
  columnsData,
  OrderingUsersEnum,
  sortDirections,
} from "shared/constants";
import { TableButton } from "shared/ui";

interface UserGetUsersColumns {
  t: Function;
  editUserHandler: Function;
  showDeleteUserModal: Function;
  showChangePasswordModal: Function;
  changeSort: Function;
}

export const useGetUsersColumns = ({
  t,
  editUserHandler,
  showDeleteUserModal,
  showChangePasswordModal,
  changeSort,
}: UserGetUsersColumns): ColumnsType<UserItem> => [
  {
    title: t(columnsData.username.title),
    render: (user: UserItem) => (
      <TableButton
        type="title"
        title={user?.username}
        onClick={() => editUserHandler(user)}
      />
    ),
    sortDirections,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          OrderingUsersEnum.USERNAME,
          OrderingUsersEnum.REVERSE_USERNAME
        );
      },
    }),
    sorter: true,
  },
  {
    title: t(columnsData.last_name.title),
    dataIndex: columnsData.last_name.dataIndex,
    key: columnsData.last_name.key,
    sortDirections,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          OrderingUsersEnum.LAST_NAME,
          OrderingUsersEnum.REVERSE_LAST_NAME
        );
      },
    }),
    sorter: true,
  },
  {
    title: t(columnsData.first_name.title),
    dataIndex: columnsData.first_name.dataIndex,
    key: columnsData.first_name.key,
    sortDirections,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(
          OrderingUsersEnum.FIRST_NAME,
          OrderingUsersEnum.REVERSE_FIRST_NAME
        );
      },
    }),
    sorter: true,
  },
  {
    title: t(columnsData.email.title),
    dataIndex: columnsData.email.dataIndex,
    key: columnsData.email.key,
    sortDirections,
    onHeaderCell: () => ({
      onClick: () => {
        changeSort(OrderingUsersEnum.EMAIL, OrderingUsersEnum.REVERSE_EMAIL);
      },
    }),
    sorter: true,
  },
  {
    title: t(columnsData.superuser.title),
    dataIndex: columnsData.superuser.dataIndex,
    key: columnsData.superuser.key,
    render: (user: UserItem) => (user ? "Yes" : "No"),
  },
  {
    title: t(columnsData.roles.title),
    key: columnsData.roles.key,
    render: (user: UserItem) =>
      user.roles?.length ? user.roles.map((role) => role.name).join(", ") : "",
  },
  {
    title: t(columnsData.actions.title),
    key: columnsData.actions.key,
    render: (record: UserItem) => {
      return (
        <div className="w-full flex items-center gap-6 justify-center">
          <TableButton
            type="delete"
            onClick={() => showDeleteUserModal(record)}
          />
          <TableButton
            className="text-lg"
            type="password"
            onClick={() => showChangePasswordModal(record.id)}
          />
        </div>
      );
    },
  },
];
