import React, { FC } from "react";
import { Modal, notification, Select } from "antd";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Title from "antd/es/typography/Title";
import { FormInput } from "shared/ui";
import { Colors } from "shared/themes";
import { handleRequestError } from "shared/helpers";
import { useUpdateRoleByIdMutation } from "shared/api";
import { ModalSizeValue } from "shared/constants";

interface UpdateRoleModalProps {
  isOpenEditRoleModal: boolean;
  closeEditRoleModal: () => void;
  permissionOptions: {
    value: string;
  }[];
  refetchRoles: () => void;
}

const UpdateRoleModal: FC<UpdateRoleModalProps> = ({
  isOpenEditRoleModal,
  closeEditRoleModal,
  permissionOptions,
  refetchRoles,
}) => {
  const { t } = useTranslation();
  const {
    values,
    resetForm,
    setFieldValue,
    isValid,
    handleChange,
    errors,
    handleBlur,
  } = useFormikContext<{ name: string; permission_blocks: string[] }>();

  const [updateRole] = useUpdateRoleByIdMutation();
  const [api, contextHolder] = notification.useNotification();

  const closeModal = () => {
    closeEditRoleModal();
    resetForm();
  };

  const onSubmitEditRole = async (params: {
    name: string;
    permission_blocks: string[];
  }) => {
    try {
      await updateRole(params).unwrap();
      api.success({
        message: t("Success"),
        description: t("Role was successfully edited"),
      });
      refetchRoles();
      closeModal();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("roles-page.error-title")
      );

      api.error({
        message: t("roles-page.error-title"),
        description,
      });
    }
  };

  return (
    <>
      {contextHolder}

      <Modal
        title={t("Edit Role")}
        width={ModalSizeValue.PRIMARY}
        open={isOpenEditRoleModal}
        okButtonProps={{
          disabled: !isValid,
          style: {
            background: `${Colors.blue.primary}`,
          },
        }}
        onCancel={closeModal}
        onOk={() => {
          onSubmitEditRole(values);
        }}
      >
        <Title level={5} className="text-[14px]">
          {t("Edit role name")}
        </Title>

        <FormInput
          value={values.name}
          fieldName="name"
          placeholder="Enter role name"
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.name}
          isError={!!errors.name}
        />

        <Title level={5} className="text-[14px]">
          {t("Edit permissions block")}
        </Title>

        <Select
          mode="multiple"
          value={values.permission_blocks}
          onChange={(value) => setFieldValue("permission_blocks", value)}
          options={permissionOptions}
          placeholder="Select permissions"
          style={{ width: "100%" }}
        />
      </Modal>
    </>
  );
};

export { UpdateRoleModal };
