import { Divider } from "antd";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useFormikContext } from "formik";
import { ExecuteReportValues } from "shared/types";
import React, { FC, useEffect, useState } from "react";
import { ReportItem } from "shared/api";
import { Colors } from "shared/themes";
import { DatePickerValue, GlobalDatePicker } from "shared/ui";

dayjs.extend(utc);
dayjs.extend(timezone);

interface DatesBlockProps {
  selectedReport?: ReportItem;
}

const DailyDatesBlock: FC<DatesBlockProps> = ({ selectedReport }) => {
  const { setFieldValue } = useFormikContext<ExecuteReportValues>();
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  const handleDateChange = (_: DatePickerValue, dateString: string) => {
    if (dateString) {
      const localDate = dayjs
        .tz(dateString, "Europe/Paris")
        .set("hour", 6)
        .set("minute", 0)
        .set("second", 0);

      setSelectedDate(localDate);

      setFieldValue(
        "order_production_datetime_start",
        localDate.subtract(24, "hour").format("YYYY-MM-DD HH:mm:ss")
      );
      setFieldValue(
        "order_production_datetime_end",
        localDate.format("YYYY-MM-DD HH:mm:ss")
      );
    } else {
      setSelectedDate(null);
      setFieldValue("order_production_datetime_start", "");
      setFieldValue("order_production_datetime_end", "");
      setFieldValue("order_wanted_delivery_datetime_start", "");
      setFieldValue("order_wanted_delivery_datetime_end", "");
    }
  };

  useEffect(() => {
    if (selectedReport) {
      const localToday = dayjs()
        .tz("Europe/Paris")
        .set("hour", 6)
        .set("minute", 0)
        .set("second", 0);

      const start = localToday.clone().subtract(24, "hour");
      const end = localToday;

      setSelectedDate(localToday);
      setFieldValue(
        "order_production_datetime_start",
        start.format("YYYY-MM-DD HH:mm:ss")
      );
      setFieldValue(
        "order_production_datetime_end",
        end.format("YYYY-MM-DD HH:mm:ss")
      );
    }
  }, [selectedReport]);

  return (
    <div className="flex flex-col">
      <div className="max-w-xs mt-1">
        <GlobalDatePicker
          handlePickerChange={handleDateChange}
          size="large"
          value={selectedDate}
          showTime
        />
      </div>

      <Divider className="my-3" style={{ background: Colors.blue.primary }} />
    </div>
  );
};

export { DailyDatesBlock };
