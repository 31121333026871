import React, { FC } from "react";
import { Modal, notification, Select } from "antd";
import { useFormikContext } from "formik";
import Title from "antd/es/typography/Title";
import { FormInput } from "shared/ui";
import { Colors } from "shared/themes";
import { ModalSizeValue } from "shared/constants";
import { useAddNewRoleMutation } from "shared/api";
import { useTranslation } from "react-i18next";
import { handleRequestError } from "shared/helpers";

interface AddRoleModalProps {
  isOpenNewRoleModal: boolean;
  closeAddRoleModal: () => void;
  permissionOptions: {
    value: string;
  }[];
  refetchRoles: () => void;
}

export interface FormValues {
  name: string;
  permission_blocks: string[];
}

const AddRolesModal: FC<AddRoleModalProps> = ({
  isOpenNewRoleModal,
  closeAddRoleModal,
  refetchRoles,
  permissionOptions,
}) => {
  const {
    values,
    resetForm,
    isValid,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormikContext<FormValues>();

  const closeModal = () => {
    closeAddRoleModal();
    resetForm();
  };

  const [createNewRole] = useAddNewRoleMutation();
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();

  const onAddRole = async ({ name, permission_blocks }: FormValues) => {
    try {
      await createNewRole({ name, permission_blocks }).unwrap();
      api.success({
        message: t("users-page.success-title"),
        description: t("roles.add-role-dec"),
      });
      closeModal();
      refetchRoles();
    } catch (error) {
      const description = handleRequestError(
        error,
        t("users-page.error-title")
      );

      api.error({
        message: t("users-page.error-title"),
        description,
      });
    }
  };

  const { name, permission_blocks } = values;

  return (
    <>
      {contextHolder}

      <Modal
        title="Add Role"
        open={isOpenNewRoleModal}
        width={ModalSizeValue.PRIMARY}
        okButtonProps={{
          disabled: !isValid,
          style: { background: Colors.blue.primary },
        }}
        onCancel={closeModal}
        onOk={() => {
          onAddRole({
            name,
            permission_blocks,
          });
        }}
      >
        <Title level={5} className="text-[14px]">
          Enter name
        </Title>
        <FormInput
          value={values.name}
          fieldName="name"
          placeholder="Enter name"
          handleChange={handleChange}
          handleBlur={handleBlur}
        />

        <Title level={5} className="text-[14px] mt-4">
          Permissions
        </Title>
        <Select
          mode="multiple"
          style={{ width: "100%" }}
          placeholder="Select permissions"
          value={values.permission_blocks}
          onChange={(value) => setFieldValue("permission_blocks", value)}
          options={permissionOptions}
        />
      </Modal>
    </>
  );
};

export { AddRolesModal };
