import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../baseQuery";
import {
  CreateOrderItem,
  CreateOrderPlanForMachine,
  GenerateMultiplePackagingReport,
  GenerateOrderProcessStepLabel,
  GetOrderByIdParams,
  OrderItem,
  OrderMachinePlan,
  OrderPlan,
  OrderPlansForMachineParams,
  OrderPlansForMachineResponse,
  OrderProcessStepItem,
  OrderStepTrackerParams,
  OrderStepTrackerResponseItem,
  OrderProcessInfoBox,
  OrdersParams,
  OrdersPlansParams,
  OrdersPlansResponse,
  OrdersResponse,
  UpdateOrderItem,
  UpdateOrderStepTrackerItem,
} from "./models";

export const ordersApi = createApi({
  baseQuery,
  reducerPath: "ordersApi",
  tagTypes: ["orders", "order-process-step", "order-plans", "timeline-orders"],
  endpoints: (builder) => ({
    getAllOrders: builder.query<OrdersResponse, OrdersParams>({
      query: (body) => ({
        url: "/api/v1/orders/",
        method: "GET",
        params: body,
      }),
      providesTags: ["orders"],
    }),

    getOrderById: builder.query<OrderItem[], GetOrderByIdParams>({
      query: (body) => ({
        url: "/api/v1/orders/",
        method: "GET",
        params: body,
      }),
      providesTags: ["orders"],
    }),

    getAllOrdersWithNoParams: builder.query<OrderItem[], OrdersParams>({
      query: (body) => ({
        url: "/api/v1/orders/",
        method: "GET",
        params: body,
      }),
      providesTags: ["orders"],
    }),
    createOrder: builder.mutation<void, CreateOrderItem>({
      query: (body) => ({
        url: "/api/v1/orders/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["orders"],
    }),
    deleteOrder: builder.mutation<void, OrderItem>({
      query: (body) => ({
        url: `/api/v1/orders/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["orders"],
    }),
    updateOrder: builder.mutation<void, UpdateOrderItem>({
      query: (body) => ({
        url: `/api/v1/orders/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["orders"],
    }),
    getOrderProcessStepTrackers: builder.query<
      OrderStepTrackerResponseItem,
      OrderStepTrackerParams
    >({
      query: (body) => ({
        url: `/api/v1/order_process_step_trackers/${body.id}/`,
        method: "GET",
        params: body,
      }),
      providesTags: ["order-process-step"],
    }),
    updateOrderProcessStepTrackers: builder.mutation<
      void,
      UpdateOrderStepTrackerItem
    >({
      query: (body) => ({
        url: `/api/v1/order_process_step_trackers/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["order-process-step", "orders"],
    }),

    getAllOrderProcessStepTrackers: builder.query<
      OrderProcessStepItem[],
      OrderStepTrackerParams
    >({
      query: (body) => ({
        url: `/api/v1/order_process_step_trackers/`,
        method: "GET",
        params: body,
      }),
      providesTags: ["order-process-step"],
    }),

    getOrderProcessStepInfoBox: builder.query<
      OrderProcessInfoBox,
      { id: number | undefined }
    >({
      query: ({ id }) => ({
        url: `/api/v1/order_process_step_trackers/${id}/info_box/`,
        method: "GET",
      }),
      providesTags: ["order-process-step"],
    }),

    getOrderProcessStepInfoBoxPdf: builder.query<
      Blob,
      { id: number | undefined }
    >({
      query: ({ id }) => ({
        url: `/api/v1/order_process_step_trackers/${id}/info_box_pdf/`,
        method: "GET",
        responseHandler: async (response) => response.blob(),
      }),
    }),

    // orders plans
    getOrderPlans: builder.query<OrdersPlansResponse, OrdersPlansParams>({
      query: (body) => ({
        url: `/api/v1/order_plans/`,
        method: "GET",
        params: body,
      }),
      providesTags: ["order-plans", "timeline-orders"],
    }),
    getOrderPlansWithoutParams: builder.query<OrderPlan[], { expand?: string }>(
      {
        query: (body) => ({
          url: `/api/v1/order_plans/`,
          method: "GET",
          params: body,
        }),
        providesTags: ["order-plans", "timeline-orders"],
      }
    ),
    createOrderPlan: builder.mutation<void, OrderPlan>({
      query: (body) => ({
        url: "/api/v1/order_plans/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["order-plans"],
    }),
    updateOrderPlan: builder.mutation<void, OrderPlan>({
      query: (body) => ({
        url: `/api/v1/order_plans/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["order-plans"],
    }),
    deleteOrderPlan: builder.mutation<void, OrderPlan>({
      query: (body) => ({
        url: `/api/v1/order_plans/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["order-plans"],
    }),

    // order plans for machines
    getOrderPlansForMachine: builder.query<
      OrderPlansForMachineResponse,
      OrderPlansForMachineParams
    >({
      query: (body) => ({
        url: `/api/v1/order_plans_for_machine/`,
        method: "GET",
        params: body,
      }),
      providesTags: ["timeline-orders"],
    }),
    getOrderPlanForMachineById: builder.query<
      OrderMachinePlan,
      OrderPlansForMachineParams
    >({
      query: (body) => ({
        url: `/api/v1/order_plans_for_machine/${body.id}/`,
        method: "GET",
      }),
      providesTags: ["timeline-orders"],
    }),
    updateOrderPlanForMachineBy: builder.mutation<void, OrderMachinePlan>({
      query: (body) => ({
        url: `/api/v1/order_plans_for_machine/${body.id}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["timeline-orders"],
    }),
    deleteOrderPlanForMachine: builder.mutation<void, { id: number | string }>({
      query: (body) => ({
        url: `/api/v1/order_plans_for_machine/${body.id}/`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["timeline-orders"],
    }),
    createOrderPlanForMachine: builder.mutation<
      void,
      CreateOrderPlanForMachine
    >({
      query: (body) => ({
        url: "/api/v1/order_plans_for_machine/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["timeline-orders"],
    }),

    generateMultiplePackagingReport: builder.mutation<
      Blob,
      GenerateMultiplePackagingReport
    >({
      query: (body) => ({
        url: `/api/v1/orders/${body.id}/generate_multiple_packaging_report/`,
        method: "POST",
        body,
        responseHandler: (response) => response.blob(),
      }),
    }),

    generateAllItemsLabels: builder.mutation<
      void,
      GenerateOrderProcessStepLabel
    >({
      query: (body) => ({
        url: `/api/v1/order_process_step_trackers/${body.id}/generate_item_labels_all/`,
        method: "POST",
        body,
        responseHandler: (response) => response.blob(),
      }),
    }),

    generateLastSetLabels: builder.mutation<
      void,
      GenerateOrderProcessStepLabel
    >({
      query: (body) => ({
        url: `/api/v1/order_process_step_trackers/${body.id}/generate_item_labels_last_set/`,
        method: "POST",
        body,
        responseHandler: (response) => response.blob(),
      }),
    }),

    generateAllPackageLabels: builder.mutation<
      void,
      GenerateOrderProcessStepLabel
    >({
      query: (body) => ({
        url: `/api/v1/order_process_step_trackers/${body.id}/generate_package_labels_all/`,
        method: "POST",
        body,
        responseHandler: (response) => response.blob(),
      }),
    }),

    generateLastSetPackageLabels: builder.mutation<
      void,
      GenerateOrderProcessStepLabel
    >({
      query: (body) => ({
        url: `/api/v1/order_process_step_trackers/${body.id}/generate_package_labels_last_set/`,
        method: "POST",
        body,
        responseHandler: (response) => response.blob(),
      }),
    }),
  }),
});

export const {
  useGetAllOrdersQuery,
  useLazyGetAllOrdersQuery,
  useGetAllOrdersWithNoParamsQuery,
  useCreateOrderMutation,
  useDeleteOrderMutation,
  useUpdateOrderMutation,
  useGetOrderByIdQuery,
  // order_process_step_trackers
  useGetOrderProcessStepTrackersQuery,
  useUpdateOrderProcessStepTrackersMutation,
  useGetAllOrderProcessStepTrackersQuery,
  useGetOrderProcessStepInfoBoxQuery,
  useLazyGetOrderProcessStepInfoBoxPdfQuery,
  // order plans
  useGetOrderPlansWithoutParamsQuery,
  useGetOrderPlansQuery,
  useCreateOrderPlanMutation,
  useUpdateOrderPlanMutation,
  useDeleteOrderPlanMutation,
  // order plans for machine
  useGetOrderPlansForMachineQuery,
  useGetOrderPlanForMachineByIdQuery,
  useUpdateOrderPlanForMachineByMutation,
  useDeleteOrderPlanForMachineMutation,
  useCreateOrderPlanForMachineMutation,
  // generate multiple packaging report
  useGenerateMultiplePackagingReportMutation,
  // prod page header pdf-fields requests
  useGenerateAllItemsLabelsMutation,
  useGenerateLastSetLabelsMutation,
  useGenerateAllPackageLabelsMutation,
  useGenerateLastSetPackageLabelsMutation,
} = ordersApi;
